import { FC, useEffect } from 'react'
import { useMyDispatch } from '../../hooks/useMyDispatch'
import { useMySelector } from '../../hooks/useMySelector'
import { getTableData } from '../../store/reducers/CommonReducer/actionCreator'
import { RenderIf } from '../RenderIf'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { useLocation, useSearchParams } from 'react-router-dom'
import history from '../../utils/history'
import {
  HeaderStyled,
  HeaderLogo,
  TableCaption,
  TableCaptionNumber,
  TableCaptionText,
  WishListContainer,
} from './styled'
import heart from '../../assets/hearts/empty_l.svg'
import chevronLeft from '../../assets/chevronLeft.svg'
import { useTranslations } from '../../hooks/useTranslations'
import clickAndCollectLogo from '../../assets/prompt/clickAndCollect.svg'
import qrMenuLogo from '../../assets/prompt/qrMenu.svg'

export const Header: FC = () => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const guid = handleLocalStorage('guid')
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')

  // Store
  const { wishlist } = useMySelector((state) => state)
  const { logoImage } = useMySelector((state) => state.app.interfaceStyles)
  const { tableData, isTopScrolled } = useMySelector((state) => state.common)

  // useEffects
  useEffect(() => {
    if (guid && tableId) {
      dispatch(getTableData({ tableId }))
    }
  }, [guid, tableId]) // eslint-disable-line

  // Functions
  const handleBackClick = () => {
    const isMenuOrRest =
      location.pathname === '/menu' || location.pathname === '/restaurants'
    if (!isMenuOrRest) {
      history.back()
    }
    if (isMenuOrRest && tableId) {
      history.push(`/?table=${tableId}`)
    }
    if (isMenuOrRest && !tableId) {
      history.push(`/`)
    }
  }

  const moveToWishlist = () => {
    if (tableId) {
      history.push(`/wishlist?table=${tableId}`)
    }
    if (!tableId) {
      history.push('/wishlist')
    }
  }

  const getImageSrc = (logoImage?: string) => {
    if (process.env.REACT_APP_IS_CLIC === '0') {
      return logoImage || qrMenuLogo
    }
    if (process.env.REACT_APP_IS_CLIC === '1') {
      return logoImage || clickAndCollectLogo
    }
  }

  return (
    <HeaderStyled isTopScrolled={isTopScrolled} data-testid="header_test-id">
      <img
        src={chevronLeft}
        alt="Back"
        onClick={handleBackClick}
        width="40"
        height="40"
      />
      <HeaderLogo
        src={getImageSrc(logoImage)}
        height="35"
        width="35"
        alt="Logo"
      />
      <RenderIf
        condition={
          location.pathname !== '/wishlist' &&
          location.pathname !== '/loyaltyCard' &&
          location.pathname !== '/restaurants'
        }
      >
        <WishListContainer
          onClick={moveToWishlist}
          count={wishlist?.length}
          // withMargin={true}
          withMargin={!!(tableData?.caption || tableData?.objectTag)}
        >
          <img src={heart} alt="wishlist" width="25" height="22" />
        </WishListContainer>
      </RenderIf>
      <TableCaption>
        <TableCaptionNumber>
          {tableData?.caption || tableData?.objectTag}
        </TableCaptionNumber>
        <RenderIf condition={!!(tableData?.caption || tableData?.objectTag)}>
          <TableCaptionText>{t('common.tableNumber')}</TableCaptionText>
        </RenderIf>
      </TableCaption>
    </HeaderStyled>
  )
}
