import { FC } from 'react'
import { Button } from '../../../components/Button'
import { useTranslations } from '../../../hooks/useTranslations'
import { ButtonContainer, Disclaimer } from './styled'

type AreYouSureProps = {
  onDiscardConfirm: any
  closeModal: any
}

export const AreYouSure: FC<AreYouSureProps> = ({
  onDiscardConfirm,
  closeModal,
}) => {
  const t = useTranslations()
  const handleDiscard = async () => {
    await onDiscardConfirm()
    closeModal()
  }

  const handleKeep = () => {
    closeModal()
  }

  return (
    <div>
      <Disclaimer>
        <p>{t('clickCollect.sureToContinue')}</p>
        <p>{t('clickCollect.discardDisclaimer')}.</p>
      </Disclaimer>
      <ButtonContainer>
        <Button buttonType="stroke" onClick={handleDiscard}>
          {t('buttons.discard')}
        </Button>
        <Button buttonType="common" onClick={handleKeep}>
          {t('buttons.keepData')}
        </Button>
      </ButtonContainer>
    </div>
  )
}
