import styled from 'styled-components'

type ImageContainerProps = {
  background?: string
  count?: string
}

// AllergenModal

export const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  &:after {
    content: unset;
  }
`

export const AllergensComponentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 58px calc(100vh - 398px);
  grid-gap: 20px;
  &:after {
    content: unset;
  }
`

export const AllergensContainer = styled.div`
  max-height: calc(100vh - 398px);
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(10, 120px);
  grid-gap: 10px;
`

// AllergenItem

export const AllergenItemContainer = styled.div`
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.ItemsBorders}`};
  background-color: ${({ theme }) => theme.ItemsBackGround};

  border-radius: 5px;
  overflow: hidden;
`

export const AllergenTitle = styled.div`
  font-weight: 500;
  font-size: 10px !important;
  line-height: 12px;
  display: flex;
  color: ${({ theme }) => theme.FontColor};
  text-align: center;
`

export const AllergenItemImageContainer = styled('div')<ImageContainerProps>`
  height: 66px;
  width: 66px;
  position: relative;
  box-sizing: border-box;
  background: ${({ background }) => background || 'unset'};
  border-radius: 100%;
  margin: 10px 0 15px;
`

export const AllergenItemImage = styled.img`
  height: 100%;
`
