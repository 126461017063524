/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAllergen, ILanguage, initialState, ITableData } from './types'

import moment from 'moment'
// @ts-ignore
import de from 'moment/locale/de'
// @ts-ignore
import en from 'moment/locale/en-gb'
// @ts-ignore
import fr from 'moment/locale/fr'
// @ts-ignore
import it from 'moment/locale/it'
import { updateMomentLanguage } from '../../../utils/updateMomentLanguage'

export const CommonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setSelectedLanguage(state, action: PayloadAction<ILanguage>) {
      updateMomentLanguage(action.payload)
      state.selectedLanguage = action.payload
    },
    setIsTopScrolled(state, action: PayloadAction<boolean>) {
      state.isTopScrolled = action.payload
    },
    setAllergens(state, action: PayloadAction<IAllergen[]>) {
      state.allergens = action.payload
    },
    setTableData(state, action: PayloadAction<ITableData>) {
      state.tableData = action.payload
    },
  },
})

export default CommonSlice.reducer
