import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { useMySelector } from '../../hooks/useMySelector'
import { useTranslations } from '../../hooks/useTranslations'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { IRestaurant } from '../../store/reducers/RestaurantsReducer/types'
import { ScheduleType, week, weekArr } from '../../components/Schedule/types'

import { RenderIf } from '../../components/RenderIf'
import { Schedule } from '../../components/Schedule'
import { Spinner } from '../../components/Spinner'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'

import qrMenuLogo from '../../assets/prompt/qrMenu.svg'
import { SelectTime } from './styled'

import {
  TimeButtonContainer,
  RestaurantsCheckBox,
  RestaurantsContainer,
  RestaurantsItem,
  RestaurantsTitle,
  UnavailableSign,
  RestaurantsAddress,
} from './styledComponents'

type BranchPickerProps = {
  isWallet?: boolean
  increaseStep: () => void
}

export const BranchPicker: FC<BranchPickerProps> = ({
  isWallet,
  increaseStep,
}) => {
  // Hooks
  const t = useTranslations()
  const { restaurants } = useMySelector((state) => state)

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<number>(0)
  const [selectedBranchId, setSelectedBranchId] = useState<number>(0)
  const { logoImage } = useMySelector((state) => state.app.interfaceStyles)
  const [timeFilteredRestaurants, setTimeFilteredRestaurants] = useState<
    IRestaurant[]
  >([])
  const [restaurantsToShow, setRestaurantsToShow] = useState<IRestaurant[]>([])

  // useEffects
  useEffect(() => {
    if (restaurants) {
      const { pickUpTime } = handleLocalStorage(['pickUpTime'])
      const format = 'HH:mm:ss'
      const pickupTimeFormatted = pickUpTime ? moment(pickUpTime) : moment()
      const restaurantsFiltered = restaurants.reduce(
        (acc: IRestaurant[], restaurant: IRestaurant) => {
          const currentSchedule =
            restaurant.schedule[
              pickupTimeFormatted
                ?.lang('en')
                ?.format('dddd')
                ?.toLocaleLowerCase()
            ]
          if (currentSchedule) {
            const [startString, endString] = currentSchedule.split(' - ') || []
            const startTime = moment(startString, format)
            const endTime = moment(endString, format)
            const isBetween = moment(
              pickupTimeFormatted.format(format),
              format
            ).isBetween(startTime, endTime)
            if (isBetween) {
              return [
                ...acc,
                {
                  ...restaurant,
                  isAvailable: true,
                },
              ]
            } else {
              return [
                ...acc,
                {
                  ...restaurant,
                  isAvailable: false,
                },
              ]
            }
          } else {
            return [
              ...acc,
              {
                ...restaurant,
                isAvailable: false,
              },
            ]
          }
        },
        []
      )
      setTimeFilteredRestaurants(restaurantsFiltered)
    }
  }, [restaurants])

  useEffect(() => {
    if (timeFilteredRestaurants && timeFilteredRestaurants.length === 1) {
      handleLocalStorage({
        branchId: timeFilteredRestaurants[0].id,
        registerId:
          process.env.REACT_APP_IS_CLIC === '1'
            ? timeFilteredRestaurants[0].clickAndCollectClientId
            : timeFilteredRestaurants[0].qrMenuClientId,
      })
      increaseStep()
    }
    if (timeFilteredRestaurants && timeFilteredRestaurants.length > 1) {
      setRestaurantsToShow(timeFilteredRestaurants)
    }
    setIsLoading(false)
  }, [timeFilteredRestaurants])

  // Functions
  const filterRestaurants = (value: string) => {
    if (timeFilteredRestaurants) {
      setRestaurantsToShow(
        timeFilteredRestaurants.filter((restaurant) =>
          restaurant.title
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        )
      )
    }
  }

  const handleRestaurantClick = (restaurant: any) => {
    if (restaurant?.isAvailable || isWallet) {
      setSelectedBranchId(restaurant.id)
      if (process.env.REACT_APP_IS_CLIC === '1') {
        setSelectedId(restaurant.clickAndCollectClientId)
      } else {
        setSelectedId(restaurant.qrMenuClientId)
      }
    }
  }

  const convertToSchedule = (sch?: ScheduleType) => {
    const schedule: any = {}
    const closed: any = []
    if (sch) {
      weekArr.map((day) => {
        if (sch[day]) {
          const newKey =
            sch[day].split('').splice(0, 5).join('') +
            sch[day].split('').splice(8, 3).join('') +
            sch[day].split('').splice(11, 5).join('')
          if (schedule[newKey]) {
            schedule[newKey] = schedule[newKey].slice(0, 4) + '-' + week[day]
          } else {
            schedule[newKey] = week[day]
          }
        } else {
          closed.push(week[day])
        }
      })
    }
    return [
      Object.entries(schedule).map(([key, value]) => [value, key]),
      closed.sort(),
    ]
  }

  const handleBranchAccept = () => {
    setIsButtonLoading(true)
    handleLocalStorage({
      branchId: selectedBranchId,
      registerId: selectedId,
    })
    increaseStep()
  }

  return (
    <div>
      <RenderIf condition={isLoading}>
        <Spinner type="alternate" />
      </RenderIf>
      {/* <SelectTime>{t('clickCollect.choosePickUpLocation')}</SelectTime> */}
      <SelectTime>
        {t(
          process.env.REACT_APP_IS_CLIC === '0'
            ? 'scanner.selectBranch'
            : 'clickCollect.choosePickUpLocation'
        )}
      </SelectTime>
      <RenderIf condition={!!timeFilteredRestaurants?.length}>
        <Input onSearch={(e: string) => filterRestaurants(e)} />
        {/* <ButtonsContainer>
          <Button buttonType="common" width="calc(50% - 5px)" height="40px">
            {t('common.list')}
          </Button>
          <Button
            buttonType="common"
            disabled
            width="calc(50% - 5px)"
            height="40px"
          >
            {t('common.map')}
          </Button>
        </ButtonsContainer> */}
        <RestaurantsContainer>
          {restaurantsToShow
            ?.sort((a, b) => Number(b.isAvailable) - Number(a.isAvailable))
            ?.map((restaurant) => (
              <RestaurantsItem
                key={`restaurant_${restaurant.id}`}
                onClick={() => handleRestaurantClick(restaurant)}
                isAvailable={restaurant?.isAvailable || Boolean(isWallet)}
                isQRMenu={process.env.REACT_APP_IS_CLIC === '0'}
              >
                <div>
                  <img
                    style={{
                      objectFit: 'contain',
                      filter: 'drop-shadow(5px 5px 10px #00000060)',
                    }}
                    src={
                      restaurant?.imageData?.logoUrl || logoImage || qrMenuLogo
                    }
                    alt="Branch"
                    height="95"
                    width="95"
                  />
                </div>
                <div>
                  <RenderIf
                    condition={!!restaurant?.isAvailable || Boolean(isWallet)}
                  >
                    <RestaurantsCheckBox
                      isSelected={
                        restaurant.qrMenuClientId === selectedId ||
                        restaurant.clickAndCollectClientId === selectedId
                      }
                    />
                  </RenderIf>
                  <RenderIf condition={!restaurant?.isAvailable && !isWallet}>
                    <UnavailableSign>
                      {t('clickCollect.unavailableBySchedule')}
                    </UnavailableSign>
                  </RenderIf>
                  <RestaurantsTitle>{restaurant.title}</RestaurantsTitle>
                  <RestaurantsAddress>
                    {restaurant?.address || ''}
                  </RestaurantsAddress>
                  <Schedule schedule={restaurant.schedule} />
                </div>
              </RestaurantsItem>
            ))}
        </RestaurantsContainer>
        <TimeButtonContainer>
          <Button
            buttonType={selectedId ? 'common' : 'gray'}
            disabled={!selectedId}
            width="100%"
            onClick={handleBranchAccept}
            isLoading={isButtonLoading}
          >
            {t('buttons.accept')}
          </Button>
        </TimeButtonContainer>
      </RenderIf>
    </div>
  )
}

export default BranchPicker
