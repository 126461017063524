import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Translations, initialState } from './types'

export const TranslationsSlice = createSlice({
  name: 'translationsSlice',
  initialState,
  reducers: {
    setTranslations(state, action: PayloadAction<Translations>) {
      return (state = { isTranslationsLoaded: true, ...action.payload })
    },
  },
})

export default TranslationsSlice.reducer
