export const get = (obj, path, def) => {
  const everyFunc = (step) => {
    return !(step && (obj = obj[step]) === undefined)
  }

  const fullPath = path
    ?.toString()
    .replace(/\[/g, '.')
    .replace(/]/g, '')
    .split('.')
    .filter(Boolean)

  return fullPath?.every(everyFunc) ? obj : def
}
