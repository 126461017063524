import styled from 'styled-components'

type StyledSelectProps = {
  width?: string
}

export const StyledSelectContainer = styled('div')<StyledSelectProps>`
  position: relative;
  margin: 0;
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  img {
    margin: 0;
    position: absolute;
    pointer-events: none;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const StyledSelect = styled('select')<StyledSelectProps>`
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  background-color: ${({ theme }) => theme.MainBackGround};
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  height: 41px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
`
