import { Moment } from 'moment'

export const initialState: ITableReservationState = {
  allergens: [],
  diet: [],
  //
  bookingDate: null,
  bookingTime: null,
  //
  customerName: '',
  customerFamilyName: '',
  phoneNumber: '',
  isDataFull: false,
  //
  branchId: 0,
  guestCount: 2,
  note: '',
  isReservationCanceled: false,
  //
  selectedRoom: null,
  availableRooms: [],
  isRoomsLoaded: false,
}

export type ITableReservationState = {
  allergens: number[]
  diet: string[]
  bookingDate: Moment | null
  bookingTime: string | null
  branchId: number
  customerName: string
  customerFamilyName: string
  phoneNumber: string
  isDataFull: boolean
  guestCount: number
  note: string
  isReservationCanceled: boolean
  selectedRoom: number | null
  availableRooms: RoomType[]
  isRoomsLoaded: boolean
}

export type RoomType = {
  id: number
  caption: string
}
