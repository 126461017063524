import { FirebaseApp, initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { Auth } from '@aws-amplify/auth'
import { qrMenuPOST } from '../../api/qrMenu/POST'
import { NotificationUser } from '../../api/qrMenu/types'
import firebaseConfig from '../firebaseConfig'

const app: FirebaseApp = initializeApp(firebaseConfig)

export const getAppMessaging = () => getMessaging(app)
export const getSWRegistration = async () =>
  await navigator.serviceWorker.getRegistration('/sw.js')

export const subscribeForNotifications = async (
  customerPayload?: Partial<NotificationUser>
) => {
  const appMessaging = getAppMessaging()
  const swRegistration = await getSWRegistration()
  const token: string = await getToken(appMessaging, {
    vapidKey: process.env.REACT_APP_PUSH_PUBLIC_KEY,
    serviceWorkerRegistration: swRegistration,
  })
  let customerName = customerPayload ? customerPayload.customerName : null,
    phoneNumber = customerPayload ? customerPayload.phoneNumber : null
  try {
    const userInfo = await Auth.currentUserInfo()
    if (userInfo?.attributes) {
      const {
        name: firstName,
        family_name: lastName,
        phone_number: phoneNumberValue,
        phone_number_verified: phoneNumberVerified,
      } = userInfo.attributes
      customerName = `${firstName} ${lastName}`
      phoneNumber = phoneNumberVerified ? phoneNumberValue : null
    }
  } catch (e) {
    console.log('@subscribeForNotifications', e)
  } finally {
    const deviceToken = localStorage.getItem('deviceToken')
    if (deviceToken !== token || customerPayload) {
      await qrMenuPOST.addNotificationUser(token, customerName, phoneNumber)
      localStorage.setItem('deviceToken', token)
    }
  }
}

// for browsers except Safari
const enableNotifications = async () => {
  const supported = await isSupported()
  if (supported) {
    if ('Notification' in window) {
      const currentPermission = Notification.permission
      if (currentPermission === 'denied') {
        console.log(
          'Push Notifications are disabled',
          "please manually change the notification permission to 'default' or 'granted' in \
  browser settings to subscribe to push messages."
        )
        return
      }
      if (currentPermission === 'granted') {
        subscribeForNotifications()
      }
    }
  }
}

export default enableNotifications
