import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, InitialStateType } from './types'

export const AuthSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAuthData(state, action: PayloadAction<InitialStateType>) {
      return (state = action.payload)
    },
  },
})

export default AuthSlice.reducer
