import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IModal, initialState } from './types'

export const ModalsSlice = createSlice({
  name: 'modalsSlice',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<IModal>) {
      if (state?.length) {
        return (state = [...state, action.payload])
      } else {
        return (state = [action.payload])
      }
    },
    closeModal(state, action: PayloadAction<string>) {
      return state?.filter((el) => el.id !== action.payload)
    },
    closeAllModals(state) {
      return (state = [])
    },
  },
})

export default ModalsSlice.reducer
