export const initialState: ICommon = {
  selectedLanguage: 'de',
  isTopScrolled: true,
  allergens: [],
  tableData: {
    id: 0,
    caption: '',
    objectTag: '',
  },
}

export interface ICommon {
  selectedLanguage: ILanguage
  isTopScrolled: boolean
  allergens: IAllergen[]
  tableData: ITableData
}

export interface IAllergen {
  id: number
  name: string
}

export interface ITableData {
  id: number
  caption: string
  objectTag: string
}

export type ILanguage = 'en' | 'de' | 'fr' | 'it'

export interface IFetchAllergens {
  guid: string
  registerId: string | number
}

export interface IFetchTableData {
  tableId?: string | number
  caption?: string | number
}
