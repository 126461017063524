export const initialState: IRestaurant[] = []

export interface IRestaurant {
  id: number
  title: string
  address: string
  position: IPosition
  googleMapUrl: string
  schedule: ScheduleType
  qrMenuClientId?: number
  clickAndCollectClientId?: number
  isAvailable?: boolean
  imageData?: {
    logoUrl?: string
  }
}

export type IPosition = {
  latitude: string
  longitude: string
}

export type ScheduleType = {
  [key: string]: string
}

export interface IFetchRestaurants {
  ActiveBranchID?: string
}
