import { FC, useEffect, useRef, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Input } from '../../../components/Input'
import { Button } from '../../../components/Button'
import { Spinner } from '../../../components/Spinner'
import { RenderIf } from '../../../components/RenderIf'
import { useTranslations } from '../../../hooks/useTranslations'

import { Error, InputContainer, StyledText } from './styled'

type ConfirmProps = {
  userName: string
  moveToLogIn: () => void
}

export const Confirm: FC<ConfirmProps> = ({ userName, moveToLogIn }) => {
  const t = useTranslations()
  const inputRef = useRef<HTMLInputElement>(null)

  // State
  const [confirmCode, setConfirmCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  // useEffect
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
      inputRef.current?.select()
    }, 10)
  }, [inputRef])

  // Functions
  const confirmHandler = async () => {
    try {
      setIsLoading(true)
      setError('')
      const data = await Auth.confirmSignUp(userName, confirmCode)
      if (data === 'SUCCESS') {
        moveToLogIn()
      }
    } catch (e: any) {
      setError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <StyledText isHeader>{t('auth.confirmMessage')}</StyledText>
      <InputContainer>
        <Input
          innerRef={inputRef}
          placeholder={t('auth.enterCode')}
          value={confirmCode}
          inputMode="numeric"
          autocomplete="one-time-code"
          onChange={(e) => setConfirmCode(e.currentTarget.value)}
        />
      </InputContainer>
      <RenderIf condition={!!error?.length}>
        <Error>{error}</Error>
      </RenderIf>
      <Button
        buttonType="common"
        width="100%"
        onClick={confirmHandler}
        disabled={isLoading}
      >
        {t('auth.confirmButton')}
      </Button>
    </div>
  )
}
