import { mobileWaiterGET } from '../api/mobileWaiter/GET'
import { ExchangeStatusResType } from '../api/mobileWaiter/types'

export const getStatusWithInterval = async (requestGUID: string) => {
  let statusData: ExchangeStatusResType | null = null
  let isTimedout = false

  setTimeout(() => (isTimedout = true), 11000)

  while ((!statusData || statusData?.status === 'PENDING') && !isTimedout) {
    statusData = await mobileWaiterGET.exchangeStatus(requestGUID)
  }

  return statusData
}
