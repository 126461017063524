import { handleLocalStorage } from './handleLocalStorage'

export const handleWishlist = (productId: number) => {
  const wishlist = handleLocalStorage('wishlist')

  if (!wishlist) {
    const { wishlist: wishlistToSet } = handleLocalStorage({
      wishlist: [productId],
    })
    return wishlistToSet
  }
  if (wishlist && !wishlist.find((el2: number) => el2 === productId)) {
    const { wishlist: wishlistToSet } = handleLocalStorage({
      wishlist: [...wishlist, productId],
    })
    return wishlistToSet
  }
  if (wishlist && wishlist.find((el1: number) => el1 === productId)) {
    const { wishlist: wishlistToSet } = handleLocalStorage({
      wishlist: wishlist.filter((el3: number) => el3 !== productId),
    })
    return wishlistToSet
  }
}
