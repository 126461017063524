import styled from 'styled-components'

export const WishlistContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 70px 3vw;
  overflow: hidden;
  background-color: ${({ theme }) => theme.MainBackGround};
  position: relative;
`

export const WishlistCaption = styled.div`
  height: 50px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.FontColor};
  font-weight: 600;
  font-size: 18px !important;
  line-height: 18px;
  padding-top: 16px;
`

export const MenuButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
