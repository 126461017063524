import styled from 'styled-components'

type InputProps = {
  withButton?: boolean
}

export const InputContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  height: 41px;
  width: 100%;
`

export const InputStyled = styled('input')<InputProps>`
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  background-color: ${({ theme }) => theme.MainBackGround};
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  width: 100%;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colour}`};
  }
`

export const SearchButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-color: ${({ theme }) => theme.colour};
`
