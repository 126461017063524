import { FC } from 'react'
import { ProgressBar_Container, ProgressBar_Progress } from './styled'

export type ProgressBarProps = {
  progress?: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress }) => {
  return (
    <ProgressBar_Container>
      <ProgressBar_Progress progress={progress} />
    </ProgressBar_Container>
  )
}
