import { FC, useState } from 'react'
import { RenderIf } from '../RenderIf'
import chevronDown from '../../assets/chevronDown.svg'
import chevronUp from '../../assets/chevronUp.svg'
import { StyledSelectContainer, StyledSelect } from './styled'

type OptionType = {
  [key: string]: string | number
}

type SelectProps = {
  options: OptionType[]
  onSelectOpen?: () => void
  onChange?: (value: string) => void
  width?: string
  defaultValue?: number | string
  fields?: {
    value: string
    label: string
  }
}

export const Select: FC<SelectProps> = ({
  onSelectOpen,
  onChange,
  options,
  fields = { value: 'value', label: 'label' },
  width = '100%',
  ...props
}) => {
  const [isSelecting, setIsSelecting] = useState(false)

  const handleSelectOpen = () => {
    setIsSelecting(true)
    if (onSelectOpen) {
      onSelectOpen()
    }
  }
  const handleSelectClose = (e: any) => {
    setIsSelecting(false)
    if (onChange) {
      onChange(e.currentTarget.value)
    }
  }

  return (
    <StyledSelectContainer width={width}>
      <StyledSelect
        {...props}
        width={width}
        onChange={handleSelectClose}
        onClick={handleSelectOpen}
      >
        {options?.map((option) => (
          <option key={option[fields.value]} value={option[fields.value]}>
            {option[fields.label]}
          </option>
        ))}
      </StyledSelect>
      <RenderIf condition={!isSelecting}>
        <img alt="toggle_down" src={chevronDown} />
      </RenderIf>
      <RenderIf condition={isSelecting}>
        <img alt="toggle_up" src={chevronUp} />
      </RenderIf>
    </StyledSelectContainer>
  )
}
