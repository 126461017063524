import { useTranslations } from '../../hooks/useTranslations'
import { MessageContainerNoReceipt } from './styled'

export const ReceiptError = () => {
  const t = useTranslations()
  return (
    <MessageContainerNoReceipt>
      {/* <Spinner /> */}
      <h2>{t('receipt.receiptError')}</h2>
    </MessageContainerNoReceipt>
  )
}
