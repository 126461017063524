import { FC, useEffect } from 'react'
import { IPosition } from '../../sections/Wishlist'
import { RenderIf } from '../RenderIf'
import { ContextMenuItem, ContextMenuStyled } from './styled'

type ItemType = {
  component: any
  onClick: () => void
}

type ContextMenuProps = {
  position: IPosition
  discardPosition: () => void
  items: ItemType[]
}

export const ContextMenu: FC<ContextMenuProps> = ({
  position,
  discardPosition,
  items,
}) => {
  useEffect(() => {
    document.addEventListener('click', discardPosition)
    return () => {
      document.removeEventListener('click', discardPosition)
    }
  }, [])

  return (
    <RenderIf condition={!!items.length}>
      <ContextMenuStyled
        position={position as IPosition}
        data-testid="context-menu_test-id"
      >
        {items.map((item, i) => (
          <ContextMenuItem
            key={`context_menu_item-${i}`}
            onClick={item.onClick}
          >
            {item.component}
          </ContextMenuItem>
        ))}
      </ContextMenuStyled>
    </RenderIf>
  )
}
