import styled from 'styled-components'

export const RadioContainer = styled.div`
  display: flex;
  margin: 20px 0;
  color: ${({ theme }) => theme.FontColor};
  input {
    margin-right: 10px;
  }
`

export const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 3px solid ${({ theme }) => theme.colour};
  border-radius: 50%;
  ::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: 3px;
  }
  :checked {
    ::after {
      background-color: ${({ theme }) => theme.colour};
    }
  }
`
