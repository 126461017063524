import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Input } from '.'
import { useTranslations } from '../../hooks/useTranslations'

type PhoneNumberInputProps = {
  value: string
  onChange: (value: string) => void
  [key: string]: any
}

export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  value: propsValue,
  ...rest
}) => {
  // Hooks
  const t = useTranslations()

  // State
  const [value, setValue] = useState<string | undefined>()
  const [countryData, setCountryData] = useState({
    country_code_iso3: '',
    country_calling_code: '',
  })
  const [inputData, setInputData] = useState({
    inputMode: 'tel',
    placeholder: t('auth.phoneNumber'),
  })

  // useEffect
  useEffect(() => {
    loadPhoneData()
  }, [])

  useEffect(() => {
    setValue(propsValue)
  }, [propsValue])

  useEffect(() => {
    const onFocus = (e: ChangeEvent<HTMLInputElement>) => {
      if (
        !e.target.value?.length &&
        (countryData.country_code_iso3 === 'CHE' ||
          countryData.country_code_iso3 === 'DEU' ||
          countryData.country_code_iso3 === 'UKR' ||
          countryData.country_code_iso3 === 'POL')
      ) {
        setValue(`${countryData.country_calling_code}`)
      }
      rest.onFocus && rest.onFocus(e)
    }
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.value.match(/^\+?\d{0,17}$/)) {
        rest.onChange(e.currentTarget.value)
      }
    }
    setInputData((prevData) => ({ ...prevData, ...rest, onFocus, onChange }))
  }, [JSON.stringify(countryData), JSON.stringify(rest)])

  // Functions
  const loadPhoneData = async () => {
    const response = await fetch('https://ipapi.co/json/')
    if (response.ok) {
      const json = await response.json()
      setCountryData(json)
      if (!rest.placeholder) {
        switch (json.country_code_iso3) {
          case 'CHE':
            setInputData((prevData) => ({
              ...prevData,
              placeholder: `${json.country_calling_code}765555640`,
            }))
            break
          case 'DEU':
            setInputData((prevData) => ({
              ...prevData,
              placeholder: `${json.country_calling_code}1765566777`,
            }))
            break
          case 'UKR':
            setInputData((prevData) => ({
              ...prevData,
              placeholder: `${json.country_calling_code}991235060`,
            }))
            break
          case 'POL':
            setInputData((prevData) => ({
              ...prevData,
              placeholder: `${json.country_calling_code}22333444`,
            }))
            break
          default:
            setInputData((prevData) => ({
              ...prevData,
              placeholder: `${json.country_calling_code}`,
            }))
        }
      }
    }
  }

  return <Input {...inputData} value={value || ''} />
}
