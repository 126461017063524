import { SpecialsSlice } from '.'
import { AppDispatch } from '../../index'
import { qrMenuGET } from '../../../api/qrMenu/GET'

export const getSpecialPrices = () => async (dispatch: AppDispatch) => {
  const result: any[] = await qrMenuGET.getSpecials()
  dispatch(
    SpecialsSlice.actions.setSpecials({
      discounts: result?.[0]?.discounts,
      individualPrices: result?.[1]?.individualPrices,
    })
  )
}
