import { FC } from 'react'
import circledCross from '../../../assets/circledCross.svg'
import { useMySelector } from '../../../hooks/useMySelector'
import dietData from '../AllergensModal/dietData'
import { AllergenSliderContainer } from './styled'

type AllergenSliderProps = {
  selectedAllergens: number[]
  diet: string[]
  handleSelectedAllergens: (value: number) => void
  handleSelectedDietItems: (value: string) => void
}

export const AllergenSlider: FC<AllergenSliderProps> = ({
  selectedAllergens,
  diet,
  handleSelectedAllergens,
  handleSelectedDietItems,
}) => {
  const { allergens } = useMySelector((state) => state.common)

  return (
    <AllergenSliderContainer>
      {selectedAllergens.map((el) => (
        <p key={`selected_allergen_${el}`}>
          {allergens.find((a) => a.id === el)?.name}
          <img
            src={circledCross}
            alt="x"
            onClick={() => handleSelectedAllergens(el)}
          />
        </p>
      ))}
      {diet.map((el) => (
        <p key={`selected_allergen_${el}`}>
          {dietData.find((a) => a.id === el)?.name}
          <img
            src={circledCross}
            alt="x"
            onClick={() => handleSelectedDietItems(el)}
          />
        </p>
      ))}
    </AllergenSliderContainer>
  )
}
