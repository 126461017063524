import styled from 'styled-components'

export const NetworkErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 25px;

  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  background: #434343;
  height: 100vh;
  justify-content: center;
`

export const NetworkErrorTitle = styled.p`
  font-size: 36px !important;
  line-height: 44px;
  font-weight: 700;
  width: 60%;
`

export const NetworkErrorSubtitle = styled.p`
  line-height: 20px;
  width: 60%;
  margin-bottom: 5rem;
`

export const NetworkErrorTip = styled.p`
  font-size: 12px !important;
  line-height: 15px;
  width: 60%;
  color: #cdcdcd;
  margin: 4rem 0 2rem;
`
