import { FC } from 'react'
import { Button } from '../../../components/Button'
import { useTranslations } from '../../../hooks/useTranslations'
import { ErrorContainer } from './styled'

type ErrorProps = {
  closeModal: () => void
}

export const Error: FC<ErrorProps> = ({ closeModal }) => {
  const t = useTranslations()
  return (
    <ErrorContainer>
      <h2>{t('errors.smthWentWrong')}</h2>
      <p>{t('errors.tryToOrder')}</p>
      <Button buttonType="common" onClick={closeModal} width="100%">
        {t('buttons.close')}
      </Button>
    </ErrorContainer>
  )
}
