import { handleLocalStorage } from './handleLocalStorage'

export const downloadCustomFont = async () => {
  try {
    let linkToFont: string | null = null
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    switch (true) {
      case guid.toUpperCase() === '9BE035F1-F6A1-4F32-89B3-D9F3A0069B32' &&
        Number(registerId) === 10:
        linkToFont =
          'https://qrmenu-fonts.s3.eu-west-1.amazonaws.com/StaffWide-Regular.otf'
        break
      // case guid === '9712049C-1325-430F-B0C8-F6D64E5CF9C5' && registerId === 24:
      //   linkToFont =
      //     'https://qrmenu-fonts.s3.eu-west-1.amazonaws.com/StaffWide-Regular.otf'
      //   break
      default:
        linkToFont = null
    }
    if (linkToFont) {
      const response = await fetch(
        'https://qrmenu-fonts.s3.eu-west-1.amazonaws.com/StaffWide-Regular.otf'
      )
      const fontBlob = await response.blob()
      applyFont(fontBlob)
    }
  } catch (error) {
    console.error('Error downloading font file:', error)
  }
}

const applyFont = (fontBlob: any) => {
  const fontUrl = URL.createObjectURL(fontBlob)
  const style = document.createElement('style')
  style.appendChild(
    document.createTextNode(`
        @font-face {
            font-family: 'CustomerSpecificFont';
            src: url(${fontUrl}) format('opentype');
        }
        * {
            font-family: 'CustomerSpecificFont', sans-serif;
        }
    `)
  )
  document.head.appendChild(style)
}
