import { FC } from 'react'
import { DataTransContainer } from './styled'
// eslint-disable-next-line
//@ts-ignore
import Lightbox from 'react-datatrans-light-box'

type DataTransProps = {
  transactionId: string
}

export const DataTrans: FC<DataTransProps> = ({ transactionId }) => {
  const onError = (data: any): void => {
    console.log('@DataTrans. Error:', data)
  }
  return (
    <DataTransContainer data-testid="data-trans_test-id">
      <Lightbox
        onError={onError}
        transactionId={transactionId}
        production={process.env.REACT_APP_IS_PROD === '1'}
      />
    </DataTransContainer>
  )
}
