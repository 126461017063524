import { FC } from 'react'
import { MenuProducts } from '../../../components/Products/CustomProductsLists/MenuProducts'
import { useOpenProductModal } from '../../../hooks/useOpenProductModal'

import {
  ICategory,
  IProduct,
} from '../../../store/reducers/CategoriesReducer/types'
import { CategoryItemContainer, CategoryItemText } from './styled'

type CategoryItemType = {
  category: ICategory
}

export const CategoryItem: FC<CategoryItemType> = ({ category }) => {
  const handleProductClick = useOpenProductModal()
  return (
    <CategoryItemContainer
      id={`container_category_${category?.id}`}
      className="list_category__container"
    >
      <CategoryItemText
        id={`category_${category?.id}`}
        className="list_category"
      >
        {category?.name}
      </CategoryItemText>
      <MenuProducts
        products={category?.products}
        onProductClick={(product: IProduct) => {
          handleProductClick(product, category?.name)
        }}
      />
    </CategoryItemContainer>
  )
}
