import { useTranslations } from '../../hooks/useTranslations'
import { Spinner } from '../Spinner'
import { MessageContainerNoReceipt } from './styled'

export const ReceiptLoading = () => {
  const t = useTranslations()
  return (
    <MessageContainerNoReceipt>
      <Spinner type="alternate" />
      <h2>{t('receipt.receiptIsLoading')}</h2>
    </MessageContainerNoReceipt>
  )
}
