import styled from 'styled-components'

type DateProps = {
  isSelected: boolean
  isDisabled: boolean
}

type WeekButtonProps = {
  isDisabled?: boolean
}

// Time

export const TimeHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TimeHeader = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  height: 16px;
  margin-top: 17px;
  color: #434343;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.FontColor};
`

export const TimeHeaderHandler = styled.div`
  width: 49px;
  height: 32px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 20px;
`

export const TimeHeaderItem = styled(TimeHeaderHandler)`
  border: unset;
`

// Week

export const MonthHeader = styled(TimeHeader)`
  color: ${({ theme }) => theme.FontColor};
`

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Date = styled('div')<DateProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  line-height: 18px;
  font-weight: 600;

  flex-grow: 1;
  padding: 10px 1px;
  border-radius: 5px;

  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colour : 'transparent'};
  color: ${({ theme, isSelected, isDisabled }) => {
    if (theme.appTheme === 'light') {
      if (isSelected) {
        return '#fff'
      }
      if (isDisabled) {
        return '#cdcdcd'
      }
      return '#434343'
    }
    if (theme.appTheme !== 'ligth') {
      if (isSelected) {
        return '#fff'
      }
      if (isDisabled) {
        return '#434343'
      }
      return theme.FontColor
    }
  }};
`

export const WeekButton = styled('div')<WeekButtonProps>`
  display: flex;
  align-items: center;
  & > img {
    border-radius: 5px;
    background-color: ${({ theme, isDisabled }) => {
      if (isDisabled && theme.appTheme === 'light') {
        return '#e0e0e0'
      }
      if (isDisabled && theme.appTheme !== 'light') {
        return theme.ItemsBackGround
      }
      return theme.MainBackground
    }};
  }
`

export const PickerContainer = styled.div`
  width: 60%;
  & .picker-item-selected {
    color: ${({ theme }) => `${theme.FontColor} !important`};
  }
`
