import styled from 'styled-components'
import { ProgressBarProps } from '.'

const getCorrectWidth = (progress?: number) => {
  if (progress && progress === 100) {
    return '92px'
  }
  if (progress && progress < 100) {
    return `${progress}px`
  }
  return '8px'
}

export const ProgressBar_Container = styled.div`
  position: relative;
  width: 100px;
  height: 8px;
  background: #fff;
  border: 0.5px solid #cdcdcd;
  border-radius: 4px;
`

export const ProgressBar_Progress = styled('div')<ProgressBarProps>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;

  height: 8px;
  width: ${({ progress }) => getCorrectWidth(progress)};
  background: ${({ theme }) => `${theme.colour}`};
  transition: width 0.5s ease-out;
`
