import styled from 'styled-components'

type AmountHandlerContainerProps = {
  isSmall?: boolean
}

export const AmountHandlerContainer = styled.div<AmountHandlerContainerProps>`
  width: 100%;
  height: 58px;
  height: ${({ isSmall }) => (isSmall ? '50px' : '58px')};
  border: ${({ theme }) => `2px solid ${theme.colour}`};
  border-radius: 5px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colour};

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AmountHandlerButton = styled.span`
  width: 34%;
  height: 100%;
  background-color: ${({ theme }) => `${theme.colour}80`};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AmountHandlerCounter = styled.div`
  font-size: 20px;
  font-weight: 700;
  height: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
`
