import styled from 'styled-components'

type SchedulePProps = {
  hideBorder?: boolean
}

export const ScheduleRow = styled.div`
  display: flex;
  margin-bottom: 5px;
  p {
    margin-right: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #979797;
  }
`

export const ScheduleP = styled('p')<SchedulePProps>`
  border-right: ${({ hideBorder }) =>
    hideBorder ? 'unset' : '1px solid #979797'};
  padding-right: 5px;
  min-width: 55px;
  text-align: right;
`
