import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { RenderIf } from '../../../components/RenderIf'
import { useOpenModal } from '../../../hooks/useOpenModal'
import { useTranslations } from '../../../hooks/useTranslations'
import { qrMenuPOST } from '../../../api/qrMenu/POST'
import { Button } from '../../../components/Button'
import TableSelect from '../../Menu/TableSelect'
import {
  ButtonContainer,
  NotificationContainer,
  WaiterCallPrompt,
} from './styles'

type QRMenuNotificationsModalProps = {
  onDeclineClose: () => void
  onSuccessClose: () => void
}

export const QRMenuNotificationsModal: FC<QRMenuNotificationsModalProps> = ({
  onDeclineClose,
  onSuccessClose,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()
  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')

  // State
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Functions
  const callOrPrompt = () => {
    if (tableId) {
      callWaiter(tableId)
    } else {
      promptTableId()
    }
  }

  const promptTableId = () => {
    openModal({
      id: 'TABLE_SELECTION',
      title: t('canner.enterTableNumber'),
      shouldHideCross: true,
      components: [
        ({ closeThisModal }) => (
          <TableSelect
            onClose={(tableIdProp) => {
              callWaiter(tableIdProp)
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const callWaiter = async (tableIdProp?: string | number) => {
    if (tableIdProp) {
      try {
        setIsLoading(true)
        const success = await qrMenuPOST.callWaiter(+tableIdProp)
        if (success) {
          setIsSuccess(true)
        }
      } catch (e) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <NotificationContainer>
      <RenderIf condition={isSuccess && !isError}>
        <WaiterCallPrompt>{t('mainPage.waiterSuccess')}</WaiterCallPrompt>
        <ButtonContainer>
          <Button buttonType="stroke" width="100%" onClick={onSuccessClose}>
            {t('buttons.ok')}
          </Button>
        </ButtonContainer>
      </RenderIf>
      <RenderIf condition={isError && !isSuccess}>
        <WaiterCallPrompt>{t('errors.smthWentWrong')}...</WaiterCallPrompt>
        <ButtonContainer>
          <Button buttonType="stroke" width="100%" onClick={onDeclineClose}>
            {t('buttons.close')}
          </Button>
        </ButtonContainer>
      </RenderIf>
      <RenderIf condition={!isSuccess && !isError}>
        <WaiterCallPrompt>{t('mainPage.waiterHelpPrompt')}</WaiterCallPrompt>
        <ButtonContainer>
          <Button
            buttonType="stroke"
            onClick={onDeclineClose}
            disabled={isLoading}
          >
            {t('common.no')}
          </Button>
          <Button
            buttonType="common"
            onClick={callOrPrompt}
            isLoading={isLoading}
          >
            {t('common.yes')}
          </Button>
        </ButtonContainer>
      </RenderIf>
      <RenderIf condition={isSuccess && isError}>
        <ButtonContainer>
          <Button buttonType="stroke" width="100%" onClick={onDeclineClose}>
            {t('buttons.close')}
          </Button>
        </ButtonContainer>
      </RenderIf>
    </NotificationContainer>
  )
}
