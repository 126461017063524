import { IProduct } from '../../store/reducers/CategoriesReducer/types'
import { useOpenModal } from '../useOpenModal'

import { AddMenuItem } from './modals/AddProductsWithItems/AddMenuItem'
import { AddProductModal } from './modals/AddProductModal'
import { AddSubTemplateItem } from './modals/AddProductsWithItems/AddSubTemplateItem'
import { ModalProps } from '../../store/reducers/ModalsReducer/types'

export type OpenProductModalType = (
  product: IProduct,
  categoryName?: string
) => void

export const useOpenProductModal = (): OpenProductModalType => {
  const openModal = useOpenModal()

  const openDefaultProductModal = (product: IProduct, categoryName: string) => {
    const isSubtemplate = product.subTemplateItems?.length
    const isMenuItems =
      !product.subTemplateItems?.length && product.menuItems?.length
    const isCommonProduct =
      !product.subTemplateItems?.length && !product.menuItems?.length
    openModal({
      id: `ADD_PRODUCT_${product.id}`,
      title: product.subTemplateItems?.length ? product.title : categoryName,
      components: [
        ...(isSubtemplate
          ? [
              ({ closeThisModal }: ModalProps) => (
                <AddSubTemplateItem
                  subTemplateItems={product.subTemplateItems}
                  closeModal={closeThisModal}
                />
              ),
            ]
          : []),
        ...(isMenuItems
          ? [
              ({ onNext, closeThisModal }: ModalProps) => (
                <AddProductModal
                  product={product}
                  onNext={onNext}
                  closeProduct={closeThisModal}
                />
              ),
              ({ closeThisModal, onNext, onPrev, ...props }: ModalProps) => (
                <AddMenuItem
                  product={product}
                  menuItems={product.menuItems}
                  closeModal={closeThisModal}
                  {...props}
                />
              ),
            ]
          : []),
        ...(isCommonProduct
          ? [
              ({ onNext, closeThisModal }: ModalProps) => (
                <AddProductModal
                  product={product}
                  onNext={onNext}
                  closeProduct={closeThisModal}
                />
              ),
            ]
          : []),
      ],
    })
  }

  const openProductModal = (product: IProduct, categoryName = '') => {
    openDefaultProductModal(product, categoryName)
  }
  return openProductModal
}
