export enum QRMpostPathes {
  ORDER_PRODUCTS = '/order',
  CREATE_PAYMENT = '/payment/create',
  COMPLETE_PAYMENT = '/payment/complete',
  SEND_RECEIPT = '/order/email',
  CREATE_PAYMENT_VARIANT = '/payment-variants/create',
  CONFIRM_PAYMENT_VARIANT = '/payment-variants/confirm',
  ADD_NOTIFICATION_USER = '/notification/users',
  CALL_WAITER = '/callWaiter',
}

export enum CCpostPathes {
  CREATE_CARD = '/card/create',
  TRANSFER_CARD = '/card/register',
  REQUEST_CARD_REFILL = '/card/load/create',
  COMPLETE_CARD_REFILL = '/card/load/complete',
  CREATE_ORDER = '/order',
  COMPLETE_ORDER_PAYMENT = '/order/complete-payment',
}

export enum TRpostPathes {
  CREATE_RESERVATION = '/create',
  CANCEL_RESERVATION = '/cancel',
}

export enum MWpostPathes {
  ADD_ORDER_ITEMS = '/table/items',
  MIGRATE_ITEMS = '/table/items/migrate',
}
