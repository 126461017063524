import styled from 'styled-components'

export const SliderContainer = styled.div`
  width: 100%;
  overflow: scroll;
`

export const Slider = styled.div`
  position: relative;
  background-color: transparent;
  overflow: scroll;
  scroll-padding: 0 10px;
  padding: 0;
  display: flex;
  box-sizing: inherit;
  width: max-content;
`

export const SpecialItem = styled.div`
  width: 150px;
  height: 150px;
  border: ${({ theme }) => `1px solid ${theme.ItemsBorders}`};
  border-radius: 5px;
  margin: 10px 10px 0 0;
  overflow: hidden;
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 55px 63px 32px;

  background-color: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};

  &::after {
    content: '';
    position: absolute;
    border-top: 1px solid #cdcdcd;
    width: 90px;
    bottom: 30px;
    left: 30px;
  }
`

export const SpecialItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 25px;
  overflow: hidden;
`

export const Title = styled.p`
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.FontColor};
  text-align: center;
`

export const DescriptionContainer = styled(TitleContainer)`
  height: 32px;
`

export const Description = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.FontColor};
`

// Discounts

export const Percentage = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: flex-end;
  margin-top: 5px;
`

export const PercentageP = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  color: ${({ theme }) => theme.FontColor};
`

// Prices

export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: flex-end;
  margin-top: 5px;
  color: ${({ theme }) => theme.colour};
  p {
    font-weight: 700;
  }
`

export const CategoryItemText = styled.p`
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
  color: ${({ theme }) => theme.FontColor};
  line-height: 22px;
`
