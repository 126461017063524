import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Moment } from 'moment'
import { initialState, RoomType } from './types'

export const TableReservationSlice = createSlice({
  name: 'tableReservationSlice',
  initialState,
  reducers: {
    handleGuestCount(state, action: PayloadAction<number>) {
      return {
        ...state,
        guestCount: action.payload,
      }
    },
    //
    handleBookingDate(state, action: PayloadAction<Moment>) {
      return {
        ...state,
        bookingDate: action.payload,
      }
    },
    handleBookingTime(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        bookingTime: action.payload,
      }
    },
    //
    handleFirstName(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerName: action.payload,
      }
    },
    handleLastName(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerFamilyName: action.payload,
      }
    },
    handlePhoneNumber(state, action: PayloadAction<string>) {
      return {
        ...state,
        phoneNumber: action.payload,
      }
    },
    handleIsDataFull(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isDataFull: action.payload,
      }
    },
    //
    addAllergen(state, action: PayloadAction<number>) {
      return {
        ...state,
        allergens: [...state.allergens, action.payload],
      }
    },
    deleteAllergen(state, action: PayloadAction<number>) {
      return {
        ...state,
        allergens: state.allergens.filter((el) => el !== action.payload),
      }
    },
    addDiet(state, action: PayloadAction<string>) {
      return {
        ...state,
        diet: [...state.diet, action.payload],
      }
    },
    deleteDiet(state, action: PayloadAction<string>) {
      return {
        ...state,
        diet: state.diet.filter((el) => el !== action.payload),
      }
    },
    discardAllergens(state) {
      return {
        ...state,
        diet: [],
        allergens: [],
      }
    },
    handleNote(state, action: PayloadAction<string>) {
      return {
        ...state,
        note: action.payload,
      }
    },
    //
    updateRoomsList(state, action: PayloadAction<RoomType[]>) {
      return {
        ...state,
        availableRooms: action.payload,
        selectedRoom: action.payload?.[0]?.id || null,
        isRoomsLoaded: true,
      }
    },
    updateSelectedRoom(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedRoom: action.payload,
      }
    },
    //
    discardAllData() {
      return {
        ...initialState,
      }
    },
    //
    updateIsReservationCanceled(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isReservationCanceled: action.payload,
      }
    },
  },
})

export default TableReservationSlice.reducer
