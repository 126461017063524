import styled, { keyframes } from 'styled-components'

type SpinnerStyledProps = {
  background: string
  position: string
}

export const SpinnerStyled = styled('div')<SpinnerStyledProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ background }) => background || '#434343'};
  position: ${({ position }) => position || 'static'};
  z-index: 1;
  left: 0;
`

const heartbeat = keyframes`
  0%
  {
    transform: scale( .8 );
  }
  15%
  {
    transform: scale( .9 );
  }
  25%
  {
    transform: scale( .8 );
  }
  35%
  {
    transform: scale( 1 );
  }
  45%
  {
    transform: scale( .9 );
  }
  55%
  {
    transform: scale( 1 );
  }
  65%
  {
    transform: scale( .8 );
  }
  100%
  {
    transform: scale( .8 );
  }
`

export const SpinnerImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 40vw;
  animation: ${heartbeat} 1s infinite;
`
