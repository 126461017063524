import { useMyDispatch } from './useMyDispatch'
import { WishlistSlice } from '../store/reducers/WishlistReducer'
import { handleWishlist } from '../utils/handleWishlist'
import { handleLocalStorage } from '../utils/handleLocalStorage'

export const useWishlist = () => {
  const dispatch = useMyDispatch()
  const { updateWishList: updateWishListStore } = WishlistSlice.actions

  const updateWishList = (productId?: number) => {
    if (productId) {
      const wishlistToSet = handleWishlist(productId)
      dispatch(updateWishListStore(wishlistToSet))
      return wishlistToSet
    } else {
      const wishlistToSet = handleLocalStorage('wishlist')
      dispatch(updateWishListStore(wishlistToSet))
    }
  }
  return updateWishList
}
