import { FC, useState } from 'react'
import { Auth } from '@aws-amplify/auth'

import { Button } from '../../../../components/Button'

import { Error, InputContainer, StyledText } from '../styled'
import { Input } from '../../../../components/Input'
import { RenderIf } from '../../../../components/RenderIf'
import { useTranslations } from '../../../../hooks/useTranslations'

type ConfirmProps = {
  phone: string
  moveToLogin: () => void
}

export const Confirm: FC<ConfirmProps> = ({ phone, moveToLogin }) => {
  const t = useTranslations()

  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleInputBlur = (inputId: string, e: any) => {
    switch (inputId) {
      case 'code':
        setErrors((prevValue) =>
          prevValue.filter((el) => el !== t('errors.confirmation6'))
        )
        if (e.target.value?.length !== 6) {
          setErrors((prevValue) => [...prevValue, t('errors.confirmation6')])
        }
        break
      case 'password':
        setErrors((prevValue) =>
          prevValue.filter((el) => el !== t('errors.password8'))
        )
        if (e.target.value?.length < 8) {
          setErrors((prevValue) => [...prevValue, t('errors.password8')])
        }
        break
    }
  }

  const resetPassword = async () => {
    try {
      setIsLoading(true)
      setErrors([])
      const success = await Auth.forgotPasswordSubmit(phone, code, password)

      if (success) {
        setIsLoading(false)
        moveToLogin()
      }
    } catch (e: any) {
      setIsLoading(false)
      setErrors([e.message])
    }
  }

  return (
    <>
      <StyledText>{t('auth.confirmationCodePassword')}</StyledText>
      <InputContainer>
        <Input
          value={code}
          placeholder={t('auth.confirmationCode')}
          onChange={(e) => setCode(e.currentTarget.value)}
          onBlur={(e: any) => handleInputBlur('code', e)}
        />
      </InputContainer>
      <InputContainer>
        <Input
          value={password}
          placeholder={t('profile.newPassword')}
          onChange={(e) => setPassword(e.currentTarget.value)}
          onBlur={(e: any) => handleInputBlur('password', e)}
        />
      </InputContainer>
      <RenderIf condition={!!errors.length}>
        {errors.map((err) => (
          <Error key={err}>{err}</Error>
        ))}
      </RenderIf>
      <Button
        buttonType="common"
        width="100%"
        isLoading={isLoading}
        onClick={resetPassword}
        disabled={code?.length !== 6 || password?.length < 8 || isLoading}
      >
        {t('auth.updateMyPassword')}
      </Button>
    </>
  )
}
