import { FC, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Input } from '../../../components/Input'
import { Button } from '../../../components/Button'
import { Spinner } from '../../../components/Spinner'
import { RenderIf } from '../../../components/RenderIf'
import { PhoneNumberInput } from '../../../components/Input/PhoneNumberInput'
import { useTranslations } from '../../../hooks/useTranslations'
import { useOpenModal } from '../../../hooks/useOpenModal'
import { TermsConditions } from '../../TermsConditions'
import { CheckBox } from '../../../components/CheckBox'
import { AGBModal } from '../../TermsConditions/AGB'

import { LoyatyConfirmationSETType, LoyatyConfirmationType } from '.'

import { Error, InputContainer, StyledText } from './styled'
import { TermsAndConditionsCheckbox } from '../../TermsConditions/checkBox'

type SignUpProps = {
  moveToLogIn: () => void
  moveToConfirm: (userName: string) => void

  isLoyaltyChecked: boolean
  setIsLoyaltyChecked: (value: boolean) => void

  loyaltyConfirmation: LoyatyConfirmationType
  handleLoyaltyConfirmation: (
    type: LoyatyConfirmationSETType,
    value: string
  ) => void
}

export const SignUp: FC<SignUpProps> = ({
  moveToLogIn,
  moveToConfirm,
  isLoyaltyChecked,
  setIsLoyaltyChecked,
  loyaltyConfirmation,
  handleLoyaltyConfirmation,
}) => {
  const t = useTranslations()
  const openModal = useOpenModal()

  // State
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string[]>([])
  //
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  //
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isTermsChecked, setIsTermsChecked] = useState(false)
  //

  //
  const { loyaltyNumber, accessCode, lastTransaction } = loyaltyConfirmation

  // Functions
  const signUp = async () => {
    if (password === confirmPassword) {
      try {
        setIsLoading(true)
        setError([])
        await Auth.signOut()
        window.localStorage.removeItem('userName')
        const data = await Auth.signUp({
          username: phone,
          password,
          attributes: {
            phone_number: phone,
            name: firstName,
            family_name: lastName,
          },
        })
        if (data.codeDeliveryDetails.DeliveryMedium === 'SMS') {
          moveToConfirm(data.user.getUsername())
        }
      } catch (e: any) {
        setError((prevValue) => [...prevValue, e.message])
      } finally {
        setIsLoading(false)
      }
    } else {
      setError([t('errors.passwordsMatch')])
    }
  }

  const handlePhoneNumberChange = (value: string) => {
    if (!value.length) {
      setPhone('+')
    } else {
      setPhone(value)
    }
  }

  const handlePhoneNumberFocus = () => {
    if (!phone.length) {
      setPhone('+41')
    }
  }

  const handleInputChange = (checkValue: any) => {
    const value = Number(checkValue)
    if (!isNaN(value)) return value
    return false
  }

  const setLoyaltyData = (type: LoyatyConfirmationSETType, value: string) => {
    if (type === 'accessCode' && value?.length) {
      handleLoyaltyConfirmation(type, value)
    }
    if (type === 'loyaltyNumber' && value?.length && handleInputChange(value)) {
      handleLoyaltyConfirmation(type, value)
    }
    if (!value?.length) {
      handleLoyaltyConfirmation(type, '')
    }
  }

  const handleInputBlur = (inputId: string, e: any) => {
    switch (inputId) {
      case 'firstName':
        setError((prevValue) =>
          prevValue.filter((el) => el !== t('errors.enterFirstName'))
        )
        if (e.target.value?.length < 1) {
          setError((prevValue) => [...prevValue, t('errors.enterFirstName')])
        }
        break
      case 'lastName':
        setError((prevValue) =>
          prevValue.filter((el) => el !== t('errors.enterLastName'))
        )
        if (e.target.value?.length < 1) {
          setError((prevValue) => [
            ...prevValue,
            t('errors.enterLastNameshortPhoneNumber'),
          ])
        }
        break
      case 'phone':
        setError((prevValue) =>
          prevValue.filter((el) => el !== t('errors.shortPhoneNumber'))
        )
        if (e.target.value?.length < 9) {
          setError((prevValue) => [...prevValue, t('errors.shortPhoneNumber')])
        }
        break
      case 'password':
        setError((prevValue) =>
          prevValue.filter((el) => el !== t('errors.password8'))
        )
        if (e.target.value?.length < 8) {
          setError((prevValue) => [...prevValue, t('errors.password8')])
        }
        break
      case 'confirmPassword':
        setError((prevValue) =>
          prevValue.filter((el) => el !== t('errors.passwordsMatch'))
        )
        if (e.target.value?.length < 8) {
          setError((prevValue) => [...prevValue, t('errors.passwordsMatch')])
        }
        break
    }
  }

  const openTermsAndConditions = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'TERMS_AND_CONDITIONS',
      title: 'Terms and Conditions',
      components: [() => <TermsConditions />],
    })
  }

  const openAGB = (e: any) => {
    e.stopPropagation()
    openModal({
      id: 'AGB_MODAL',
      title: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
      components: [() => <AGBModal />],
    })
  }

  return (
    <div style={{ position: 'relative' }}>
      <RenderIf condition={isLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <StyledText isHeader>Sign Up</StyledText>
      <InputContainer>
        <Input
          placeholder={t('auth.firstName')}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
          onBlur={(e: any) => handleInputBlur('firstName', e)}
        />
        <Input
          placeholder={t('auth.lastName')}
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
          onBlur={(e: HTMLInputElement) => handleInputBlur('lastName', e)}
        />
      </InputContainer>
      <InputContainer>
        <PhoneNumberInput
          // placeholder={t('auth.phoneNumber}
          value={phone}
          onFocus={handlePhoneNumberFocus}
          onChange={handlePhoneNumberChange}
          onBlur={(e: HTMLInputElement) => handleInputBlur('phone', e)}
        />
      </InputContainer>
      <InputContainer>
        <Input
          placeholder={t('auth.password')}
          value={password}
          type="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
          onBlur={(e: HTMLInputElement) => handleInputBlur('password', e)}
        />
      </InputContainer>
      <InputContainer>
        <Input
          placeholder={t('auth.confirmPassword')}
          value={confirmPassword}
          type="password"
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          onBlur={(e: any) => handleInputBlur('confirmPassword', e)}
        />
      </InputContainer>
      <RenderIf condition={!!error.length}>
        {error.map((err) => (
          <Error key={err}>{err}</Error>
        ))}
      </RenderIf>
      {/*  */}
      <InputContainer>
        <CheckBox
          isChecked={isLoyaltyChecked}
          onClick={setIsLoyaltyChecked}
          label={t('auth.alreadyHaveLoyaltyCard')}
        />
      </InputContainer>
      <InputContainer>
        <Input
          disabled={!isLoyaltyChecked}
          placeholder={t('profile.card')}
          value={loyaltyNumber}
          onChange={(e) =>
            setLoyaltyData('loyaltyNumber', e.currentTarget.value)
          }
          inputMode="numeric"
        />
        <Input
          disabled={!isLoyaltyChecked}
          placeholder={t('auth.accessCode')}
          value={accessCode}
          onChange={(e) => setLoyaltyData('accessCode', e.currentTarget.value)}
          inputMode="numeric"
        />
      </InputContainer>
      <InputContainer>
        <TermsAndConditionsCheckbox
          isChecked={isTermsChecked}
          handleCheck={setIsTermsChecked}
        />
      </InputContainer>
      <Button
        buttonType="common"
        width="100%"
        disabled={
          phone?.length < 9 ||
          firstName?.length < 1 ||
          lastName?.length < 1 ||
          password?.length < 8 ||
          confirmPassword?.length < 8 ||
          !isTermsChecked ||
          isLoading ||
          (isLoyaltyChecked &&
            (!loyaltyNumber?.length ||
              // !lastTransaction?.length ||
              !accessCode?.length))
        }
        onClick={signUp}
      >
        {t('auth.signUp')}
      </Button>
      <StyledText>{t('auth.or')}</StyledText>
      <Button
        buttonType="stroke"
        width="100%"
        disabled={isLoading}
        onClick={moveToLogIn}
      >
        {t('auth.logIn')}
      </Button>
    </div>
  )
}
