import { useEffect, useState } from 'react'
import { getRestaurants } from '../../store/reducers/RestaurantsReducer/actionCreator'
import { IRestaurant } from '../../store/reducers/RestaurantsReducer/types'
import { Schedule as ScheduleBoard } from '../../components/Schedule'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { useMyDispatch } from '../../hooks/useMyDispatch'
import { useMySelector } from '../../hooks/useMySelector'
import { Header } from '../../components/Header'
import { GridDiv } from '../../utils/gridDiv'
import { Map } from './Map'

import { IconStroked } from '../../components/IconsContainers/styled'
import { ReactComponent as MarkerImage } from '../../assets/mapMarkerImage.svg'
import {
  RestaurantsContainer,
  RestaurantsList,
  RestaurantItem,
  RestaurantItemTextContainer,
  RestaurantItemText,
  Schedule,
} from './styled'

export const Restaurants = () => {
  const dispatch = useMyDispatch()
  const { restaurants } = useMySelector((state) => state)
  const { colour } = useMySelector((state) => state.app.interfaceStyles)
  const guid = handleLocalStorage('guid')

  const [selectedRestaurant, setSelectedRestaurant] = useState<IRestaurant>()

  const loadRestaurants = async () => {
    if (guid) {
      dispatch(getRestaurants({}))
    }
  }

  useEffect(() => {
    loadRestaurants()
  }, [guid])

  return (
    <RestaurantsContainer>
      <Header />
      <Map
        restaurants={restaurants}
        selectedCoords={
          selectedRestaurant?.position || restaurants?.[0]?.position
        }
      />
      <RestaurantsList>
        {restaurants?.map((R) => (
          <RestaurantItem
            key={`restaurant_${R.id}`}
            isSelected={selectedRestaurant?.id === R.id}
            onClick={() => setSelectedRestaurant(R)}
          >
            <GridDiv
              gr="1/-1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <IconStroked color={colour}>
                <MarkerImage />
              </IconStroked>
            </GridDiv>
            <RestaurantItemTextContainer>
              <RestaurantItemText>{R.title}</RestaurantItemText>
              <Schedule>
                <ScheduleBoard schedule={R.schedule} />
              </Schedule>
            </RestaurantItemTextContainer>
          </RestaurantItem>
        ))}
      </RestaurantsList>
    </RestaurantsContainer>
  )
}

export default Restaurants
