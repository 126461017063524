import { FC, MouseEvent, useState } from 'react'

import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { IOrderProduct } from '../../../sections/Order/types'

import { convertToPrice } from '../../../utils/convertToPrice'
import { getPrice } from '../../../utils/getProductPrice'
import { useMySelector } from '../../../hooks/useMySelector'

import fullHeart from '../../../assets/hearts/full_s.svg'
import emptyHeart from '../../../assets/hearts/empty_s.svg'
import { ReactComponent as ClocheIcon } from '../../../assets/cloches/clocheWide.svg'

import { useWishlist } from '../../../hooks/useWishlist'
import { AllergenImages } from '../../../utils/AllergenImages'
import { RenderIf } from '../../RenderIf'

import {
  Allergen,
  AllergensContainer,
  Price,
  ProductCounter,
  ProductImage,
  ProductImageContainer,
  ProductStyled,
  ProductTitle,
  SecondaryImage,
  SoldOutBanner,
} from './styled'
import { IconFilled } from '../../IconsContainers/styled'
import { useTranslations } from '../../../hooks/useTranslations'
import { isProductProtected } from '../../../utils/isProductProtected'
import { getProtectedAge } from '../../../utils/getProtectedAge'

type ProductProps = {
  product: IProduct | IOrderProduct
  amount: number
  onProductClick?: (product: IProduct, e: MouseEvent<HTMLElement>) => void
}

export const ProductGrided: FC<ProductProps> = ({
  product,
  amount,
  onProductClick,
}) => {
  // Hooks
  const t = useTranslations()
  const handleWishlist = useWishlist()

  // Store
  const { wishlist } = useMySelector((state) => state)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const { colour } = useMySelector((state) => state.app.interfaceStyles)

  // State
  const [isImgErrored, setIsImageErrored] = useState(false)

  // Functions
  const isInWishlist = (idToCheck: number) => {
    return !!wishlist?.find((listItem) => listItem === idToCheck)
  }

  const handleWishListClick = (e: any) => {
    e.stopPropagation()
    handleWishlist(product?.id)
  }

  const handleProductClick = (e: MouseEvent<HTMLElement>) => {
    if (onProductClick && Boolean(!product?.soldOut)) {
      onProductClick(product, e)
    }
  }

  return (
    <ProductStyled onClick={handleProductClick} isSelected={!!amount}>
      <RenderIf condition={!!amount}>
        <ProductCounter>{amount}</ProductCounter>
      </RenderIf>
      <ProductImageContainer>
        <RenderIf condition={!isImgErrored}>
          <ProductImage
            src={product?.imageUrl || 'ERRORED_IMAGE'}
            onError={({ currentTarget }) => {
              setIsImageErrored(true)
              currentTarget.onerror = null // prevents looping
            }}
            alt="product"
            height="57"
            width="125"
          />
        </RenderIf>
        <RenderIf condition={isImgErrored}>
          <IconFilled color={`${colour}80`}>
            <ClocheIcon />
          </IconFilled>
        </RenderIf>
      </ProductImageContainer>
      <ProductTitle>{product?.title}</ProductTitle>
      <AllergensContainer>
        {product?.allergens?.split(', ').map((el) => (
          <Allergen
            key={el}
            alt={el}
            src={AllergenImages[el] || AllergenImages[0]}
          />
        ))}
      </AllergensContainer>
      <RenderIf condition={Boolean(getPrice(product))}>
        <Price isSoldOut={product?.soldOut}>
          <p>{convertToPrice(getPrice(product))}</p>&thinsp;
          <p>{Currency || ''}</p>
          <RenderIf condition={Boolean(product?.soldOut)}>
            <SoldOutBanner>{t('products.soldOut')}</SoldOutBanner>
          </RenderIf>
        </Price>
      </RenderIf>
      <SecondaryImage onClick={handleWishListClick}>
        <img
          alt="wishlist"
          src={isInWishlist(product?.id) ? fullHeart : emptyHeart}
          width="12"
          height="11"
        />
      </SecondaryImage>
      <RenderIf condition={isProductProtected(product)}>
        <SecondaryImage isRight>
          <p>{getProtectedAge(product)}</p>
        </SecondaryImage>
      </RenderIf>
    </ProductStyled>
  )
}
