import { RestaurantsSlice } from '.'
import { AppDispatch } from '../../index'
import { IFetchRestaurants, IRestaurant } from './types'
import { qrMenuGET } from '../../../api/qrMenu/GET'

export const getRestaurants =
  (params: IFetchRestaurants) => async (dispatch: AppDispatch) => {
    try {
      const restaurants: IRestaurant[] = await qrMenuGET.restaurants(
        params.ActiveBranchID
      )
      dispatch(RestaurantsSlice.actions.setRestaurants(restaurants))
    } catch (e) {
      console.log('restaurants request failure')
    }
  }
