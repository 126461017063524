import { ScheduleType, week, weekArr } from './types'

export const convertToSchedule = (sch?: ScheduleType) => {
  const schedule: any = {}
  const closed: any = []
  if (sch) {
    weekArr.map((day) => {
      if (sch[day]) {
        const newKey =
          sch[day].split('').splice(0, 5).join('') +
          sch[day].split('').splice(8, 3).join('') +
          sch[day].split('').splice(11, 5).join('')
        if (schedule[newKey]) {
          schedule[newKey] = schedule[newKey].slice(0, 4) + '-' + week[day]
        } else {
          schedule[newKey] = week[day]
        }
      } else {
        closed.push(week[day])
      }
    })
  }
  return [
    Object.entries(schedule).map(([key, value]) => [value, key]),
    closed.sort(),
  ]
}
