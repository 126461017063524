import { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { RenderIf } from '../../components/RenderIf'
import { SelfService } from './components/SelfService'
import { useMySelector } from '../../hooks/useMySelector'
import { errorHandler } from '../../components/ErrorHandler'
import { AmountHandler } from '../../components/AmountHandler'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { ISelectedProduct } from '../../store/reducers/SelectedProductsReducer/types'
import { TimeLocation } from '../../components/TimeLocation'
import { getPrice } from '../../utils/getProductPrice'
import { Spinner } from '../../components/Spinner'
import { Header } from '../../components/Header'
import { Cart } from '../../components/Cart.tsx'
import { IOrderProduct } from '../Order/types'
import { Footer } from './components/Footer'
import { isAuth } from '../../utils/isAuth'
import { Split } from './components/Split'
import { Tips } from './components/Tips'
import {
  IProduct,
  ITemplateItem,
} from '../../store/reducers/CategoriesReducer/types'

import {
  CurrentPageType,
  PaymentDetailsType,
  PaymentDetailsTypeExtended,
  ProductToPay,
} from './types'
import { PreviewContainer } from './styled'
import { EmptyBlock } from '../Menu/Categories/styled'
import { qrMenuGET } from '../../api/qrMenu/GET'
import history from '../../utils/history'
import { useOpenModal } from '../../hooks/useOpenModal'
import { AskWaiterModal } from '../MainScreen/AskWaiterModal'

export const Payment = () => {
  // Hooks
  const openModal = useOpenModal()

  // Store
  const { products } = useMySelector((state) => state)
  const { selectedProducts } = useMySelector((state) => state)
  const { paymentVariant } = useMySelector((state) => state.app.menu)
  const { isAppInit } = useMySelector((state) => state.app)

  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')
  const guid = handleLocalStorage('guid')

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSelfServicePayEnabled, setIsSelfServicePayEnabled] =
    useState<boolean>(false)

  const [tipsValue, setTipsValue] = useState<number>(0)
  const [totalWithTips, setTotalWithTips] = useState<number>(0)
  const [totalWithOutTips, setTotalWithOutTips] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<CurrentPageType>('cart')
  const [isPaymentAvailable, setIsPaymentAvailable] = useState<boolean>(false)
  const [paymentData, setPaymentData] = useState<PaymentDetailsTypeExtended>()

  const { cloudOrdersList, isColudOrderExist, isColudOrderChecked } =
    useMySelector((state) => state.cloudOrders)
  const { categories: categoriesFromState } = useMySelector((state) => state)
  const isClick = process.env.REACT_APP_IS_CLIC === '1'

  const productsToShow = useMemo(() => {
    if (isColudOrderChecked && isColudOrderExist && cloudOrdersList?.length) {
      return cloudOrdersList
        ?.map((co: any) => {
          const category = categoriesFromState?.find(
            (cat) => cat.id === co.categoryId
          )
          const product = category?.products?.find(
            (product) => product.id === co.productId
          )
          return product as IOrderProduct
        })
        ?.filter((e) => e)
    } else {
      return paymentData?.products
    }
  }, [
    paymentData?.products,
    cloudOrdersList,
    isColudOrderExist,
    isColudOrderChecked,
  ])

  const [productsToPay, setProductsToPay] = useState<ProductToPay[]>([])
  const [isFooterShown, setIsFooterShown] = useState<boolean>(true)
  const [currentSelectedProductId, setCurrentSelectedProductId] =
    useState<number>(0)

  /// SelfService saved card state
  const [useSavedCard, setUseSavedCard] = useState(false)
  const [useCustomerCard, setUseCustomerCard] = useState(false)
  const [updateActiveCard, setUpdateActiveCard] = useState(false)

  // useEffects
  useEffect(() => {
    getIsAuthorized()
  }, [])

  useEffect(() => {
    const newSplitTotal = productsToPay.reduce((accP, product) => {
      const selected: any = productsToShow?.find(
        (p) => (p.itemId || p.id) === product.itemId
      )
      if (selected) {
        const { menuItems = [] } = selected
        const currentPrice = getPrice(selected)
        return (
          accP +
          (currentPrice +
            menuItems?.reduce(
              (acc: any, { count, price }: any) => acc + count * price,
              0
            )) *
            (product.count || 0)
        )
      } else {
        return accP
      }
    }, 0)
    setTotalWithOutTips(newSplitTotal as number)
    setTotalWithTips(newSplitTotal as number)
  }, [JSON.stringify(productsToPay)]) // eslint-disable-line

  useEffect(() => {
    if (totalWithOutTips) {
      setTotalWithTips(totalWithOutTips + tipsValue)
    }
  }, [totalWithOutTips, tipsValue]) // eslint-disable-line

  useEffect(() => {
    if (paymentData?.total) {
      setTotalWithOutTips(paymentData?.total)
      setTotalWithTips(paymentData?.total + tipsValue)
    }
  }, [paymentData?.total]) // eslint-disable-line

  useEffect(() => {
    if (paymentData?.orderState === 1) {
      openAskWaiterModal()
      history.replace('/')
    }
  }, [paymentData?.orderState]) // eslint-disable-line

  useEffect(() => {
    getPaymentDetails()
  }, [JSON.stringify(selectedProducts), isAppInit]) // eslint-disable-line

  useEffect(() => {
    if (paymentData && paymentVariant === 2) {
      addProductsToPaymentData(paymentData)
    }
  }, [paymentData?.lastModified, products]) // eslint-disable-line

  // Functions
  const openAskWaiterModal = () => {
    openModal({
      id: 'ASK_WAITER',
      components: [
        ({ closeThisModal }) => <AskWaiterModal onClose={closeThisModal} />,
      ],
    })
  }

  const getIsAuthorized = async () => {
    const value = await isAuth()
    setIsSelfServicePayEnabled(!!value)
  }

  const addProductsToPaymentData = (paymentData: PaymentDetailsType) => {
    const paymentDetailsWithProducts: PaymentDetailsTypeExtended = {
      ...paymentData,
      products: paymentData.orderItems
        .map((orderItem) => {
          let foundProduct: IProduct | ITemplateItem | undefined =
            products?.find((product) => product.id === orderItem.id)
          if (!foundProduct) {
            foundProduct = products
              ?.reduce((acc: ITemplateItem[], curr) => {
                if (curr.subTemplateItems) {
                  return [...acc, ...curr.subTemplateItems]
                }
                return acc
              }, [])
              ?.find((st) => st.id === orderItem.id)
          }
          if (!foundProduct) {
            return
          }
          return {
            ...foundProduct,
            itemId: orderItem.itemId,
            menuItems: orderItem.menuItems,
            amount: orderItem.count,
          }
        })
        .filter((p) => p) as IOrderProduct[],
    }
    setPaymentData(paymentDetailsWithProducts)
  }

  const getPaymentDetails = async () => {
    try {
      setIsLoading(true)
      if (paymentVariant === 1 || process.env.REACT_APP_IS_CLIC === '1') {
        const productsNotes = handleLocalStorage('products_notes')
        const productsToSet = selectedProducts.reduce(
          (acc: any[], curr: ISelectedProduct) => {
            let foundProduct: IProduct | ITemplateItem | undefined =
              products?.find((sp) => sp.id === curr.id)
            if (!foundProduct) {
              foundProduct = products
                ?.reduce((acc: ITemplateItem[], current) => {
                  if (current.subTemplateItems) {
                    return [...acc, ...current.subTemplateItems]
                  }
                  return acc
                }, [])
                ?.find((st) => st.id === curr.id)
            }
            return [
              ...acc,
              {
                ...foundProduct,
                ...curr,
                count: curr?.amount || 0,
                kitchenInfo: curr?.selectedKitchenInfo || '{}',
                price: getPrice(foundProduct),
                productId: curr?.id,
                customerNote: productsNotes?.[foundProduct?.id || 0],
              },
            ]
          },
          []
        )
        setProductsToPay(productsToSet)
        setPaymentData({
          products: productsToSet,
          orderItems: productsToSet,
          lastModified: Date.now(),
          total: productsToSet?.reduce((acc, curr) => {
            const { menuItems = [] } = curr
            return (
              acc +
              curr.price * (curr.count ?? 0) +
              menuItems?.reduce(
                (acc: number, { count, price }: any) => acc + count * price,
                0
              )
            )
          }, 0),
        })
      }
      if (paymentVariant === 2 && process.env.REACT_APP_IS_CLIC === '0') {
        if (guid && tableId) {
          const paymentDetails: PaymentDetailsType =
            await qrMenuGET.paymentDetails(+tableId)
          if (paymentDetails) {
            addProductsToPaymentData(paymentDetails)
          }
        }
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCount = (newValue: number) => {
    if (currentSelectedProductId) {
      setProductsToPay((prevProductsToPay) => {
        const arrayToSet = [...prevProductsToPay]
        const objIndex = arrayToSet.findIndex(
          (ptp) => ptp.itemId === currentSelectedProductId
        )
        arrayToSet[objIndex].count = newValue
        return arrayToSet
      })
    }
  }

  const renderAmountHandler = () => (
    <AmountHandler
      isSmall
      currentValue={
        productsToPay.find((pTp) => pTp.itemId === currentSelectedProductId)
          ?.count || 0
      }
      minValue={0}
      maxValue={
        productsToPay.find((pTp) => pTp.itemId === currentSelectedProductId)
          ?.maxAmount
      }
      onDecrease={handleCount}
      onIncrease={handleCount}
    />
  )

  const handleUseSavedCard = (value: boolean) => {
    setUseSavedCard(value)
    if (value) {
      setUpdateActiveCard(false)
    }
  }

  return (
    <>
      <PreviewContainer>
        <Header />
        <RenderIf condition={process.env.REACT_APP_IS_CLIC === '1'}>
          <TimeLocation customTop="70px" />
        </RenderIf>
        <EmptyBlock
          height={process.env.REACT_APP_IS_CLIC === '1' ? '192px' : '70px'}
        />
        <RenderIf condition={isLoading || (!isColudOrderChecked && !isClick)}>
          <Spinner type="alternate" />
        </RenderIf>
        <RenderIf
          condition={
            !isLoading &&
            (isColudOrderChecked || isClick) &&
            currentPage === 'cart'
          }
        >
          <Cart
            products={productsToShow}
            setIsPaymentAvailable={setIsPaymentAvailable}
          />
        </RenderIf>
        <RenderIf
          condition={
            !isLoading &&
            (isColudOrderChecked || isClick) &&
            currentPage === 'split'
          }
        >
          <Split
            products={productsToShow}
            productsToPay={productsToPay}
            setProductsToPay={setProductsToPay}
            //
            currentSelectedProductId={currentSelectedProductId}
            setCurrentSelectedProductId={setCurrentSelectedProductId}
            setIsPaymentAvailable={setIsPaymentAvailable}
          />
        </RenderIf>
        <RenderIf
          condition={
            !isLoading &&
            (isColudOrderChecked || isClick) &&
            currentPage === 'tips'
          }
        >
          <Tips
            total={totalWithOutTips || paymentData?.total || 0}
            tipsValue={tipsValue}
            onChange={setTipsValue}
          />
        </RenderIf>
        <RenderIf
          condition={
            !isLoading &&
            (isColudOrderChecked || isClick) &&
            currentPage === 'selfService'
          }
        >
          <SelfService
            enablePayButton={setIsSelfServicePayEnabled}
            setIsFooterShown={setIsFooterShown}
            useSavedCard={useSavedCard}
            setUseSavedCard={handleUseSavedCard}
            updateActiveCard={updateActiveCard}
            setUpdateActiveCard={setUpdateActiveCard}
          />
        </RenderIf>
        <RenderIf condition={isFooterShown}>
          <EmptyBlock height="120px" />
        </RenderIf>
      </PreviewContainer>

      <RenderIf condition={isFooterShown}>
        <Footer
          total={totalWithTips}
          tipsValue={tipsValue}
          //
          currentPage={currentPage}
          enableTips={() => setCurrentPage('tips')}
          enableSelfService={() => setCurrentPage('selfService')}
          enableSplit={() => {
            setTotalWithTips(0)
            setCurrentPage('split')
          }}
          //
          productsToPay={productsToPay}
          renderAmountHandler={renderAmountHandler}
          //
          isSelfServicePayDisabled={!isSelfServicePayEnabled}
          isPaymentAvailable={isPaymentAvailable}
          //
          useSavedCard={useSavedCard}
          updateActiveCard={updateActiveCard}
          //
          useCustomerCard={useCustomerCard}
        />
      </RenderIf>
    </>
  )
}

export default Payment
