import { FC, useState } from 'react'
import { Button } from '../../../../components/Button'
import { RenderIf } from '../../../../components/RenderIf'
import { useMySelector } from '../../../../hooks/useMySelector'
import { useTranslations } from '../../../../hooks/useTranslations'
import { clickCollectPOST } from '../../../../api/clickCollect/POST'
import { convertToPrice } from '../../../../utils/convertToPrice'
import { GridDiv } from '../../../../utils/gridDiv'
import { CurrentPageType, ProductToPay } from '../../types'
import { DataTrans } from '../../../../components/Checkout/DataTrans'
import history from '../../../../utils/history'

type CCFooterProps = {
  total: number
  currentPage: CurrentPageType
  tipsValue: number
  productsToPay: ProductToPay[]
  enableTips: () => void
  enableSelfService: () => void
  isSelfServicePayDisabled: boolean
  useSavedCard: boolean
  updateActiveCard: boolean
  handlePayment: (value: () => Promise<any>) => Promise<boolean | undefined>
  isLoading: boolean
  isPaymentAvailable?: boolean
}

export const CCFooter: FC<CCFooterProps> = ({
  currentPage,
  productsToPay,
  enableSelfService,
  isSelfServicePayDisabled,
  useSavedCard,
  updateActiveCard,
  handlePayment,
  isLoading,
  isPaymentAvailable,
}) => {
  // Hooks
  const t = useTranslations()

  // State
  const [TransactionId, setTransactionId] = useState<string>()

  const paymentRequest = async () => {
    const res = await handlePayment(async () => {
      const result = await clickCollectPOST.createOrder(
        productsToPay,
        !useSavedCard,
        updateActiveCard
      )
      const { orderNr, paymentProvider, data, transactionId } = result
      if (orderNr) {
        history.replace(`/?payment_success_nr=${orderNr}`)
      }
      if (paymentProvider === 1 && transactionId) {
        setTransactionId(transactionId)
      }
    })
    return res
  }

  return (
    <>
      <RenderIf
        condition={typeof TransactionId === 'string' && !!TransactionId?.length}
      >
        <DataTrans transactionId={TransactionId as string} />
      </RenderIf>
      <RenderIf condition={currentPage === 'cart'}>
        <GridDiv gc="1 / -1">
          <Button
            buttonType="common"
            width="100%"
            isLoading={isLoading}
            onClick={enableSelfService}
            disabled={!isPaymentAvailable}
          >
            {/* {t('auth.confirmButton')} */}

            {t('common.next')}
          </Button>
        </GridDiv>
      </RenderIf>
      <RenderIf condition={currentPage === 'selfService'}>
        <GridDiv gc="1 / -1">
          <Button
            buttonType="common"
            width="100%"
            disabled={isSelfServicePayDisabled}
            onClick={paymentRequest}
            isLoading={isLoading}
          >
            {/* {t('cover.pay')} */}
            {t('auth.confirmButton')}
          </Button>
        </GridDiv>
      </RenderIf>
    </>
  )
}
