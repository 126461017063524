import { FC } from 'react'
import { Auth } from '@aws-amplify/auth'
import { useSearchParams } from 'react-router-dom'
import { useTranslations } from '../../../hooks/useTranslations'
import { RenderIf } from '../../../components/RenderIf'
import history from '../../../utils/history'
import { PageType } from '../'

import { ReactComponent as EditIcon } from '../../../assets/profile/edit.svg'
import { ReactComponent as CardIcon } from '../../../assets/profile/card.svg'
import { ReactComponent as PaymentVariant } from '../../../assets/profile/credit_card.svg'
import { ReactComponent as OrdersIcon } from '../../../assets/profile/orders.svg'
import { ReactComponent as WishListIcon } from '../../../assets/profile/wishlist.svg'
import { ReactComponent as ExitIcon } from '../../../assets/profile/exit.svg'

import { IconStroked } from '../../../components/IconsContainers/styled'
import { Page, ProfileOption } from './styled'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'
import { useMySelector } from '../../../hooks/useMySelector'

type MenuProps = {
  setCurrentPage: (page: PageType) => void
  onNext: () => void
  closeThisModal: () => void
}

export const Menu: FC<MenuProps> = ({
  setCurrentPage,
  onNext,
  closeThisModal,
}) => {
  // Hooks
  const t = useTranslations()
  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')
  const registerId = handleLocalStorage('registerId')
  const { DatatransActivated } = useMySelector((state) => {
    return state.app.companyData
  })

  // Functions
  const handleWishlistClick = () => {
    if (tableId) {
      history.push(`/wishlist?table=${tableId}`)
    }
    if (!tableId) {
      history.push('/wishlist')
    }
    closeThisModal()
  }

  const logOut = async () => {
    if (navigator.onLine) {
      await Auth.signOut()
      window.localStorage.removeItem('userName')
      closeThisModal()
    }
  }

  return (
    <>
      <Page>{t('profile.myProfile')}</Page>

      <RenderIf condition={navigator.onLine}>
        <ProfileOption
          onClick={() => {
            setCurrentPage('edit')
            onNext()
          }}
        >
          <IconStroked>
            <EditIcon />
          </IconStroked>
          <p>{t('profile.editProfile')}</p>
        </ProfileOption>
      </RenderIf>

      <ProfileOption
        onClick={() => {
          setCurrentPage('orders')
          onNext()
        }}
      >
        <IconStroked>
          <OrdersIcon />
        </IconStroked>
        <p>{t('profile.orders')}</p>
      </ProfileOption>

      <ProfileOption
        onClick={() => {
          setCurrentPage('card')
          onNext()
        }}
      >
        <IconStroked>
          <CardIcon />
        </IconStroked>
        <p>{t('profile.card')}</p>
      </ProfileOption>

      <RenderIf condition={DatatransActivated}>
        <ProfileOption
          onClick={() => {
            setCurrentPage('payment')
            onNext()
          }}
        >
          <IconStroked>
            <PaymentVariant />
          </IconStroked>
          <p>{t('profile.paymentMethod')}</p>
        </ProfileOption>
      </RenderIf>

      <RenderIf condition={registerId}>
        <ProfileOption onClick={handleWishlistClick}>
          <IconStroked>
            <WishListIcon />
          </IconStroked>
          <p>{t('profile.wishlist')}</p>
        </ProfileOption>
      </RenderIf>

      <RenderIf condition={navigator.onLine}>
        <ProfileOption onClick={logOut}>
          <IconStroked>
            <ExitIcon />
          </IconStroked>
          <p>{t('profile.logout')}</p>
        </ProfileOption>
      </RenderIf>
    </>
  )
}
