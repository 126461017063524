import styled from 'styled-components'

type StyledTextProps = {
  isHeader?: boolean
}

export const InputContainer = styled.div`
  margin: 10px 0;
  display: flex;
  column-gap: 10px;
`

export const StyledText = styled('p')<StyledTextProps>`
  width: 100%;
  text-align: center;
  margin: 10px 0;
  color: ${({ theme }) => theme.FontColor};
  font-size: ${({ isHeader = false }) => (isHeader ? '1.2rem' : '1rem')};
  font-weight: ${({ isHeader = false }) => (isHeader ? '600' : '400')};
`

export const LinkText = styled(StyledText)`
  display: inline;
  color: ${({ theme }) => theme.colour};
  text-decoration: underline;
`

export const Error = styled.p`
  margin: 10px 0;
  color: red;
`
