import { CategoriesSlice } from '.'
import { AppDispatch } from '../../index'
import { ICategory, IProduct } from './types'
import { ProductsSlice } from '../ProductsReducer'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import { errorHandler } from '../../../components/ErrorHandler'
import { getCategoriesFiltered } from '../../../utils/getCategoriesFiltered'

export const getCategoriesData =
  (search?: string) => async (dispatch: AppDispatch) => {
    try {
      const kitchenTimeSlots = await qrMenuGET.getKitchenTimeSlots()
      const categories: ICategory[] = await getCategoriesFiltered(
        search,
        kitchenTimeSlots
      )
      const products = categories?.reduce(
        (categoriesAcc: IProduct[], category: ICategory) => {
          return [...categoriesAcc, ...category.products]
        },
        []
      )
      dispatch(CategoriesSlice.actions.fetchCategories(categories))
      dispatch(ProductsSlice.actions.setProducts(products))
    } catch (e) {
      errorHandler(e)
    }
  }
