export const downloadFromUrl = (url: string, name = 'receipt.pdf') => {
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      // 3. Append to html page
      document.body.appendChild(link)
      // 4. Force download
      link.click()
      // 5. Clean up and remove the link
      link.parentNode?.removeChild(link)
    })
}
