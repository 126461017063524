import styled from 'styled-components'
import { getContrastColor } from '../../../utils/getContrastColor'

type ProfileOptionType = {
  disabled?: boolean
}

type NumbersContainerType = {
  isSaldo?: boolean
}

type NumberTextType = {
  isGreen?: boolean
}

const MainTextColors = {
  PENDING: '#4A90E2',
  IN_PREPARATION: '#4A90E2',
  PREPARED: '#4A90E2',
  TAKEN: '#34CB9E',
  NOT_PAID: '#434343',
  0: '#434343',
  1: '#34CB9E',
}

export const ProfileOption = styled('div')<ProfileOptionType>`
  border: ${({ theme }) => `1px solid ${theme.ItemsBorders}`};
  border-radius: 5px;
  height: 50px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.FontColor};

  background-color: ${({ theme, disabled }) =>
    disabled ? '#e1e1e1' : theme.ItemsBackGround};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  p {
    margin-left: 10px;
  }
`

export const Page = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  color: #434343;
  text-align: center;
  margin-top: -15px;
  color: ${({ theme }) => theme.FontColor};
`

// Edit

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  position: relative;
  div:not(:last-child) {
    margin-right: 5px;
  }
`

export const EyeImage = styled.img`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
`

export const Error = styled.p`
  color: #c60008;
  font-size: 12px;
  margin-bottom: 20px;
`

// Card

export const CardItem = styled.p`
  display: flex;
  justify-content: center;
  font-size: 16px;
  column-gap: 10px;
`

export const CardTitle = styled(CardItem)`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.FontColor};
`

export const RefillButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 25px;
`

// Orders

export const OrdersButtonsContainer = styled.div`
  margin-top: -25px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 45px;
  grid-gap: 0 25px;
  width: calc(100vw - 40px);
  position: sticky;
  position: -webkit-sticky;
  top: -21px;
  background-color: red;
  z-index: 1;
  background-color: ${({ theme }) => theme.MainBackGround};
`

export const NoOrders = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colour};
  text-align: center;
  width: 100%;
  top: 50px;
`

export const OrderItem = styled.div`
  margin-bottom: 5px;
  border: ${({ theme }) => `1px solid ${theme.ItemsBorders}`};
  background-color: ${({ theme }) => theme.ItemsBackGround};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const OrderHeader = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`

export const OrderProducts = styled.div`
  width: 100%;
`

export const OrderProduct = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 75px;
  grid-template-rows: 30px 30px;
`

export const OrderProductTitle = styled.p`
  line-height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${({ theme }) => theme.FontColor};
`

export const OrderProductTotal = styled.p`
  line-height: 16px;
  color: #c3c3c3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
`

export const OrderProductImage = styled.img`
  grid-row: 1 / -1;
  grid-column: 1 / 2;
  max-width: 100%;
  object-fit: contain;
  border-radius: 50%;
  padding: 5px;
`

export const BranchPrice = styled.div`
  height: 40px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
`

export const PriceContainer = styled.div`
  color: ${({ theme }) => theme.FontColor};

  font-size: 14px;
  white-space: nowrap;
`

export const PriceContainerItem = styled(PriceContainer)`
  width: 100%;
  height: 100%;
  padding-right: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const NumberDate = styled.div`
  width: 110px;
  height: 40px;
  margin: 0 10px;
  border-right: 1px solid #cdcdcd;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`

export const MainText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.FontColor};
`

export const NumberText = styled(MainText)<NumberTextType>`
  color: ${({ theme, isGreen }) => {
    if (isGreen) {
      return 'green'
    } else {
      return theme.FontColor
    }
  }};
`

export const SubText = styled.p`
  font-size: 11px;
  line-height: 15px;
  color: ${({ theme }) => theme.FontColor};
`

export const Chevron = styled.img`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
`

// Refill card

export const RefillCard = styled.div`
  color: ${({ theme }) => theme.FontColor};
  height: 100%;
  min-height: calc(35vh - 50px - 20px - 25px);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  & > p {
    margin: 10px 0;
    width: 100%;
    text-align: right;
  }
  & button {
    height: 50px !important;
  }
`

export const RadioContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.FontColor};
  input {
    margin-right: 10px;
  }
`

export const ResultText = styled.p`
  color: ${({ theme }) => theme.colour};
`

/////// Card layout

type CardProps = {
  isIOS?: boolean
  isBonuses?: boolean
}

export const CardContainer = styled('div')<CardProps>`
  display: grid;
  grid-template-rows: ${({ isIOS }) =>
    isIOS
      ? 'repeat(4, 55px) 40px repeat(2, 80px)'
      : 'repeat(4, 65px) repeat(1, 95px) repeat(2, 65px)'};
  /* background: ${({ theme }) => theme.colour}; */
  background: ${({ theme }) =>
    `linear-gradient(135deg, ${theme.colour}, ${theme.colour}, ${theme.colour}1A)`};
  padding: 20px;
  border-radius: 20px;
  color: ${({ theme }) => getContrastColor(theme.colour)};
`

export const CardHeader = styled('div')<CardProps>`
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ isIOS }) => (isIOS ? 'unset' : '1px solid #fff')};
`

export const CardHoldersName = styled('div')<CardProps>`
  grid-row: 2 / 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-transform: uppercase; */
  font-size: 25px;
  text-transform: ${({ isIOS }) => (isIOS ? 'uppercase' : 'unset')};
`

export const CardSaldoPointContainer = styled('div')<CardProps>`
  grid-row: ${({ isIOS }) => (isIOS ? '4 / 5' : '3 / 4')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardSaldoPointItem = styled('div')<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ isIOS, isBonuses }) =>
    !isIOS && isBonuses ? 'flex-end' : 'flex-start'};

  text-transform: ${({ isIOS }) => (isIOS ? 'uppercase' : 'unset')};
  & p:first-child {
    font-size: 9px;
  }
`

export const CardQrCodeContainer = styled('div')<CardProps>`
  grid-row: 6 / -1;
  grid-row: ${({ isIOS }) => (isIOS ? '6 / -1' : '4 / 6')};
  height: 100%;
  aspect-ratio: ${({ isIOS }) => (isIOS ? 1.5 : 1)};
  border-radius: ${({ isIOS }) => (isIOS ? '5px' : '10px')};
  overflow: hidden;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: ${({ isIOS }) => (isIOS ? 'flex-start' : 'center')};
  justify-self: center;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`
