import styled from 'styled-components'

export const SelfService_Container = styled.div`
  overflow-y: scroll;
  height: 100%;
`

// Steps

export const Steps = styled.div`
  width: 280px;
  margin: 0 auto 25px;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
`

export const Step_Item = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.colour};
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
`

export const Step_Divider = styled.div`
  width: 35px;
  height: 0;
  border-top: 1px solid #cdcdcd;
  margin: 0 15px;
`

// Instructions

export const Instructions = styled.div`
  padding: 0 20px;
`

export const Instruction_Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 0;
`

export const Point = styled.p`
  height: 24px;
  width: 24px;
  min-width: 24px;
  background-color: #565656;
  color: #fff;
  text-align: center;
  border-radius: 12px;
  margin-right: 14px;
`

export const Instruction_Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  color: ${({ theme }) => theme.FontColor};
`
