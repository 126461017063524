import { FC, useEffect, useState } from 'react'
import { Categories } from './Categories'
import { AllergensModal } from './AllergensModal'
import { useSearchParams } from 'react-router-dom'
import { CategoriesSlider } from './CategoriesSlider'
import { useOpenModal } from '../../hooks/useOpenModal'
import { useMySelector } from '../../hooks/useMySelector'
import { AddProductModal } from '../../hooks/useOpenProductModal/modals/AddProductModal'
import { FiltersSlice } from '../../store/reducers/FiltersReducer'
import { useMyDispatch } from '../../hooks/useMyDispatch'
import { Scanner } from '../../components/Scanner'
import { Header } from '../../components/Header'
import { toast } from 'react-toastify'
import { Footer } from './Footer'
import { TableSelect } from './TableSelect'
import { SpinnerOverlay } from '../../components/OverlaySpinner'

import {
  ICategory,
  IProduct,
} from '../../store/reducers/CategoriesReducer/types'
import { QrMenuStyledContainer } from './styled'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { usePromptParams } from '../Welcome/usePromptParams'
import { useTranslations } from '../../hooks/useTranslations'

type MenuProps = {
  loadMenuData: () => Promise<void>
}

export const Menu: FC<MenuProps> = ({ loadMenuData }) => {
  // Hooks
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const t = useTranslations()
  const openPromptModal = usePromptParams()
  const [searchParams] = useSearchParams()
  const payment_success_nr = searchParams.get('payment_success_nr')

  // Actions
  const { discardAllergens, discardDiet } = FiltersSlice.actions

  // Store
  const { isProductsLoaded } = useMySelector((state) => state.app)
  const { categories: categoriesFromState } = useMySelector((state) => state)
  const { allergens, diet } = useMySelector((state) => state.filters)
  const { bookingVariant, selfService } = useMySelector(
    (state) => state.app.menu
  )

  // State
  const [categories, setCategories] = useState<ICategory[]>([])
  const [isInputShowing, setIsInputShowing] = useState<boolean>(false)
  const [shouldFilterCategories, setShouldFilterCategories] =
    useState<boolean>(false)

  // useEffects
  useEffect(() => {
    promptTableId()
  }, [isProductsLoaded, bookingVariant])

  useEffect(() => {
    const { pickUpTime, registerId } = handleLocalStorage([
      'pickUpTime',
      'registerId',
    ])
    // TODO: check if pickUpTime works correctly
    const isClick = process.env.REACT_APP_IS_CLIC === '1'
    if (isClick && !pickUpTime) {
      openPromptModal(loadMenuData, ['time', 'branch'], true)
    }
    if (isClick && pickUpTime && !registerId) {
      openPromptModal(loadMenuData, ['branch'], true)
    }
    if (isClick && pickUpTime && registerId) {
      loadMenuData()
    }
  }, [payment_success_nr])

  useEffect(() => {
    if (!allergens.length && !diet.length) {
      setCategories(categoriesFromState)
    } else {
      const categoriesToSet = categoriesFromState?.map((cat) => {
        let products = [...cat.products]
        if (allergens.length) {
          products = products?.filter(
            (product1) =>
              !product1.allergens
                ?.split(', ')
                ?.some((al) => allergens?.includes(+al))
          )
        }
        if (diet.length) {
          products = products?.filter(
            (product2) =>
              !!product2.diatItems
                ?.split(',')
                ?.every((dietItem) => diet?.includes(dietItem))
          )
        }
        return { ...cat, products }
      })
      setCategories(categoriesToSet)
    }
  }, [categoriesFromState, shouldFilterCategories]) // eslint-disable-line

  // Functions
  const promptTableId = () => {
    const tableId = searchParams.get('table')
    if (
      !tableId &&
      isProductsLoaded &&
      bookingVariant === 2 &&
      !selfService &&
      process.env.REACT_APP_IS_CLIC === '0'
    ) {
      openModal({
        id: 'TABLE_SELECTION',
        title: t('order.toMenu'),
        shouldHideCross: true,
        components: [
          ({ closeThisModal }) => <TableSelect onClose={closeThisModal} />,
        ],
      })
    }
  }

  const openAllergensModal = () => {
    openModal({
      id: 'ALLERGENS_MODAL',
      components: [
        ({ closeThisModal }) => (
          <AllergensModal
            type="menu"
            allergensActions={FiltersSlice.actions}
            onClear={() => {
              dispatch(discardAllergens())
              dispatch(discardDiet())
              setShouldFilterCategories((prevValue) => !prevValue)
              closeThisModal()
            }}
            onApply={() => {
              setShouldFilterCategories((prevValue) => !prevValue)
              closeThisModal()
            }}
          />
        ),
      ],
      title: 'Filters',
    })
  }

  const openProductModal = (product: IProduct) => {
    const divElement = document.getElementById(`product_${product.id}`)
    divElement?.scrollIntoView()
    openModal({
      id: `ADD_PRODUCT_${product.id}`,
      components: [
        ({ closeThisModal }) => (
          <AddProductModal product={product} closeProduct={closeThisModal} />
        ),
      ],
      title: product.title,
    })
  }

  const openBarcodeModal = () => {
    openModal({
      id: 'BARCODE_SCANNER',
      title: 'Scan',
      components: [
        () => (
          <Scanner
            title={"t('scanner.scanProduct')"}
            subtitle={"t('scanner.hoverBarcode')"}
            onScan={handleBarcodeScan}
          />
        ),
      ],
    })
  }

  const handleBarcodeScan = async (EANCode: string) => {
    const productToOpen = findProductByEANCode(EANCode)
    if (!productToOpen) toast.error(t('scanner.barcodeError'))
    if (productToOpen) {
      openProductModal(productToOpen)
    }
  }

  const findProductByEANCode = (EANCode: string) => {
    let foundProduct = null
    let currentCategoryIndex = 0
    while (!foundProduct && currentCategoryIndex < categoriesFromState.length) {
      categoriesFromState[currentCategoryIndex].products.map((product) => {
        if (product.EANCode === EANCode) {
          foundProduct = product
        }
      })
      currentCategoryIndex++
    }
    return foundProduct
  }

  return (
    <QrMenuStyledContainer>
      <SpinnerOverlay isLoading={!isProductsLoaded} />
      {/* div below is needed for scroll to work correctly */}
      <div>
        <Header />
        <CategoriesSlider
          isInputShowing={isInputShowing}
          hideInput={() => setIsInputShowing(false)}
          reloadMenuData={loadMenuData}
          applyCategoriesFilter={() =>
            setShouldFilterCategories((prevValue) => !prevValue)
          }
        />
        <Categories
          categories={categories}
          isInputShowing={isInputShowing}
          isAllergensShowing={!!allergens?.length || !!diet?.length}
        />
        <Footer
          openAllergensModal={openAllergensModal}
          openBarcodeModal={openBarcodeModal}
          setIsInputShowing={() => setIsInputShowing((prevValue) => !prevValue)}
        />
      </div>
    </QrMenuStyledContainer>
  )
}

export default Menu
