import { useMySelector } from './useMySelector'

export const useTranslations = () => {
  const { selectedLanguage } = useMySelector((state) => state.common)
  const translations = useMySelector((state) => state.translations)

  const translateFunction = (value: string, substitutions = {}) => {
    const [partition, key] = value.split('.')
    const res = translations?.[selectedLanguage]?.[partition]?.[key]

    if (typeof res === 'function') return res(substitutions)
    if (typeof res === 'string') return res
    return ''
  }

  return translateFunction
}
