import { ChangeEvent, FC, RefObject, useEffect, useState } from 'react'
import searchImage from '../../assets/search.svg'
import { RenderIf } from '../RenderIf'
import { InputContainer, InputStyled, SearchButton } from './styled'

type InputProps = {
  onSearch?: (e: string) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  innerRef?: RefObject<HTMLInputElement>
  [key: string]: any
}

export const Input: FC<InputProps> = ({
  onSearch,
  onChange,
  innerRef,
  defaultValue,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState('')
  // const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setCurrentValue(defaultValue)
  }, [defaultValue])

  const handleSearch = () => {
    if (onSearch) {
      onSearch(currentValue)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.currentTarget.value)
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <InputContainer>
      <InputStyled
        ref={innerRef}
        withButton={!!onSearch}
        onChange={handleChange}
        value={currentValue || ''}
        {...props}
      />
      <RenderIf condition={!!onSearch}>
        <SearchButton onClick={handleSearch}>
          <img alt="search" src={searchImage} />
        </SearchButton>
      </RenderIf>
    </InputContainer>
  )
}
