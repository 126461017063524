import { FC } from 'react'

import { PhoneNumberInput } from '../../../../components/Input/PhoneNumberInput'
import { RenderIf } from '../../../../components/RenderIf'
import { Button } from '../../../../components/Button'

import { Error, InputContainer, StyledText } from '../styled'

type RequestProps = {
  phoneValue: string
  changePhone: (newValue: string) => void
  requestReset: () => void
  isLoading: boolean
  errors: string[]
}

export const Request: FC<RequestProps> = ({
  phoneValue,
  changePhone,
  requestReset,
  isLoading,
  errors,
}) => {
  return (
    <>
      <StyledText>Enter your phone number</StyledText>
      <InputContainer>
        <PhoneNumberInput value={phoneValue} onChange={changePhone} />
      </InputContainer>
      <RenderIf condition={!!errors.length}>
        {errors.map((err) => (
          <Error key={err}>{err}</Error>
        ))}
      </RenderIf>
      <Button
        buttonType="common"
        width="100%"
        onClick={requestReset}
        isLoading={isLoading}
        disabled={phoneValue?.length < 9}
      >
        Send confirmation code
      </Button>
    </>
  )
}
