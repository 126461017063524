export const amplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENTID,
  },
  Analytics: {
    disabled: true,
  },
}
