import styled from 'styled-components'

export const QrMenuStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.MainBackGround};
  position: relative;
`
