import { FC, useState } from 'react'
import { useTranslations } from '../../../hooks/useTranslations'
import { Button } from '../../../components/Button'
import { MWErrorContainer } from './styled'
import { ButtonContainer } from '../../../components/Button/styled'
import { useOpenModal } from '../../../hooks/useOpenModal'
import { NotificationsModal } from '../../MainScreen/NotificationsModal'

type MWErrorProps = {
  closeModal: () => void
  orderAgain: () => void
}

export const MWError: FC<MWErrorProps> = ({ closeModal, orderAgain }) => {
  const t = useTranslations()
  const openModal = useOpenModal()

  const openNotificationsModal = () => {
    openModal({
      id: 'CALL_WAITER_MODAL',
      title: t('common.notifications'),
      components: [
        ({ closeThisModal }) => (
          <NotificationsModal
            onDeclineClose={closeThisModal}
            onSuccessClose={() => {
              closeThisModal()
              closeModal()
            }}
          />
        ),
      ],
    })
  }

  return (
    <MWErrorContainer>
      <h2>{t('errors.smthWentWrong')}</h2>
      <p>{t('errors.callWaiter')}</p>
      <ButtonContainer>
        <Button
          buttonType="stroke"
          onClick={openNotificationsModal}
          width="100%"
        >
          {t('buttons.callWaiterButton')}
        </Button>
        <Button buttonType="common" onClick={orderAgain} width="100%">
          {t('buttons.tryAgain')}
        </Button>
      </ButtonContainer>
    </MWErrorContainer>
  )
}
