import { FC, useEffect, useRef } from 'react'
import { InputContainer, InputStyled } from './styled'
import searchImg from '../../assets/search.svg'
import { useMySelector } from '../../hooks/useMySelector'
import { useTranslations } from '../../hooks/useTranslations'

type InputItemType = {
  inputValue: string
  setInputValue: (value: string) => void
  onSearchClick: () => void
}

export const Search: FC<InputItemType> = ({
  inputValue,
  setInputValue,
  onSearchClick,
}) => {
  const { colour } = useMySelector((state) => state.app.interfaceStyles)
  const inputRef = useRef<HTMLInputElement>(null)
  const t = useTranslations()

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
      inputRef.current?.select()
    }, 0)
  }, [])

  return (
    <InputContainer>
      <InputStyled
        ref={inputRef}
        value={inputValue}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        placeholder={t('footer.search')}
      />
      <img
        src={searchImg}
        alt={t('footer.search')}
        style={{ background: colour }}
        onClick={onSearchClick}
      />
    </InputContainer>
  )
}
