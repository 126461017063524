import styled from 'styled-components'

export const ReceiptModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 85px 58px;
  grid-gap: 25px;
  height: 100%;
  margin-top: 12px;
  h2 {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    padding-bottom: 9px;
    margin: 0;
  }
  p {
    font-size: 11px !important;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #434343;
  }
  &::after {
    content: unset;
  }
`

export const ReceiptNumberContainer = styled.div`
  margin-left: 20px;
  background-color: #cdcdcd;
  color: #fff;
  height: 85px;
  width: 85px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    padding: 0;
    color: #fff;
    font-size: 35px !important;
    line-height: 40px;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 9px !important;
    line-height: 13px;
  }
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    color: ${({ theme }) => theme.FontColor};
  }
  p {
    color: ${({ theme }) => theme.FontColor};
  }
`

export const ButtonContainer = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5vw;
`

//

export const ReceiptInput = styled.input`
  border: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colour};

  outline: none;
  height: 18vw;
  width: 100%;
  text-align: center;
  font-size: 8vw;
  color: #3c3c32;
  background-color: ${({ theme }) => theme.MainBackGround};
`

// No-Receipt styles

export const MessageContainerNoReceipt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${({ theme }) => theme.FontColor};
    font-size: 20px;
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
  }
`
