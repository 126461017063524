export const getContrastColor = (hexColorParam = 'ffffff') => {
  let hexColor = hexColorParam ? hexColorParam.replace(/^#/, '') : 'ffffff'

  if (hexColor.length === 3) {
    hexColor = hexColor
      .split('')
      .map((char) => char + char)
      .join('')
  }

  const r = parseInt(hexColor.slice(0, 2), 16)
  const g = parseInt(hexColor.slice(2, 4), 16)
  const b = parseInt(hexColor.slice(4, 6), 16)

  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 190 ? '#000000' : '#FFFFFF'
}
