import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, UpdateCloudOrdersArrayType } from './types'

export const CloudOrdersSlice = createSlice({
  name: 'wishlistSlice',
  initialState,
  reducers: {
    updateCloudOrdersArray(
      state,
      action: PayloadAction<UpdateCloudOrdersArrayType>
    ) {
      return (state = {
        ...state,
        isColudOrderExist: Boolean(action.payload.cloudOrdersList?.length),
        isColudOrderChecked: true,
        restaurantId: action.payload.restaurantId,
        cloudOrdersList: action.payload.cloudOrdersList,
      })
    },
  },
})

export default CloudOrdersSlice.reducer
