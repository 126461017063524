import { FC } from 'react'
import { useTranslations } from '../../../hooks/useTranslations'
import { Button } from '../../../components/Button'
import cloche from '../../../assets/cloche.svg'
import { OrderModalContainer } from './styled'

type SuccessProps = {
  closeModal: () => void
}

export const Success: FC<SuccessProps> = ({ closeModal }) => {
  const t = useTranslations()
  return (
    <OrderModalContainer>
      <img alt="OK" src={cloche} />
      <div>
        <h2>{t('order.thankYouMessage')}</h2>
        <p>{t('order.thankYouSubMessage')}</p>
      </div>
      <Button
        style={{ gridColumn: '1 / -1' }}
        width="100%"
        buttonType="common"
        onClick={closeModal}
      >
        {t('buttons.ok')}
      </Button>
    </OrderModalContainer>
  )
}
