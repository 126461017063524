import { handleLocalStorage } from './handleLocalStorage'

const myDynamicManifest = (guid, registerId, companyName, imageUrl) => ({
  short_name: companyName,
  name: companyName,
  icons: [
    {
      src: imageUrl,
      type: 'image/png',
      sizes: '192x192',
    },
    {
      src: imageUrl,
      type: 'image/png',
      sizes: '512x512',
      purpose: 'any',
    },
  ],
  start_url: `${process.env.REACT_APP_URL}?guid=${guid}${
    registerId ? '&register=' + registerId : ''
  }`,
  display: 'standalone',
  theme_color: '#000000',
  background_color: '#ffffff',
  //
  description:
    'Click & Collect - application that allows you to order some products',
  dir: 'ltr',
  // display_override: ['fullscreen', 'minimal-ui'],
  orientation: 'portrait',
  scope: '/',
  iarc_rating_id: 'e0b07840-fc96-43df-8347-19511689efee',
  categories: ['food', 'shopping'],
  shortcuts: [],
  lang: 'de',
  screenshots: [
    {
      src: './static/media/home.png',
      sizes: '966x2266',
      type: 'image/png',
      description: 'Home page',
    },
    {
      src: './static/media/product_list.png',
      sizes: '946x2262',
      type: 'image/png',
      description: 'Product list',
    },
    {
      src: './static/media/product_view.png',
      sizes: '966x2266',
      type: 'image/png',
      description: 'Product review',
    },
  ],
})

const getManifestURL = (companyName, imageUrl) => {
  const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
  const stringManifest = JSON.stringify(
    myDynamicManifest(guid, registerId, companyName, imageUrl)
  )
  const blob = new Blob([stringManifest], { type: 'application/json' })
  return URL.createObjectURL(blob)
}

export const registerManifest = async (companyName, imageUrl) => {
  // set manifest
  document
    ?.querySelector('#application_manifest--placeholder')
    ?.setAttribute('href', getManifestURL(companyName, imageUrl))

  // set image for apple devices
  document
    ?.querySelector('#application_apple-touch-icon--placeholder')
    ?.setAttribute('href', imageUrl)

  // set image for browser tab
  document
    ?.querySelector('#application_favicon--placeholder')
    ?.setAttribute('href', imageUrl)

  document.title = companyName
}
