// Allergens:
import cancer from '../assets/allergens/cancer.svg'
import celery from '../assets/allergens/celery.svg'
import common from '../assets/allergens/common.svg'
import eggs from '../assets/allergens/eggs.svg'
import fish from '../assets/allergens/fish.svg'
import lupins from '../assets/allergens/lupins.svg'
import milk from '../assets/allergens/milk.svg'
import mollusks from '../assets/allergens/mollusks.svg'
import mustard from '../assets/allergens/mustard.svg'
import peanuts from '../assets/allergens/peanuts.svg'
import sesameSeeds from '../assets/allergens/sesameSeeds.svg'
import so2 from '../assets/allergens/so2.svg'
import soy from '../assets/allergens/soy.svg'
import spelt from '../assets/allergens/spelt.svg'
//
// Diet:
import vegan from '../assets/diet/vegan.svg'
import vegetarian from '../assets/diet/vegetarian.svg'
import bio from '../assets/diet/bio.svg'
import lactose from '../assets/diet/lactose.svg'
import alcohol from '../assets/diet/alcohol.svg'
import dinkel from '../assets/diet/dinkel.svg'
import gluten from '../assets/diet/gluten.svg'

export const AllergenImages: any = {
  0: common,

  21: gluten,
  22: cancer,
  23: eggs,
  24: fish,
  25: peanuts,
  26: soy,
  27: milk,
  28: common,

  29: celery,
  30: mustard,
  31: sesameSeeds,
  32: so2,
  33: lupins,
  34: mollusks,

  35: common,
  36: common,
  37: common,
  38: common,
  39: spelt,
  40: common,

  41: common,
  42: common,
  43: common,
  44: common,
  45: common,
  46: common,
  47: common,
  48: common,

  isVegan: vegan,
  isVegetarian: vegetarian,
  isBio: bio,
  isLactoseFree: lactose,
  isAlcohol: alcohol,
  isDinkel: dinkel,
  isNoGluten: gluten,
  isContainGluten: gluten,
}
