import { FC, useEffect, useState } from 'react'
import { CompanyItem } from './CompanyItem'
import { Spinner } from '../../../components/Spinner'
import { RenderIf } from '../../../components/RenderIf'
import { errorHandler } from '../../../components/ErrorHandler'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'
import { getRestaurants } from '../../../store/reducers/RestaurantsReducer/actionCreator'
import { useTranslations } from '../../../hooks/useTranslations'
import { useMyDispatch } from '../../../hooks/useMyDispatch'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import { Button } from '../../../components/Button'
import { CompanyData } from './types'

import { RestaurantsContainer } from '../styledComponents'
import { BranchTitle } from './styled'

type GUIDPickerProps = {
  increaseStep: () => void
}

export const GUIDPicker: FC<GUIDPickerProps> = ({ increaseStep }) => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [companies, setCompanies] = useState<CompanyData[]>([])
  const [selectedGUID, setSelectedGUID] = useState<string>('')

  // useEffects
  useEffect(() => {
    loadRestaurants()
  }, [])

  // Functions
  const loadRestaurants = async () => {
    try {
      const companiesResult: CompanyData[] = await qrMenuGET.companies()
      // Cutting first 50 companies not to overload the screen.
      // Create paggination or infinite scroll if needed.
      setCompanies(companiesResult?.slice(0, 50))
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelect = async () => {
    if (selectedGUID) {
      setIsLoading(true)
      const guidOLD = handleLocalStorage('guid')
      if (guidOLD !== selectedGUID) {
        handleLocalStorage({ wishlist: [] })
      }
      await handleLocalStorage({ guid: selectedGUID })
      dispatch(getRestaurants({}))
      increaseStep()
      setIsLoading(false)
    }
  }

  return (
    <div>
      <RenderIf condition={isLoading}>
        <Spinner type="alternate" />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <BranchTitle>{t('common.selectRestaurant')}</BranchTitle>
        <RestaurantsContainer>
          {companies.map((company) => (
            <CompanyItem
              key={`company_${company.CompanyID}`}
              company={company}
              selectedGUID={selectedGUID}
              setSelectedGUID={setSelectedGUID}
            />
          ))}
        </RestaurantsContainer>
        <Button
          width="100%"
          buttonType="common"
          onClick={handleSelect}
          disabled={!selectedGUID.length}
        >
          {t('buttons.select')}
        </Button>
      </RenderIf>
    </div>
  )
}

export default GUIDPicker
