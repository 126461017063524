import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISpecialPrices, initialState } from './types'

export const SpecialsSlice = createSlice({
  name: 'specialsSlice',
  initialState,
  reducers: {
    setSpecials(state, action: PayloadAction<ISpecialPrices>) {
      return (state = action.payload)
    },
  },
})

export default SpecialsSlice.reducer
