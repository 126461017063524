import { FC } from 'react'
import {
  RestaurantsCheckBox,
  RestaurantsItem,
  RestaurantsTitle,
} from '../styledComponents'
import { CompanyData } from './types'
import { formatGuid } from '../../../utils/formatGuid'

type CompanyItemProps = {
  company: CompanyData
  selectedGUID: string
  setSelectedGUID: (value: string) => void
}

export const CompanyItem: FC<CompanyItemProps> = ({
  company,
  selectedGUID,
  setSelectedGUID,
}) => {
  return (
    <RestaurantsItem
      isCompany
      onClick={() => setSelectedGUID(formatGuid(company.CompanyID))}
    >
      <RestaurantsTitle>{company.ContractorFirma}</RestaurantsTitle>
      <RestaurantsCheckBox
        isSelected={
          company.CompanyID?.toLocaleLowerCase() ===
          selectedGUID?.toLocaleLowerCase()
        }
      />
    </RestaurantsItem>
  )
}
