import { FC, useEffect, useState } from 'react'

import { Button } from '../../../../components/Button'
import { useMyDispatch } from '../../../useMyDispatch'
import { useMySelector } from '../../../useMySelector'
import { useTranslations } from '../../../useTranslations'
import { RenderIf } from '../../../../components/RenderIf'
import { ProgressBar } from '../../../../components/ProgressBar'
import { SelectedProductsSlice } from '../../../../store/reducers/SelectedProductsReducer'
import {
  IProduct,
  IMenuItem,
} from '../../../../store/reducers/CategoriesReducer/types'

import chevronDown from '../../../../assets/chevronDown.svg'
import {
  ItemsContainer,
  ItemsGrid,
  ItemsGridExpander,
  MainTitle,
  Item,
  ItemImageContainer,
  ItemImage,
  ItemPrice,
} from './styled'

type AddMenuItemProps = {
  product: IProduct
  closeModal: () => void
  menuItems: IMenuItem[]
}

export const AddMenuItem: FC<AddMenuItemProps> = ({
  product,
  closeModal,
  menuItems,
  ...seletedKitchenInfo
}) => {
  // Hooks
  const dispatch = useMyDispatch()
  const t = useTranslations()

  // Actions
  const { addSelectedProduct } = SelectedProductsSlice.actions

  // Store
  const { Currency } = useMySelector((state) => state.app.companyData)

  // State
  const [currentSelected, setCurrentSelected] = useState(0)
  const [selectedMenuItems, setSelectedMenuItems] = useState<any[]>([])
  const [isItemsCollapsed, setIsItemsCollapsed] = useState<boolean>(false)
  const [isCollapsedTouched, setIsCollapsedTouched] = useState<boolean>(false)

  // useEffects
  useEffect(() => {
    if (!isCollapsedTouched && menuItems[currentSelected]?.items?.length > 6) {
      setIsItemsCollapsed(true)
    }
  }, [menuItems, currentSelected])

  useEffect(() => {
    const isMaxReached =
      selectedMenuItems.filter(
        (el) => el.categoryId_origin === menuItems[currentSelected].categoryId
      )?.length >= menuItems[currentSelected].maxCount
    if (isMaxReached) {
      orderOrNext()
    }
  }, [selectedMenuItems])

  // Functions
  const handleClick = (itemToAdd: any) => {
    setSelectedMenuItems((prevValue) => [
      ...prevValue,
      {
        ...itemToAdd,
        count: 1,
        categoryId_origin: menuItems[currentSelected].categoryId,
      },
    ])
  }

  const orderOrNext = () => {
    if (currentSelected + 1 < menuItems.length) {
      setCurrentSelected((prevValue) => prevValue + 1)
    }
    if (currentSelected + 1 === menuItems.length) {
      orderProduct()
    }
  }

  const orderProduct = () => {
    dispatch(
      addSelectedProduct({
        id: product.id,
        uniqueId: `${product.id}_${JSON.stringify(
          selectedMenuItems
        )}_${Date.now()}`,
        addedAsWeight: product.unitSellCode === 2 || product.unitSellCode === 3,
        selectedKitchenInfo: seletedKitchenInfo
          ? JSON.stringify(seletedKitchenInfo)
          : '{}',
        amount: 1,
        menuItems: selectedMenuItems,
      })
    )
    closeModal()
  }

  const getSlicedArray = (arrayToSlice: any[]) => {
    if (!isCollapsedTouched && isItemsCollapsed) {
      return arrayToSlice.slice(0, 6)
    }
    return arrayToSlice
  }

  return (
    <div>
      <ProgressBar
        progress={((currentSelected + 1) / menuItems.length) * 100}
      />
      <MainTitle>
        {t('products.buildPerfect', {
          productName: product.title,
          minCount: menuItems[currentSelected].minCount,
          maxCount: menuItems[currentSelected].maxCount,
        })}
      </MainTitle>
      <ItemsContainer>
        <ItemsGrid>
          {getSlicedArray(menuItems[currentSelected].items).map((item: any) => (
            <Item
              key={`menu-item_${item.productId}`}
              onClick={() => handleClick(item)}
              isSelected={selectedMenuItems.find(
                (selectedItem) => selectedItem.productId === item.productId
              )}
              count={
                selectedMenuItems.filter(
                  (selectedItem) => selectedItem.productId === item.productId
                )?.length
              }
            >
              <ItemImageContainer>
                <ItemImage src={item.imageUrl} />
              </ItemImageContainer>
              <ItemPrice>{item.name}</ItemPrice>
              <ItemPrice>{`${item.price} ${Currency || ''}`}</ItemPrice>
            </Item>
          ))}
        </ItemsGrid>
        <RenderIf condition={isItemsCollapsed}>
          <ItemsGridExpander
            onClick={() => {
              setIsItemsCollapsed(false)
              setIsCollapsedTouched(true)
            }}
          >
            <img alt="Show all" src={chevronDown} />
          </ItemsGridExpander>
        </RenderIf>
      </ItemsContainer>
      <Button
        width="100%"
        buttonType="common"
        onClick={orderOrNext}
        disabled={
          selectedMenuItems.filter(
            (el) =>
              el.categoryId_origin === menuItems[currentSelected].categoryId
          )?.length < menuItems[currentSelected].minCount
        }
      >
        {currentSelected + 1 === menuItems.length
          ? t('buttons.accept')
          : t('common.next')}
      </Button>
    </div>
  )
}

export default AddMenuItem
