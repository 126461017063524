import styled from 'styled-components'

type MainPromptContainerProps = {
  isClick: boolean
}

export const MainPromptContainer = styled('div')<MainPromptContainerProps>`
  height: 100vh;
  width: 100vw;
  background-color: ${({ isClick }) => (isClick ? '#fff' : '#434343')};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50vh 50vh;
  justify-items: center;
`

export const Logo = styled.img`
  height: auto;
  width: 50vw;
  padding-bottom: 100px;
  align-self: end;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
`

export const PoweredBy = styled.img`
  width: 60px;
  padding-bottom: 70px;
`

export const SelectTime = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.FontColor};
  margin-bottom: 5px;
`
