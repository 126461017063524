import { isDisabled } from '@testing-library/user-event/dist/utils'
import styled from 'styled-components'

type CheckBox_BoxProps = {
  isChecked: boolean
  isDisabled: boolean
  checkedColor?: string
}

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CheckBox_Box = styled('div')<CheckBox_BoxProps>`
  width: 28px;
  min-width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 2px;

  //

  border: ${({ isChecked }) => (isChecked ? 'unset' : '1px solid #b0b0b0')};
  background: ${({ isChecked }) =>
    isChecked ? "url('/static/media/checkMark.svg') no-repeat" : 'unset'};
  background-position: center;
  background-color: ${({ isChecked, isDisabled, checkedColor, theme }) => {
    if (isDisabled) {
      return '#d0d0d0'
    }
    if (isChecked && !isDisabled) {
      return checkedColor || theme.colour
    }
    return '#fff'
  }};
`
export const CheckBox_Label = styled.div`
  margin-left: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #434343;
  color: ${({ theme }) => theme.FontColor};
`
