import { FC } from 'react'
import LoadingBar from 'react-redux-loading-bar'
import { RenderIf } from '../RenderIf'
import { Spinner } from '../Spinner'

import { LoadingBarContainer, OverlayContainer } from './styled'

type SpinnerOverlayProps = {
  isLoading: boolean
}

export const SpinnerOverlay: FC<SpinnerOverlayProps> = ({ isLoading }) => {
  const getBackgroundColor = () => {
    if (process.env.REACT_APP_IS_CLIC === '0') return '#FF8700'
    if (process.env.REACT_APP_IS_CLIC === '1') return '#C80008'
    return 'red'
  }

  return (
    <RenderIf condition={isLoading}>
      <OverlayContainer>
        <LoadingBarContainer>
          <LoadingBar
            style={{ backgroundColor: getBackgroundColor(), height: '5px' }}
          />
        </LoadingBarContainer>
        <Spinner />
      </OverlayContainer>
    </RenderIf>
  )
}
