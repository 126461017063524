import * as Sentry from '@sentry/react'

export const initSentry = () => {
  const replay = new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
    networkDetailAllowUrls: [
      'https://gr174iz9r6.execute-api.eu-west-1.amazonaws.com/',
    ],
    networkRequestHeaders: ['X-Custom-Header'],
    networkResponseHeaders: ['X-Custom-Header'],
  })

  Sentry.init({
    dsn: 'https://a0f810d4e8da4d4faf6c4fc99b63786d@o4504242163613696.ingest.sentry.io/4504809414459392',
    tracesSampleRate: process.env.REACT_APP_IS_PROD === '1' ? 0.05 : 0,
    replaysSessionSampleRate: process.env.REACT_APP_IS_PROD === '1' ? 0.05 : 0,
    replaysOnErrorSampleRate: process.env.REACT_APP_IS_PROD === '1' ? 1 : 0,
    integrations: [new Sentry.BrowserTracing(), replay],
  })
}
