import { CommonSlice } from '../store/reducers/CommonReducer'
import { useMyDispatch } from './useMyDispatch'

export const useHandleOffset = () => {
  const dispatch = useMyDispatch()
  const { setIsTopScrolled } = CommonSlice.actions

  let prevOffset = 0
  let prevDirection = true

  const handleOffset = (e: any) => {
    const newOffset = e.currentTarget?.scrollY
    if (newOffset > prevOffset && newOffset > 50 && prevDirection) {
      prevDirection = false
      dispatch(setIsTopScrolled(false))
    }
    if (newOffset < prevOffset && !prevDirection) {
      prevDirection = true
      dispatch(setIsTopScrolled(true))
    }
    prevOffset = newOffset
  }

  return handleOffset
}
