export const initialState: ISpecialPrices = {
  discounts: [],
  individualPrices: [],
}

export interface ISpecialPrices {
  discounts: IDiscount[]
  individualPrices: IIndivPrice[]
}

export interface IDiscount {
  discountPercentage: number
  productId: number
  description: string
}

export interface IIndivPrice {
  description: string
  price: number
  price2: number
  productId: number
}

export interface IFetchSpecialsParams {
  guid: string
  registerId: string | number
}
