import { FC, useState } from 'react'
import { Auth } from '@aws-amplify/auth'

import { Request } from './Request'
import { Confirm } from './Confirm'

import { Button } from '../../../../components/Button'
import { Spinner } from '../../../../components/Spinner'
import { RenderIf } from '../../../../components/RenderIf'
import { useTranslations } from '../../../../hooks/useTranslations'

import { StyledText } from '../styled'

const TOO_SHORT_ERROR = 'Phone number is too short.'
const REQUEST_ERROR = 'Please check your phone number or try to sign up.'

type SignUpProps = {
  moveToLogIn: () => void
}

export const ResetPassword: FC<SignUpProps> = ({ moveToLogIn }) => {
  const t = useTranslations()

  // State
  const [phone, setPhone] = useState('')
  const [stage, setStage] = useState<'request' | 'confirm'>('request')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string[]>([])

  // Functions
  const handlePhoneNumberChange = (value: string) => {
    if (!value.length) {
      setPhone('+')
    } else {
      setPhone(value)
    }
  }

  const requestReset = async () => {
    const hasError = checkError()
    if (!hasError) {
      try {
        setIsLoading(true)
        const success = await Auth.forgotPassword(phone)
        if (success) {
          setStage('confirm')
        }
      } catch {
        setError((prevValue) => [...prevValue, REQUEST_ERROR])
      } finally {
        setIsLoading(false)
      }
    }
  }

  const checkError = () => {
    setError((prevValue) => prevValue.filter((el) => el !== TOO_SHORT_ERROR))
    if (phone?.length < 9) {
      setError((prevValue) => [...prevValue, TOO_SHORT_ERROR])
    }
    return phone?.length < 9
  }

  return (
    <div style={{ position: 'relative' }}>
      <StyledText isHeader>Password reset</StyledText>
      <RenderIf condition={stage === 'request'}>
        <Request
          phoneValue={phone}
          changePhone={handlePhoneNumberChange}
          requestReset={requestReset}
          isLoading={isLoading}
          errors={error}
        />
      </RenderIf>
      <RenderIf condition={stage === 'confirm'}>
        <Confirm phone={phone} moveToLogin={moveToLogIn} />
      </RenderIf>

      <br />
      <Button
        buttonType="stroke"
        width="100%"
        disabled={isLoading}
        onClick={moveToLogIn}
      >
        Back to {t('auth.logIn')}
      </Button>
    </div>
  )
}
