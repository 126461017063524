import { FC } from 'react'
import { RenderIf } from '../../components/RenderIf'

import { ReactComponent as NavigatorIcon } from '../../assets/cover/navigator.svg'
import { ReactComponent as ChevronRight } from '../../assets/chevronRight.svg'

import { IconStroked } from '../../components/IconsContainers/styled'
import {
  SidebarItemContainer,
  SidebarItem,
  SidebarItemTextContainer,
  SidebarTitle,
  Navigator,
} from './styled'
import { withNavLinkHOC } from './withNavLinkHOC'

export type InfoItemProps = {
  title: string
  data?: any[]
  components?: any
  onClick?: () => void
  hideCover?: () => void
  link?: string
  Icon: any
}

export const InfoItem: FC<InfoItemProps> = ({
  title,
  Icon,
  link,
  data,
  components,
  onClick,
}) => {
  return (
    <SidebarItemContainer onClick={onClick}>
      <SidebarTitle>{title}</SidebarTitle>
      <IconStroked>
        <Icon height="50" width="50" />
      </IconStroked>
      <SidebarItemTextContainer>
        {components}
        {data?.map((el, i) => (
          <SidebarItem key={`${el}${i}`}>{el}</SidebarItem>
        ))}
        <RenderIf condition={!!link}>
          {/* <NavLink to={getNavLink(link as string)} onClick={hideCover}> */}
          <Navigator>
            <IconStroked>
              <NavigatorIcon height="22" width="12" />
            </IconStroked>
          </Navigator>
          {/* </NavLink> */}
        </RenderIf>
        <RenderIf condition={!!onClick}>
          <Navigator>
            <IconStroked>
              <ChevronRight height="24" width="22" />
            </IconStroked>
          </Navigator>
        </RenderIf>
      </SidebarItemTextContainer>
    </SidebarItemContainer>
  )
}

export default withNavLinkHOC(InfoItem)
