import { Auth } from '@aws-amplify/auth'
import { load as WebFontLoader } from 'webfontloader'
import { registerManifest } from '../../../utils/registerManifest'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import { setFont } from '../../../utils/setFont'
import { AppDispatch } from '../../index'
import { AppSlice } from '.'

import {
  darkTheme,
  IAppResponse,
  ICompanyData,
  IInterface,
  initialState,
  lightTheme,
} from './types'
import { downloadCustomFont } from '../../../utils/downloadCustomFont'

export const getAppData = () => async (dispatch: AppDispatch) => {
  const setupRequest = new Promise<IAppResponse>((resolve, reject) => {
    ;(async () => {
      try {
        const setup: IAppResponse = await qrMenuGET.setup()
        resolve(setup)
      } catch (err) {
        reject(err)
      }
    })()
  })
  const companyDataRequest = new Promise<ICompanyData>((resolve, reject) => {
    ;(async () => {
      try {
        const companyData: ICompanyData = await qrMenuGET.companyData()
        resolve(companyData)
      } catch (err) {
        reject(err)
      }
    })()
  })
  const loadCustomFont = new Promise<null>((resolve) => {
    ;(async () => {
      try {
        downloadCustomFont()
        resolve(null)
      } catch (err) {
        console.log('Font loading error:', err)
      }
    })()
  })
  Promise.all([setupRequest, companyDataRequest, loadCustomFont]).then(
    ([setup, companyData]: [IAppResponse, ICompanyData, null]) => {
      const appName =
        process.env.REACT_APP_IS_CLIC === '0' ? 'QRMENU' : 'CLICKCOLLECT'
      if (setup?.interfaceConfig?.[appName]?.font) {
        WebFontLoader({
          google: {
            families: [setup.interfaceConfig[appName].font],
          },
        })
        setFont(setup.interfaceConfig[appName].font)
      }
      registerManifest(
        companyData?.CompanyName || companyData?.ContractorName || '',
        setup?.interfaceConfig?.[appName]?.appIcon ||
          setup?.interfaceConfig?.[appName]?.logoImage
      )
      if (!setup?.menuConfig?.[appName]?.loginAvailable) {
        Auth.signOut()
        window.localStorage.removeItem('userName')
      }

      dispatch(
        AppSlice.actions.fetchAppData({
          isAppInit: true,
          companyData,
          address: setup?.address,
          clickCollectConfig: setup?.clickCollectConfig,
          // TODO: styles for booking must be taken from tableReservationConfig
          tableReservationConfig: setup?.tableReservationConfig,
          menu: setup?.menuConfig?.[appName],
          interfaceStyles: getInterfaceStyles({
            ...initialState.interfaceStyles,
            ...setup?.interfaceConfig?.[appName],
          }),
        })
      )
    }
  )
}

const getInterfaceStyles = (styles: Partial<IInterface>) => {
  switch (styles?.appTheme) {
    case 'dark':
      return {
        ...styles,
        ...darkTheme,
      }
    case 'light':
      return {
        ...styles,
        ...lightTheme,
      }
    default:
      return {
        ...styles,
        ...lightTheme,
      }
  }
}
