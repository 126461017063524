import { FC, MouseEvent } from 'react'
import { ProductToPay } from '../../../sections/Payment/types'
import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { ProductListed } from '../ProductListed/ProductListed'
import { ProductsList } from '../styled'

type SplitProductsProps = {
  products: IProduct[]
  onProductClick: (product: IProduct, e: MouseEvent<HTMLElement>) => void
  //
  getIsSelected: (product: IProduct) => boolean
  altProductsCounts: ProductToPay[]
}

export const SplitProducts: FC<SplitProductsProps> = ({
  products,
  onProductClick,
  getIsSelected,
  altProductsCounts,
}) => {
  const getAltCount = (product: IProduct) =>
    altProductsCounts?.find((pTp) => pTp.itemId === product.itemId)?.count

  return (
    <ProductsList>
      {products?.map((product, i) => {
        // Variables
        const includedItems = [
          ...(product?.menuItems ? product.menuItems : []),
        ].reduce((acc: any[], curr: any) => {
          const foundObject = acc.find(
            (el: any) => el.productId === curr.productId
          )
          if (foundObject) {
            return [
              ...acc.filter((el: any) => el.productId !== curr.productId),
              {
                ...curr,
                price: foundObject.price + curr.price,
                amount: foundObject.amount + 1,
              },
            ]
          } else {
            return [...acc, { ...curr, amount: 1 }]
          }
        }, [])

        let selectedKitchenInfo: string[] = []
        if (typeof product?.kitchenInfo === 'string') {
          selectedKitchenInfo = Object.values(JSON.parse(product?.kitchenInfo))
        }

        return (
          <ProductListed
            key={`${product?.id}_${i}`}
            product={product}
            onProductClick={onProductClick}
            //
            altCount={getAltCount(product)}
            isSelected={getIsSelected?.(product)}
            includedItems={includedItems}
            selectedKitchenInfo={selectedKitchenInfo}
          />
        )
      })}
    </ProductsList>
  )
}
