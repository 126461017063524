// import { Auth } from 'aws-amplify'
import { Auth } from '@aws-amplify/auth'
import { isAuth } from './isAuth'

type UserData = {
  firstName: string
  lastName: string
  phoneNumber: string
  sub?: string
  attributes: any
}

export const getUserData = async (): Promise<UserData> => {
  const isUserAuth = await isAuth()
  if (isUserAuth) {
    const { attributes } = await Auth.currentUserInfo()
    return {
      ...attributes,
      lastName: attributes.family_name,
      firstName: attributes.name,
      phoneNumber: attributes.phone_number,
    }
  } else {
    return {
      lastName: '',
      firstName: '',
      phoneNumber: '',
      attributes: null,
    }
  }
}
