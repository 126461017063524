import { FC } from 'react'
import { RenderIf } from '../../components/RenderIf'
import { NavLink, useSearchParams } from 'react-router-dom'
import { InfoItemProps } from './InfoItem'

export const withNavLinkHOC =
  (Component: FC<InfoItemProps>): FC<InfoItemProps> =>
  ({ link, hideCover, ...rest }) => {
    const [searchParams] = useSearchParams()

    const getNavLink = (path = 'menu') => {
      const tableId = searchParams.get('table')
      if (tableId) {
        return `/${path}?table=${tableId}`
      } else {
        return `/${path}`
      }
    }

    return (
      <>
        <RenderIf condition={!!link}>
          <NavLink to={getNavLink(link as string)} onClick={hideCover}>
            <Component link={link} {...rest} />
          </NavLink>
        </RenderIf>
        <RenderIf condition={!link}>
          <Component {...rest} />
        </RenderIf>
      </>
    )
  }
