import { MouseEvent, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button } from '../../components/Button'
import { Header } from '../../components/Header'
import { Products } from '../../components/Products'
import { RenderIf } from '../../components/RenderIf'
import { ContextMenu } from '../../components/ContextMenu'
import { NotFoundMessage } from '../../components/commonStyles'

import { IProduct } from '../../store/reducers/CategoriesReducer/types'
import { useOpenProductModal } from '../../hooks/useOpenProductModal'
import { useTranslations } from '../../hooks/useTranslations'
import { useMySelector } from '../../hooks/useMySelector'
import { useWishlist } from '../../hooks/useWishlist'
import history from '../../utils/history'

import basket from '../../assets/basket_small.svg'
import trashCan from '../../assets/trashCan.svg'

import {
  WishlistContainer,
  WishlistCaption,
  MenuButtonContainer,
} from './styled'

export type IPosition = {
  x: number
  y: number
}

export const Wishlist = () => {
  // Hooks
  const t = useTranslations()
  const handleWishlist = useWishlist()
  const handleAddClick = useOpenProductModal()

  const [searchParams] = useSearchParams()
  const tableId = searchParams.get('table')

  // Store
  const { wishlist = [] } = useMySelector((state) => state)
  const { products } = useMySelector((state) => state)

  // State
  const [selectedProducts, setSelectedProducts] = useState<any[]>([])
  const [selectedProductId, setSelectedProductId] = useState<number>(0)
  const [position, setPosition] = useState<IPosition | null>(null)

  // useEffect
  useEffect(() => {
    setSelectedProducts(
      wishlist
        ?.map((listItem) =>
          products?.reduce((acc: IProduct[], curr: IProduct) => {
            if (curr.id === listItem) {
              return [...acc, curr]
            } else {
              const foundSTItem = curr.subTemplateItems?.find(
                (stItem) => stItem.id === listItem
              )
              if (foundSTItem) {
                return [...acc, foundSTItem as any]
              } else {
                return acc
              }
            }
          }, [])
        )
        .flat(Infinity)
    )
  }, [products, wishlist])

  // Functions
  const handleProductClick = (
    product: IProduct,
    e: MouseEvent<HTMLElement>
  ) => {
    setSelectedProductId(product.id)
    setPosition({
      x: e.pageX,
      y: e.pageY,
    })
  }

  const navigateToMenu = () => {
    if (tableId) {
      history.push(`/menu?table=${tableId}`)
    }
    if (!tableId) {
      history.push('/menu')
    }
  }

  return (
    <WishlistContainer>
      <Header />
      <div>
        <WishlistCaption>{t('common.wishlist')}</WishlistCaption>
        <RenderIf condition={Boolean(selectedProducts?.length)}>
          <Products
            products={selectedProducts}
            onProductClick={handleProductClick}
          />
        </RenderIf>
        <RenderIf condition={Boolean(!selectedProducts?.length)}>
          <NotFoundMessage>
            <p>{t('errors.emptyWishlist')}</p>
            <p>{t('errors.wishlistBrowse')}</p>
          </NotFoundMessage>
          <MenuButtonContainer>
            <Button buttonType="common" onClick={navigateToMenu}>
              {/* TODO: translations */}
              Browse now
            </Button>
          </MenuButtonContainer>
        </RenderIf>
      </div>
      <RenderIf condition={!!position}>
        <ContextMenu
          position={position as IPosition}
          discardPosition={() => setPosition(null)}
          items={contextMenuItems(
            t,
            selectedProducts,
            selectedProductId,
            handleAddClick,
            handleWishlist
          )}
        />
      </RenderIf>
    </WishlistContainer>
  )
}

const contextMenuItems = (
  t: any,
  selectedProducts: IProduct[],
  selectedProductId: number,
  handleAddClick: (product: IProduct) => void,
  handleWishlist: (id: number) => void
) => [
  {
    component: (
      <>
        <img src={basket} alt="Add" />
        {t('buttons.add')}
      </>
    ),
    onClick: () =>
      handleAddClick(
        selectedProducts.find(
          (product) => product.id === selectedProductId
        ) as IProduct
      ),
  },
  {
    component: (
      <>
        <img src={trashCan} alt="Remove" />
        {t('buttons.remove')}
      </>
    ),
    onClick: () => handleWishlist(selectedProductId),
  },
]

export default Wishlist
