import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './types'

export const WishlistSlice = createSlice({
  name: 'wishlistSlice',
  initialState,
  reducers: {
    updateWishList(state, action: PayloadAction<number[]>) {
      return (state = action.payload)
    },
  },
})

export default WishlistSlice.reducer
