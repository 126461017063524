import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { qrMenuPOST } from '../../api/qrMenu/POST'
import { IPaymentComplete } from '../../api/qrMenu/types'
import { useMySelector } from '../../hooks/useMySelector'
import { useOpenModal } from '../../hooks/useOpenModal'
import { useTranslations } from '../../hooks/useTranslations'
import { downloadFromUrl } from '../../utils/downloadFromURL'
import { Button } from '../Button'
import { RenderIf } from '../RenderIf'
import { EmailPrompt } from './emailPrompt'
import { ReceiptError } from './ReceiptError'
import { ReceiptLoading } from './ReceiptLoading'
import {
  ReceiptModalContainer,
  ReceiptNumberContainer,
  MessageContainer,
  ButtonContainer,
} from './styled'
import { handleLocalStorage } from '../../utils/handleLocalStorage'

type ReceiptModalType = {
  onCloseModal: (orderGuid?: string) => void
}

type IStatus = 'isLoading' | 'success' | 'error'

export const ReceiptModal: FC<ReceiptModalType> = ({ onCloseModal }) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()
  const { paymentVariant } = useMySelector((state) => state.app.menu)

  // Search params
  const [searchParams] = useSearchParams()
  const datatransTrxId = searchParams.get('datatransTrxId')
  const saferPayOrderGuid = searchParams.get('orderGuid')

  // State
  const [status, setStatus] = useState<IStatus>('isLoading')
  const [receiptData, setReceiptData] = useState<IPaymentComplete>()

  // useEffects
  useEffect(() => {
    triggerPaymentComplete()
  }, [])

  // Functions
  const triggerPaymentComplete = async () => {
    if ((datatransTrxId || saferPayOrderGuid) && paymentVariant) {
      try {
        const qrmResult = await qrMenuPOST.paymentComplete(
          paymentVariant as 1 | 2,
          datatransTrxId,
          saferPayOrderGuid
        )
        const restaurantId = handleLocalStorage('restaurantId')
        if (restaurantId) {
          qrMenuPOST.closeCloudOrders(restaurantId, qrmResult?.OrderGUID)
        }
        if (
          qrmResult?.Completed === 1 ||
          qrmResult?.completed === 1 ||
          qrmResult?.total
        ) {
          setReceiptData(qrmResult)
          setStatus('success')
        } else {
          setStatus('error')
        }
      } catch (e) {
        setStatus('error')
      }
    }
  }

  const openEmailPrompt = () => {
    openModal({
      id: 'EMAIL_PROMPT',
      title: '',
      components: [
        ({ closeThisModal }) => (
          <EmailPrompt
            onCloseModal={() => {
              if (onCloseModal) {
                onCloseModal(receiptData?.OrderGUID)
              }
              closeThisModal()
            }}
          />
        ),
      ],
    })
  }

  const saveReceipt = () => {
    if (receiptData?.receiptUrl) {
      downloadFromUrl(receiptData?.receiptUrl)
    }
    if (onCloseModal) {
      onCloseModal(receiptData?.OrderGUID)
    }
  }

  return (
    <>
      <RenderIf condition={status === 'isLoading'}>
        <ReceiptLoading />
      </RenderIf>
      <RenderIf condition={status === 'error'}>
        <ReceiptError />
      </RenderIf>
      <RenderIf condition={status === 'success'}>
        <ReceiptModalContainer>
          <ReceiptNumberContainer>
            <RenderIf
              condition={!!receiptData?.receiptNr || !!receiptData?.orderNr}
            >
              <h2>{receiptData?.receiptNr || receiptData?.orderNr}</h2>
              <p>{t('receipt.number')}</p>
            </RenderIf>
          </ReceiptNumberContainer>
          <MessageContainer>
            <h2>{t('receipt.paymentSuccess')}</h2>
            <p>{t('receipt.thankYouForVisit')}</p>
          </MessageContainer>
          <ButtonContainer>
            <Button buttonType="stroke" width="100%" onClick={openEmailPrompt}>
              {t('buttons.sendEMail')}
            </Button>
            <Button buttonType="common" width="100%" onClick={saveReceipt}>
              {t('buttons.save')}
            </Button>
          </ButtonContainer>
        </ReceiptModalContainer>
      </RenderIf>
    </>
  )
}
