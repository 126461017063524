import styled from 'styled-components'
import { FooterStyled } from '../../components/FooterStyled/styled'

type SubFooterProps = {
  isSlow?: boolean
  text?: string
}

export const PreviewContainer = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.MainBackGround};
  position: fixed;
  left: 0;
  top: 0;
`

export const TableDiv = styled.div`
  height: 50px;
  width: 100%;
  text-align: center;

  color: ${({ theme }) => theme.FontColor};

  font-weight: 600;
  font-size: 18px !important;
  line-height: 18px;
  padding-top: 16px;
`

export const PreviewFooter = styled(FooterStyled)`
  position: fixed;

  top: calc(100% - 120px);
  left: 0;
  right: 0;
`

export const PaymentProductsContainer = styled.div`
  min-height: 100%;
  overflow-y: scroll;
  padding: 0 3vw;
`

export const Total = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    line-height: 30px;
    font-size: 17px;
    font-weight: 500;
  }
  span:last-child {
    font-weight: 600;
    margin-left: 15px;
  }
`

export const SubFooter = styled('div')<SubFooterProps>`
  padding: 0 20px;
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  border-top: 1px solid #cdcdcd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  position: relative;
  &::after {
    content: ${({ isSlow, text }) => (isSlow ? `'${text}'` : 'unset')};
    width: 100%;
    position: absolute;
    background-color: #de6667;
    z-index: 999;
    bottom: 100%;
    left: 0;
    min-height: 5vw;
    margin: 0;
    padding: 10px 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #fff;
  }
`

export const CheckboxContainer = styled.div`
  margin: 15px 0;
`
