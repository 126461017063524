import { FC } from 'react'
import { AllergenImages } from '../../../utils/AllergenImages'
import {
  AllergenTitle,
  AllergenItemContainer,
  AllergenItemImage,
  AllergenItemImageContainer,
} from './styled'

type AllergenProps = {
  filterItem: {
    id: number | string
    name: string
  }
  isAllergen?: boolean
  selectedFilters: Array<number | string>
  handleSelectedFilters: (id: number | string) => void
}

export const AllergenItem: FC<AllergenProps> = ({
  filterItem,
  isAllergen,
  selectedFilters,
  handleSelectedFilters,
}) => {
  const getAllergenImage = (id: number | string) => {
    return AllergenImages[id] || AllergenImages[0]
  }

  const getAllergenColor = (id: number | string): string => {
    if (isAllergen && selectedFilters?.includes(id)) {
      return '#FF5151'
    }
    if (!isAllergen && selectedFilters?.includes(id)) {
      return '#4A90E2'
    }
    return '#cdcdcd'
  }

  return (
    <AllergenItemContainer
      key={filterItem.id}
      onClick={() => handleSelectedFilters(filterItem.id)}
    >
      <AllergenItemImageContainer background={getAllergenColor(filterItem.id)}>
        <AllergenItemImage src={getAllergenImage(filterItem.id)} />
      </AllergenItemImageContainer>
      <AllergenTitle>{filterItem.name}</AllergenTitle>
    </AllergenItemContainer>
  )
}
