export const catalogSearchButtonId = 'catalog-search__button-id'

export type Restaurant = {
  clickAndCollectClientId: number
  googleMapUrl: string
  id: number
  position: {
    latitude: string
    longitude: string
  }
  schedule: ScheduleType
  title: string
}

export type ScheduleType = {
  [key: string]: string
}

type Temp = {
  [key: string]: '1Mon' | '2Tue' | '3Wed' | '4Thu' | '5Fri' | '6Sat' | '7Sun'
}

export const week: Temp = {
  monday: '1Mon',
  tuesday: '2Tue',
  wednesday: '3Wed',
  thursday: '4Thu',
  friday: '5Fri',
  saturday: '6Sat',
  sunday: '7Sun',
}

export const weekArr = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]
