// import { Auth } from 'aws-amplify'
import { Auth } from '@aws-amplify/auth'
import { handleLocalStorage } from './handleLocalStorage'
import { isAuth } from './isAuth'

type isAuthT = () => Promise<string | boolean>

export const getAuthUserName: isAuthT = async () => {
  const isUserAuth = await isAuth()
  if (isUserAuth) {
    const data = await Auth.currentUserInfo()
    if (data?.attributes) {
      if (data?.attributes?.name || data?.attributes?.family_name) {
        const userName = `${data?.attributes?.name} ${data?.attributes?.family_name}`
        handleLocalStorage({ userName })
        return userName
      }
      if (data?.attributes?.phone_number) {
        return `${data?.attributes?.phone_number}`
      }
      if (data?.username) {
        const userName = `${data?.username}`
        handleLocalStorage({ userName })
        return userName
      }
      return ''
    }
    const userName = handleLocalStorage('userName')
    if (userName) {
      return userName
    }
    return false
  } else {
    return false
  }
}
