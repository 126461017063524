import { FC, useRef, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from '../Button'
import { validateEmail } from '../../utils/validateEmail'
import { useTranslations } from '../../hooks/useTranslations'
import { ReceiptModalContainer, ReceiptInput } from './styled'
import { errorHandler } from '../ErrorHandler'
import { GridDiv } from '../../utils/gridDiv'
import { qrMenuPOST } from '../../api/qrMenu/POST'

type EmailPromptType = {
  onCloseModal: () => void
}

export const EmailPrompt: FC<EmailPromptType> = ({ onCloseModal }) => {
  const t = useTranslations()
  const inputRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState('')
  const [searchParams] = useSearchParams()
  const datatransTrxId = searchParams.get('datatransTrxId')

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
      inputRef.current?.select()
    }, 0)
  }, [inputRef])

  const handleSend = async () => {
    try {
      if (datatransTrxId && validateEmail(email)) {
        const res = await qrMenuPOST.sendReseipt(datatransTrxId, email)
        if (res.success && !!onCloseModal) {
          onCloseModal()
        }
      } else {
        toast.error(t('errors.invalidEmail'))
      }
    } catch (e) {
      errorHandler(e)
    }
  }

  return (
    <ReceiptModalContainer>
      <GridDiv gc="1 / -1">
        <ReceiptInput
          ref={inputRef}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
      </GridDiv>
      <GridDiv gc="1 / -1">
        <Button buttonType="common" width="100%" onClick={handleSend}>
          {t('buttons.send')}
        </Button>
      </GridDiv>
    </ReceiptModalContainer>
  )
}
