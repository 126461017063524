import { FC, useEffect, useState } from 'react'
import { Button } from '../../../../../components/Button'
import { useTranslations } from '../../../../../hooks/useTranslations'
import { CCProvider } from '../../../../../utils/getCCProviderIcon'
import { Radio, RadioContainer } from '../../../../../components/Radio/styled'
import { RenderIf } from '../../../../../components/RenderIf'

type CardsModalProps = {
  userCardData: any
  isLoyaltyCardExisting: boolean
  closeModal: () => void
  paymentRequest: (
    useSavedCard: boolean,
    useCustomerCard: boolean,
    updateActiveCard: boolean
  ) => Promise<boolean | undefined>
}

export const CardsModal: FC<CardsModalProps> = ({
  userCardData,
  isLoyaltyCardExisting,
  closeModal,
  paymentRequest,
}) => {
  const t = useTranslations()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [value, setValue] = useState<'0' | '1' | '2'>('1')

  useEffect(() => {
    if (!userCardData) {
      setValue('0')
    }
  }, [])

  const changeValue = (e: any) => {
    setValue(e.target.value)
  }

  const handlePayClick = async () => {
    setIsLoading(true)
    await paymentRequest(value === '1', value === '0', false)
    setIsLoading(false)
    closeModal()
  }

  return (
    <div>
      <RenderIf condition={isLoyaltyCardExisting}>
        <RadioContainer>
          <Radio
            type="radio"
            id="customer"
            name="customerCard"
            value={0}
            checked={value === '0'}
            onChange={changeValue}
          />
          <label htmlFor="customer">{t('order.useCustomerCard')}</label>
        </RadioContainer>
      </RenderIf>

      <RenderIf condition={userCardData}>
        <RadioContainer>
          <Radio
            type="radio"
            id="saved"
            name="card"
            value={1}
            checked={value === '1'}
            onChange={changeValue}
          />
          <label htmlFor="saved">
            <CCProvider cardMask={JSON.parse(userCardData || null)?.masked} />{' '}
            {JSON.parse(userCardData || null)?.masked}
          </label>
        </RadioContainer>
      </RenderIf>

      <RadioContainer>
        <Radio
          type="radio"
          id="useNew"
          name="card"
          value={2}
          checked={value === '2'}
          onChange={changeValue}
        />
        {/* TODO: translations */}
        <label htmlFor="useNew">Use another card</label>
      </RadioContainer>
      <Button
        buttonType="common"
        width="100%"
        onClick={handlePayClick}
        isLoading={isLoading}
      >
        {t('cover.pay')}
      </Button>
    </div>
  )
}
