import { FC } from 'react'
import { AbsoluteDiv } from '../../utils/absoluteDiv'
import { CCProvider } from '../../utils/getCCProviderIcon'
import { CheckBox } from '../CheckBox'

import { CardContainer, CardNumber, BottomCorner } from './styled'

type CardProps = {
  cardNumber?: string
  dueDate?: string
  onCheckBoxClick?: (value: boolean) => void
}

export const Card: FC<CardProps> = ({
  cardNumber,
  dueDate,
  onCheckBoxClick,
}) => {
  const handleCheckBoxClick = (value: boolean) => {
    onCheckBoxClick && onCheckBoxClick(value)
  }

  return (
    <CardContainer data-testid="card_test-id">
      <CardNumber>{cardNumber}</CardNumber>
      {/* <AbsoluteDiv top="20px" right="20px">
        <CheckBox
          isChecked={true}
          checkedColor="darkgreen"
          onClick={handleCheckBoxClick}
          data-testid="card_checkbox__test-id"
        />
      </AbsoluteDiv> */}
      <AbsoluteDiv bottom="22px" left="20px">
        <BottomCorner isLeft>
          <CCProvider cardMask={cardNumber} height={24} />
        </BottomCorner>
      </AbsoluteDiv>
      <AbsoluteDiv bottom="20px" right="20px">
        <BottomCorner>
          <p>{dueDate}</p>
        </BottomCorner>
      </AbsoluteDiv>
    </CardContainer>
  )
}
