import styled from 'styled-components'
import { IPosition } from '../../sections/Wishlist'

type ContextMenuProps = {
  position: IPosition
}

export const ContextMenuStyled = styled('div')<ContextMenuProps>`
  top: ${({ position }) => `${position.y}px`};
  left: ${({ position }) => `${position.x}px`};
  position: absolute;
  z-index: 2;
  min-width: 75px;
  background-color: #fff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  overflow: hidden;
  padding: 5px 0;
`

export const ContextMenuItem = styled.div`
  width: 100%;
  height: 2.2rem;
  line-height: 1.7rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-right: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid #cacaca;
  }
`
