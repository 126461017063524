import { FC } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { useMySelector } from '../../hooks/useMySelector'
import { RenderIf } from '../../components/RenderIf'

import {
  IPosition,
  IRestaurant,
} from '../../store/reducers/RestaurantsReducer/types'
import { MapContainer } from './styled'

type MapProps = {
  restaurants?: IRestaurant[]
  selectedCoords?: IPosition
}

const containerStyle = {
  width: '100%',
  height: '100%',
}

export const Map: FC<MapProps> = ({ restaurants, selectedCoords }) => {
  const { interfaceStyles } = useMySelector((state) => state.app)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    mapIds: ['fed5640071b6b90f'],
    // TODO: Replace with users googleMapsApiKey
    googleMapsApiKey: 'AIzaSyDGXQ2calkSU87qKI0vVBs7M6ALBAsJGWQ',
  })

  const getPosition = (position: IPosition) => {
    return {
      lat: +position.latitude,
      lng: +position.longitude,
    }
  }

  return (
    <MapContainer>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: selectedCoords?.latitude ? +selectedCoords.latitude : 47.228,
            lng: selectedCoords?.longitude ? +selectedCoords.longitude : 9.6,
          }}
          zoom={14}
          options={{ mapId: 'fed5640071b6b90f', disableDefaultUI: true }}
        >
          {restaurants?.map((restaurant) => (
            <RenderIf
              key={restaurant.id}
              condition={!!restaurant.position.longitude}
            >
              <Marker
                key={restaurant.id}
                position={getPosition(restaurant.position)}
                icon={{
                  path: icon,
                  strokeColor: interfaceStyles.colour,
                  fillColor: interfaceStyles.colour,
                  fillOpacity: 1,
                  scale: 0.1,
                  anchor: new window.google.maps.Point(225, 500),
                }}
              />
            </RenderIf>
          ))}
          <></>
        </GoogleMap>
      ) : (
        <></>
      )}
    </MapContainer>
  )
}

const icon =
  'M257.13,125.11c40.21,0,72.52,30.23,72.52,70.43,0,38.59-32.31,70.76-72.52,70.76-40.52,0-72.85-32.17-72.85-70.76,0-40.2,32.33-70.43,72.85-70.43Zm181.54,52.42C438.67,78.79,358,0,257.13,0c-101,0-183.8,78.79-183.8,177.53,0,4.18,0,10.3,2.09,14.15H73.33c0,96.81,183.8,320.32,183.8,320.32S438.67,288.49,438.67,191.68h0V177.53Z'
