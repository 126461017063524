import styled from 'styled-components'

type RestaurantItemProps = {
  isSelected?: boolean
}

type RestaurantItemTextProps = {
  isTime?: boolean
}

export const RestaurantsContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::after {
    content: unset;
  }
`

export const MapContainer = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 0;
  position: relative;
  color: #fff;
`

export const RestaurantsList = styled.div`
  max-height: 80vw;
  width: 100vw;
  overflow-y: scroll;
  z-index: 1;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: scroll;
`

export const RestaurantItem = styled('div')<RestaurantItemProps>`
  background-color: #fff;
  height: 84px;
  width: 100%;
  margin-bottom: 10px;
  border: ${({ theme, isSelected }) =>
    `1px solid ${isSelected ? theme.colour : '#cdcdcd'}`};
  border-radius: 5px;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; */

  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 40px 40px;
`

export const RestaurantItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  grid-column: 2 / -1;
  grid-row: 1 / -1;

  &:after {
    content: unset;
  }
`

export const RestaurantItemText = styled.p`
  font-weight: 500;
  font-size: 14px !important;
  line-height: 15px;
  margin: 0;
  color: #434343;
  padding: 5px 0 5px;
`

export const Schedule = styled(RestaurantItemText)`
  color: #979797;
  padding: unset;
  display: flex;
  flex-direction: column;
`
