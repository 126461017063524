import { FC } from 'react'

import { useOpenModal } from '../../../useOpenModal'
import { useMySelector } from '../../../useMySelector'
import { useTranslations } from '../../../useTranslations'
import { roundValueTo } from '../../../../utils/roundValueTo'

import AddProductModal from '../AddProductModal'
import { Button } from '../../../../components/Button'
import { Products } from '../../../../components/Products'
import { ITemplateItem } from '../../../../store/reducers/CategoriesReducer/types'
import { ModalProps } from '../../../../store/reducers/ModalsReducer/types'

import { ItemsContainer } from './styled'

type AddSubTemplateItemProps = {
  subTemplateItems: ITemplateItem[]
  closeModal: () => void
}

export const AddSubTemplateItem: FC<AddSubTemplateItemProps> = ({
  subTemplateItems,
  closeModal,
}) => {
  // Hooks
  const t = useTranslations()
  const openModal = useOpenModal()

  // Store
  const { selectedProducts } = useMySelector((state) => state)

  const addProduct = (product: any) => {
    openModal({
      id: `ADD_PRODUCT_${product.id}`,
      title: product.title,
      components: [
        ({ onNext, closeThisModal }: ModalProps) => (
          <AddProductModal
            product={product as any}
            onNext={onNext}
            closeProduct={closeThisModal}
          />
        ),
      ],
    })
  }

  const getAmount = (product: any): number => {
    return selectedProducts?.reduce((acc, curr) => {
      if (curr.id === product.id) {
        return roundValueTo(acc + curr.amount)
      } else {
        return acc
      }
    }, 0)
  }

  return (
    <>
      <ItemsContainer>
        <Products
          getAmount={getAmount}
          products={subTemplateItems as any}
          onProductClick={addProduct}
        />
      </ItemsContainer>
      <Button width="100%" buttonType="common" onClick={closeModal}>
        {t('buttons.ok')}
      </Button>
    </>
  )
}
