import styled from 'styled-components'

type NumbersContainerType = {
  isLeft?: boolean
}

type CardNumberType = {
  isDisabled?: boolean
}

export const CardContainer = styled.div`
  width: 100%;
  aspect-ratio: 1.586; // default credit card aspect ratio
  border-radius: 10px;

  padding: 20px;
  color: #fff;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  min-height: 200px;
  max-width: 100%;
  overflow-x: hidden;
  & > * {
    z-index: 1;
  }
  &::before {
    content: '';
    background-color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  &::after {
    content: '';
    background: ${({ theme }) =>
      `linear-gradient(200deg, ${theme.colour}AE, ${theme.colour})`};
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
`

export const CardContainerSmall = styled(CardContainer)`
  aspect-ratio: unset;
  min-height: 70px;
  height: 70px;
  margin: 20px 0;

  color: #565656;
  &::after {
    background: #d9d9d9;
  }
`

export const CardNumber = styled.p`
  display: flex;
  justify-content: center;
  column-gap: 10px;

  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 20px;
  /* text-transform: uppercase; */
  transform: translate(0, -50%);
`

export const BottomCorner = styled('div')<NumbersContainerType>`
  display: flex;
  height: 100%;
  justify-content: ${({ isLeft = false }) => (isLeft ? 'start' : 'end')};
`
