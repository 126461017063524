import { FC, MouseEvent, useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'

import { RenderIf } from '../../RenderIf'
import { useMySelector } from '../../../hooks/useMySelector'
import { AllergenImages } from '../../../utils/AllergenImages'
import { convertToPrice } from '../../../utils/convertToPrice'
import { getPrice } from '../../../utils/getProductPrice'

import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { IOrderProduct } from '../../../sections/Order/types'

import fullHeart from '../../../assets/hearts/full_s.svg'
import emptyHeart from '../../../assets/hearts/empty_s.svg'
import { ReactComponent as NoteIcon } from '../../../assets/notes/small_note.svg'
import { ReactComponent as ClocheIcon } from '../../../assets/cloches/clocheSquare.svg'

import {
  AgeProtection,
  Allergen,
  AllergensContainer,
  ImageContainer,
  IncludedItems,
  KitchenItem,
  KitchenItemContainer,
  Name,
  Note,
  Price,
  ProductStyled,
  SecondaryImage,
  StyledImage,
} from './styled'
import { useWishlist } from '../../../hooks/useWishlist'
import { useOpenNote } from '../../../hooks/useOpenNote'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'
import { IconFilled, IconStroked } from '../../IconsContainers/styled'
import { useTranslations } from '../../../hooks/useTranslations'
import { SoldOutBanner } from '../ProductGrided/styled'
import { getProtectedAge } from '../../../utils/getProtectedAge'
import { isProductProtected } from '../../../utils/isProductProtected'

type ProductProps = {
  product: IProduct | IOrderProduct
  onProductClick: (product: IProduct, e: MouseEvent<HTMLElement>) => void
  showPricePerItem?: boolean

  isSelected?: boolean
  amount?: number
  altCount?: number
  includedItems?: any[]
  selectedKitchenInfo?: string[]
}

export const ProductListedComponent: FC<ProductProps> = ({
  product,
  onProductClick,
  showPricePerItem = false,

  isSelected,
  amount,
  altCount,
  includedItems,
  selectedKitchenInfo,
}) => {
  // Hooks
  const handleWishlist = useWishlist()
  const openNote = useOpenNote()
  const t = useTranslations()

  // Store
  const { wishlist } = useMySelector((state) => state)
  const { paymentVariant } = useMySelector((state) => state.app.menu)
  const { notesAvailable } = useMySelector((state) => state.app.menu)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const { colour } = useMySelector((state) => state.app.interfaceStyles)

  // State
  const [isImgErrored, setIsImageErrored] = useState(false)
  const [productsNotes, setProductsNotes] = useState<{
    [key: number]: string
  }>({})

  // useEffects
  useEffect(() => {
    setProductsNotes(handleLocalStorage('products_notes'))
  }, [])

  // Functions
  const handleNoteClick = (e: any) => {
    if (notesAvailable === 1) {
      e.stopPropagation()
      openNote(
        product?.id,
        setProductsNotes,
        !(location.pathname === '/payment' && paymentVariant === 2)
      )
    }
  }

  const handleWishListClick = (e: any) => {
    e.stopPropagation()
    handleWishlist(product?.id)
  }

  const handleProductClick = (
    product: IProduct,
    e: MouseEvent<HTMLElement>
  ) => {
    if (onProductClick && Boolean(!product?.soldOut)) {
      onProductClick(product, e)
    }
  }

  const isInWishlist = (idToCheck: number) => {
    return !!wishlist?.find((listItem) => listItem === idToCheck)
  }

  const getPriceToShow = () => {
    if (showPricePerItem) {
      return convertToPrice(getPrice(product))
    }
    if (!showPricePerItem) {
      const amount = product?.amount || 1
      const menuItems: any[] = product?.menuItems || []
      const additionalPrice = menuItems?.reduce((acc: number, curr: any) => {
        const { price = 0, count = 0 } = curr
        return acc + price * count
      }, 0)
      return convertToPrice((getPrice(product) + additionalPrice) * amount)
    }
  }

  const getIncludedItemPrice = (price?: number) => {
    if (price) {
      return `${convertToPrice(price)} ${Currency || ''}`
    }
    if (!price) {
      return t('order.free')
    }
  }

  return (
    <ProductStyled
      id={`product_${product?.id}`}
      isSelected={isSelected}
      onClick={(e) => handleProductClick(product, e)}
    >
      <ImageContainer count={(product?.amount as number) || amount}>
        <RenderIf condition={!isImgErrored}>
          <StyledImage
            src={product?.imageUrl || 'ERRORED_IMAGE'}
            onError={({ currentTarget }) => {
              setIsImageErrored(true)
              currentTarget.onerror = null // prevents looping
            }}
            alt={product?.title}
            width="75"
            height="75"
          />
        </RenderIf>
        <RenderIf condition={isImgErrored}>
          <IconFilled color={`${colour}80`}>
            <ClocheIcon />
          </IconFilled>
        </RenderIf>
      </ImageContainer>
      <Name altCount={altCount}>
        <p>{product?.title}</p>
      </Name>
      <Note>
        <RenderIf
          condition={
            location.pathname !== '/menu' && !!productsNotes?.[product?.id]
          }
        >
          <p>{productsNotes?.[product?.id]}</p>
        </RenderIf>
        <RenderIf condition={location.pathname === '/menu'}>
          <AllergensContainer>
            {product?.allergens?.split(', ').map((el) => (
              <Allergen
                key={el}
                alt={el}
                src={AllergenImages[el] || AllergenImages[0]}
              />
            ))}
          </AllergensContainer>
        </RenderIf>
      </Note>
      <RenderIf condition={isProductProtected(product)}>
        <AgeProtection>{getProtectedAge(product)}</AgeProtection>
      </RenderIf>
      <RenderIf
        condition={
          notesAvailable === 1 &&
          location.pathname !== '/menu' &&
          !(location.pathname === '/payment' && paymentVariant === 2)
        }
      >
        <SecondaryImage onClick={handleNoteClick}>
          <IconStroked>
            <NoteIcon />
          </IconStroked>
        </SecondaryImage>
      </RenderIf>
      <RenderIf condition={location.pathname === '/menu'}>
        <SecondaryImage onClick={handleWishListClick}>
          <img
            alt="wishlist"
            src={isInWishlist(product?.id) ? fullHeart : emptyHeart}
            width="17"
            height="15"
          />
        </SecondaryImage>
      </RenderIf>
      <RenderIf condition={Boolean(getPrice(product)) || showPricePerItem}>
        <Price isSoldOut={product?.soldOut}>
          <span>{getPriceToShow()}</span>
          &thinsp;
          <span>{Currency || ''}</span>
          <RenderIf condition={Boolean(product?.soldOut)}>
            <SoldOutBanner isList>{t('products.soldOut')}</SoldOutBanner>
          </RenderIf>
        </Price>
      </RenderIf>
      <RenderIf
        condition={!!includedItems?.length || !!selectedKitchenInfo?.length}
      >
        <IncludedItems>
          <RenderIf condition={!!selectedKitchenInfo?.length}>
            <KitchenItemContainer>
              {selectedKitchenInfo?.map((el) => (
                <KitchenItem key={`kitchenInfo_${el}`}>{el}</KitchenItem>
              ))}
            </KitchenItemContainer>
          </RenderIf>

          {includedItems?.map((currentItem: any) => (
            <p key={`includedItem_${currentItem.productId}`}>{`${
              currentItem.amount
            } ${currentItem.name} (${getIncludedItemPrice(
              currentItem.price
            )})`}</p>
          ))}
        </IncludedItems>
      </RenderIf>
    </ProductStyled>
  )
}

interface ProductPropsExtended extends ProductProps {
  withLazy?: boolean
}

const ProductListedHOC =
  (Component: FC<ProductProps>): FC<ProductPropsExtended> =>
  ({ withLazy = false, product, ...rest }) => {
    return (
      <>
        <RenderIf condition={withLazy}>
          <LazyLoad height={87} once key={product?.id}>
            <Component product={product} {...rest} />
          </LazyLoad>
        </RenderIf>
        <RenderIf condition={!withLazy}>
          <Component product={product} {...rest} />
        </RenderIf>
      </>
    )
  }

export const ProductListed = ProductListedHOC(ProductListedComponent)
