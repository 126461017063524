import styled from 'styled-components'

type AbsoluteDivProps = {
  top?: string
  bottom?: string
  left?: string
  right?: string
}

export const AbsoluteDiv = styled('div')<AbsoluteDivProps>`
  position: absolute;
  top: ${({ top }) => top || 'unset'};
  left: ${({ left }) => left || 'unset'};
  bottom: ${({ bottom }) => bottom || 'unset'};
  right: ${({ right }) => right || 'unset'};
`
