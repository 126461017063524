import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import { setupStore } from './store'
import history from './utils/history'
import { unstable_HistoryRouter as Router } from 'react-router-dom'

import { initSentry } from './utils/sentry'
import './index.css'

const store = setupStore()
initSentry()

render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
