export const initialState = {
  isColudOrderExist: false,
  isColudOrderChecked: false,
  restaurantId: '',
  cloudOrdersList: [],
}

export type UpdateCloudOrdersArrayType = {
  restaurantId: string
  cloudOrdersList: any
}
