import { clickCollectInstance } from '.'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { CCgetPathes, WalletPathes } from '../ApiGETPathes'
import { CardType } from './types'

const withToken = true

export class clickCollectGET {
  public static async customerCard(walletCardId?: string) {
    const guid = handleLocalStorage('guid')
    const result: CardType = await clickCollectInstance.get(
      walletCardId
        ? `${WalletPathes.GET_CARD}?company=${guid}&cardId=${walletCardId}`
        : `${CCgetPathes.CARD}?company=${guid}`,
      withToken
    )
    return result
  }

  public static async cardDetails() {
    const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
    const result: any = await clickCollectInstance.get(
      `${CCgetPathes.CARD_DETAILS}?company=${guid}&register=${registerId}`,
      withToken,
      true,
      true
    )
    return result
  }
}
