import { FC, useState } from 'react'
import dietData from './dietData'
import { AllergenItem } from './AllergenItem'
import { Button } from '../../../components/Button'
import { RenderIf } from '../../../components/RenderIf'
import { useTranslations } from '../../../hooks/useTranslations'
import { useMySelector } from '../../../hooks/useMySelector'
import { useMyDispatch } from '../../../hooks/useMyDispatch'

import {
  ButtonsContainer,
  AllergensComponentContainer,
  AllergensContainer,
} from './styled'

export type CategoryType = 'allergen' | 'diet'

type AllergensProps = {
  onClear: () => void
  onApply: () => void
  type: 'menu' | 'tableReservation'
  allergensActions: {
    addAllergen: (value: number) => any
    deleteAllergen: (value: number) => any
    addDiet: (value: string) => any
    deleteDiet: (value: string) => any
  }
}

export const AllergensModal: FC<AllergensProps> = ({
  onClear,
  onApply,
  type = 'menu',
  allergensActions,
}) => {
  const dispatch = useMyDispatch()
  const t = useTranslations()

  // Actions
  const { addAllergen, deleteAllergen, addDiet, deleteDiet } = allergensActions

  // Store
  const { allergens } = useMySelector((state) => state.common)
  // const { allergens: selectedAllergens, diet } = useMySelector(
  //   (state) => state.filters
  // )
  const { allergens: selectedAllergens, diet } = useMySelector((state) => {
    if (type === 'menu') {
      return state.filters
    }
    if (type === 'tableReservation') {
      return state.tableReservation
    }
    return state.filters
  })

  // State
  const [category, setCategory] = useState<CategoryType>('allergen')

  // Functions
  const handleSelectedAllergens = (id: number) => {
    if (selectedAllergens.includes(id)) {
      dispatch(deleteAllergen(id))
    }
    if (!selectedAllergens.includes(id)) {
      dispatch(addAllergen(id))
    }
  }

  const handleSelectedDietItems = (id: string) => {
    if (diet.includes(id)) {
      dispatch(deleteDiet(id))
    }
    if (!diet.includes(id)) {
      dispatch(addDiet(id))
    }
  }

  return (
    <AllergensComponentContainer>
      <ButtonsContainer>
        <Button
          width="100%"
          buttonType={category === 'allergen' ? 'dark' : 'gray'}
          onClick={() => setCategory('allergen')}
        >
          {t('filters.allergens')}
        </Button>
        <Button
          width="100%"
          buttonType={category === 'diet' ? 'dark' : 'gray'}
          onClick={() => setCategory('diet')}
        >
          {t('filters.diet')}
        </Button>
      </ButtonsContainer>
      <AllergensContainer>
        <RenderIf condition={category === 'allergen'}>
          {allergens?.map((allergen) => (
            <AllergenItem
              key={allergen.id}
              isAllergen
              filterItem={allergen}
              selectedFilters={selectedAllergens}
              handleSelectedFilters={(allergenId) =>
                handleSelectedAllergens(allergenId as number)
              }
            />
          ))}
        </RenderIf>
        <RenderIf condition={category === 'diet'}>
          {dietData?.map((dietItem) => (
            <AllergenItem
              key={dietItem.id}
              isAllergen={false}
              filterItem={dietItem}
              selectedFilters={diet}
              handleSelectedFilters={(itemId) =>
                handleSelectedDietItems(itemId as string)
              }
            />
          ))}
        </RenderIf>
      </AllergensContainer>
      <ButtonsContainer>
        <Button width="100%" buttonType="stroke" onClick={onClear}>
          {t('buttons.clear')}
        </Button>
        <Button width="100%" buttonType="common" onClick={onApply}>
          {t('buttons.apply')}
        </Button>
      </ButtonsContainer>
    </AllergensComponentContainer>
  )
}
