import styled from 'styled-components'

export const NotFoundMessage = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.colour};
  text-align: center;
  max-width: 300px;
  margin: 75px auto;
`
