import styled from 'styled-components'

export const AskWaiterModalContainer = styled.div`
  padding-top: 20px;
  color: ${({ theme }) => theme.FontColor};
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    text-align: justify;
  }
`
