import { FC } from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  //
  TwitterShareButton,
  // TwitterIcon,
  //
  WhatsappShareButton,
  WhatsappIcon,
  //
  PinterestShareButton,
  PinterestIcon,
} from 'react-share'
import { SocialIcon } from 'react-social-icons'

import { useMySelector } from '../../../hooks/useMySelector'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'

import { StyledButtonsContainer } from './styled'

const installText = () => 'Here is where i buy products'

export const ShareButtons = () => {
  const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])
  const interfaceStyles = useMySelector((state) => state.app.interfaceStyles)

  return (
    <StyledButtonsContainer>
      <FacebookShareButton
        quote={installText()}
        title={installText()}
        url={`${process.env.REACT_APP_URL}?guid=${guid}${
          registerId ? '&register=' + registerId : ''
        }`}
      >
        <Icon I={FacebookIcon} />
      </FacebookShareButton>
      <TwitterShareButton
        title={installText()}
        url={`${process.env.REACT_APP_URL}?guid=${guid}${
          registerId ? '&register=' + registerId : ''
        }`}
      >
        <SocialIcon network="x" />
      </TwitterShareButton>
      <WhatsappShareButton
        title={installText()}
        url={`${process.env.REACT_APP_URL}?guid=${guid}${
          registerId ? '&register=' + registerId : ''
        }`}
      >
        <Icon I={WhatsappIcon} />
      </WhatsappShareButton>
      <PinterestShareButton
        media={interfaceStyles.appIcon || interfaceStyles.logoImage}
        description={installText()}
        url={`${process.env.REACT_APP_URL}?guid=${guid}${
          registerId ? '&register=' + registerId : ''
        }`}
      >
        <Icon I={PinterestIcon} />
      </PinterestShareButton>
    </StyledButtonsContainer>
  )
}

const Icon: FC<any> = ({ I }) => <I round size={50} />
