import { Auth } from '@aws-amplify/auth'

type isAuthT = () => Promise<boolean>

export const isAuth: isAuthT = async () => {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch (e) {
    return false
  }
}
