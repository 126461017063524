export default [
  { id: 'isLactoseFree', name: 'Laktosefrei' },
  { id: 'isAlcohol', name: 'Alkohol' },
  { id: 'isBio', name: 'Bio' },
  { id: 'isVegan', name: 'Vegan' },
  { id: 'isVegetarian', name: 'Vegetarisch' },
  { id: 'isNoGluten', name: 'Glutenfrei' },
  { id: 'isDinkel', name: 'Dinkel' },
  { id: 'isContainGluten', name: 'Glutenhaltiges' },
]
