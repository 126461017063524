import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Header } from '../../components/Header'
import { Cart } from '../../components/Cart.tsx'
import { Spinner } from '../../components/Spinner'
import { RenderIf } from '../../components/RenderIf'
import { useOpenModal } from '../../hooks/useOpenModal'
import { useMyDispatch } from '../../hooks/useMyDispatch'
import { useMySelector } from '../../hooks/useMySelector'
import { useTranslations } from '../../hooks/useTranslations'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { SelectedProductsSlice } from '../../store/reducers/SelectedProductsReducer'
import { ISelectedProduct } from '../../store/reducers/SelectedProductsReducer/types'
import { convertToPrice } from '../../utils/convertToPrice'
import { IOrderProduct, IProductsToOrder } from './types'
import { getPrice } from '../../utils/getProductPrice'
import { Success } from './OrderModal/successModal'
import { Error } from './OrderModal/errorModal'
import history from '../../utils/history'

import {
  OrderButtonContainer,
  OrderContainer,
  OrderFooter,
  OrderProductsContainer,
  Total,
} from './styled'
import {
  IProduct,
  ITemplateItem,
} from '../../store/reducers/CategoriesReducer/types'
import { qrMenuPOST } from '../../api/qrMenu/POST'
import { errorHandler } from '../../components/ErrorHandler'
import { mobileWaiterGET } from '../../api/mobileWaiter/GET'
import {
  Statustype,
  TableDataResType,
  TableStatustype,
} from '../../api/mobileWaiter/types'
import { mobileWaiterPOST } from '../../api/mobileWaiter/POST'
import { MWError } from './OrderModal/mwErrorModal'
import { getStatusWithInterval } from '../../utils/getStatusWithInterval'

export type IPosition = {
  x: number
  y: number
}

export const Order = () => {
  // Hooks
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const [searchParams] = useSearchParams()

  // Actions
  const { discardSelectedProducts } = SelectedProductsSlice.actions

  // Store
  const { products } = useMySelector((state) => state)
  const { tableData } = useMySelector((state) => state.common)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const { selectedProducts } = useMySelector((state) => state)
  const { isColudOrderExist, isColudOrderChecked, restaurantId } =
    useMySelector((state) => state.cloudOrders)

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isMWLoading, setIsMWLoading] = useState<boolean>(false)
  const [productsToShow, setProductsToShow] = useState<IOrderProduct[]>([])
  const [productsToOrder, setProductsToOrder] = useState<IProductsToOrder[]>([])
  const [total, setTotal] = useState<number>(0)
  const [tableStatus, setTableStatus] = useState<TableStatustype>()
  const [mwTableData, setMwTableData] = useState<TableDataResType>(null)

  const tableId = searchParams.get('table')
  const tableName = tableData.caption || tableData.objectTag
  const { guid, registerId } = handleLocalStorage(['guid', 'registerId'])

  // useEffects
  useEffect(() => {
    checkTableStatus()
    getSelectedProducts()
  }, [selectedProducts]) // eslint-disable-line

  // Functions
  const checkTableStatus = async () => {
    try {
      setIsLoading(true)
      if (tableId) {
        const tableStatusData = await mobileWaiterGET.tableStatus(tableId)
        setTableStatus(tableStatusData.status)
        setMwTableData(tableStatusData.tableData)
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const getSelectedProducts = () => {
    if (!selectedProducts.length) {
      if (tableId) {
        history.replace(`/menu?table=${tableId}`)
      }
      if (!tableId) {
        history.replace('/menu')
      }
    }
    const productsToSet = selectedProducts.reduce(
      (acc: any[], curr: ISelectedProduct) => {
        let foundProduct: IProduct | ITemplateItem | undefined = products?.find(
          (sp) => sp.id === curr.id
        )
        if (!foundProduct) {
          foundProduct = products
            ?.reduce((acc: ITemplateItem[], current) => {
              if (current.subTemplateItems) {
                return [...acc, ...current.subTemplateItems]
              }
              return acc
            }, [])
            ?.find((st) => st.id === curr.id)
        }
        return [
          ...acc,
          {
            ...foundProduct,
            ...curr,
            amount: curr?.amount || 0,
            kitchenInfo: curr?.selectedKitchenInfo || '{}',
          },
        ]
      },
      []
    )

    const productsNotes = handleLocalStorage('products_notes')
    const productsToOrder = productsToSet.map(
      ({
        id,
        categoryId,
        amount,
        kitchenInfo,
        menuItems,
        addedAsWeight,
        ...product
      }) => ({
        productId: id,
        addedAsWeight,
        categoryId,
        count: amount ?? 0,
        price: getPrice(product),
        kitchenInfo,
        menuItems,
        customerNote: productsNotes?.[id],
      })
    )
    //
    setProductsToShow(productsToSet)
    setProductsToOrder(productsToOrder)
    getTotalPrice(productsToSet)
  }

  const handleBookClick = () => {
    if (isColudOrderChecked && isColudOrderExist) {
      // call an update
      updateMWOrder()
    } else if (selectedProducts.length && tableStatus === 'FREE') {
      createOrder()
    } else if (selectedProducts.length && tableStatus === 'BUSY') {
      createMWOrder()
    }
  }

  const updateMWOrder = async () => {
    if (guid && tableId && restaurantId) {
      const res = await qrMenuPOST.updateCloudOrders(
        tableId,
        restaurantId,
        productsToOrder
      )
      openOrderModal()
    }
  }

  const createMWOrder = async () => {
    try {
      setIsMWLoading(true)
      const requestGUID = await addOredrItems()
      if (requestGUID?.length) {
        const statusData = await getStatusWithInterval(requestGUID)
        if (statusData?.status === 'COMPLETED') openOrderModal()
        if (statusData?.status === 'PENDING') openMWErrorModal()
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsMWLoading(false)
    }
  }

  const createOrder = async () => {
    try {
      setIsLoading(true)
      if (guid && tableId && registerId) {
        await qrMenuPOST.bookProducts(tableId, productsToOrder)
        openOrderModal()
      }
    } catch (e) {
      openModal({
        id: 'ERROR_MODAL',
        components: [
          ({ closeThisModal }) => <Error closeModal={closeThisModal} />,
        ],
        title: t('errors.commonErrorMessage'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const addOredrItems = async () => {
    try {
      const { restaurantId, tableId } = mwTableData || {}

      if (restaurantId && tableId) {
        const requestGUID = await mobileWaiterPOST.addOredrItems(
          restaurantId,
          tableId,
          productsToOrder
        )
        return requestGUID
      }

      return
    } catch {
      return
    }
  }

  const getTotalPrice = (orderProducts: IOrderProduct[]) => {
    const totalToSet = orderProducts.reduce((acc, curr) => {
      const { menuItems = [] } = curr
      const currentPrice = getPrice(curr)
      return (
        acc +
        (currentPrice +
          menuItems?.reduce(
            (acc, { count, price }) => acc + count * price,
            0
          )) *
          (curr.amount ?? 0)
      )
    }, 0)
    setTotal(totalToSet)
  }

  // Modals
  const openOrderModal = () => {
    openModal({
      id: 'SUCCESS_MODAL',
      components: [
        ({ closeThisModal }) => (
          <Success
            closeModal={() => {
              dispatch(discardSelectedProducts())
              if (tableId) {
                history.push(`/menu?table=${tableId}`)
              }
              if (!tableId) {
                history.push('/menu')
              }
              closeThisModal()
            }}
          />
        ),
      ],
      onClose: () => {
        dispatch(discardSelectedProducts())
        if (tableId) {
          history.push(`/menu?table=${tableId}`)
        }
        if (!tableId) {
          history.push('/menu')
        }
      },
      title: t('order.toMenu'),
    })
  }

  const openMWErrorModal = () => {
    openModal({
      id: 'MW_ERROR_MODAL',
      components: [
        ({ closeThisModal }) => (
          <MWError
            closeModal={closeThisModal}
            orderAgain={() => {
              closeThisModal()
              createMWOrder()
            }}
          />
        ),
      ],
      title: '',
    })
  }

  return (
    <>
      <RenderIf condition={isMWLoading}>
        <Spinner type="transparent" />
      </RenderIf>
      <Header />
      <OrderContainer>
        <RenderIf condition={!isLoading}>
          <OrderProductsContainer>
            <Cart products={productsToShow} />
          </OrderProductsContainer>
        </RenderIf>
        <RenderIf condition={isLoading}>
          <Spinner type="alternate" />
        </RenderIf>

        <OrderFooter>
          <Total>
            <span>Total:</span>
            <span>
              {convertToPrice(total)} {Currency || ''}
            </span>
          </Total>
          <OrderButtonContainer>
            <Button
              buttonType="common"
              width="50%"
              disabled={!tableStatus || isMWLoading}
              onClick={handleBookClick}
            >
              {tableName
                ? t('order.bookTable', { tableNumber: tableName })
                : t('order.book')}
            </Button>
          </OrderButtonContainer>
        </OrderFooter>
      </OrderContainer>
    </>
  )
}

export default Order
