import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './types'

export const CategoriesSlice = createSlice({
  name: 'categoriesSlice',
  initialState,
  reducers: {
    fetchCategories(state, action: PayloadAction<any>) {
      return (state = action.payload)
    },
  },
})

export default CategoriesSlice.reducer
