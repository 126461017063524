import styled from 'styled-components'

type ProductBasketProps = {
  count?: number
}

export const ProductBasket = styled('div')<ProductBasketProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::after {
    content: ${({ count }) => (count ? `'${count}'` : 'unset')};
    position: absolute;
    color: #fff;
    background: rgba(0, 0, 0, 0.75);
    height: 25px;
    line-height: 24px;
    width: 25px;
    border-radius: 15px;

    transform: translate(-50%, -50%);
    text-align: center;
    left: 90%;
    top: 90%;
    font-size: 13px;
    padding-top: 1px;
    box-shadow: 0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%);
  }
`
