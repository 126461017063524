import { MWpostPathes, TRpostPathes } from '../ApiPOSTPathes'
import { mobileWaiterInstance } from '.'

export class mobileWaiterPOST {
  public static async addOredrItems(
    restaurantId: string,
    tableId: number,
    orderItems: any[]
  ): Promise<string> {
    const result: { requestGUID: string } = await mobileWaiterInstance.post(
      MWpostPathes.ADD_ORDER_ITEMS,
      {
        restaurantId,
        tableId,
        orderItems,
      }
    )
    return result?.requestGUID
  }

  public static async migrateItems(
    restaurantId: string,
    tableId: number
  ): Promise<string> {
    const result: { requestGUID: string } = await mobileWaiterInstance.post(
      MWpostPathes.MIGRATE_ITEMS,
      {
        restaurantId,
        tableId,
      }
    )
    return result?.requestGUID
  }
}
