import styled from 'styled-components'
import { Button } from '../../../../components/Button'
import { InputStyled } from '../../../../components/Search/styled'
import { GridDiv } from '../../../../utils/gridDiv'
import { Total } from '../../styled'

export const TipsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TipsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 5px;
  margin: 10px;
`

export const TipsModalButton = styled(Button)`
  flex-direction: column;
  width: calc((100vw - 30px) / 3);
  height: calc((100vw - 30px) / 3);
`

export const TipsModalButtonPercentage = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 32px !important;
  line-height: 39px;
  color: ${({ theme }) => theme.colour};
`

export const TipsGridContainer = styled(GridDiv)`
  margin: 0 -10px;
`

export const TipsInput = styled(InputStyled)`
  text-align: end;
`

export const InputCurrency = styled.p`
  margin: 0 20px;
`

export const TotalContainer = styled.div`
  padding: 0 20px;
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
  /* border-top: ${({ theme }) => `1px solid ${theme.ItemsBorders}`}; */
  border-top: 1px solid #cdcdcd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
`

export const TipsTotal = styled(Total)`
  height: 5vw;
  margin: 10px 0;
  span {
    line-height: 5vw;
    font-size: 15px;
  }
`
