import { FC, useEffect, useState } from 'react'
import { useMySelector } from '../../../useMySelector'
import { RenderIf } from '../../../../components/RenderIf'
import { IOrderProduct } from '../../../../sections/Order/types'
import { AllergenImages } from '../../../../utils/AllergenImages'
import { AmountHandler } from '../../../../components/AmountHandler'
import { IProduct } from '../../../../store/reducers/CategoriesReducer/types'
import { convertToPrice } from '../../../../utils/convertToPrice'
import { getPrice } from '../../../../utils/getProductPrice'
import { useTranslations } from '../../../useTranslations'
import { Button } from '../../../../components/Button'
import { KitchenInfoItemType } from '.'
import { handleLocalStorage } from '../../../../utils/handleLocalStorage'

import { useWishlist } from '../../../useWishlist'
import { useOpenNote } from '../../../useOpenNote'

import { ReactComponent as ClocheIcon } from '../../../../assets/cloches/clocheSquare.svg'
import emptyHeart from '../../../../assets/hearts/empty_l.svg'
import note from '../../../../assets/notes/large_note.svg'
import {
  Allergen,
  AllergenItem,
  AllergensContainer,
  Description,
  Footer,
  KitchenItem,
  KitchenItemsContainer,
  ProductMainContainer,
  ProductMainTitle,
  ProductMainImage,
  ProductMainImageContainer,
  ProductMainPriceContainer,
  ProductMainPrice,
  ProductTotalPrice,
  SecondaryImage,
  ProductTitlePrice,
  AllergensSections,
  SectionTitle,
  Divider,
  ButtonContainer,
  CutDisclaimer,
  PriceInfoContainer,
  AllergenText,
  MinMaxWarning,
  AgeProtection,
} from './styled'
import { IconFilled } from '../../../../components/IconsContainers/styled'
import { isProductProtected } from '../../../../utils/isProductProtected'
import { getProtectedAge } from '../../../../utils/getProtectedAge'

type AddProductModalComponentProps = {
  product: IProduct | IOrderProduct
  onAddClick: () => void
  isAddDisabled: boolean
  isLeadDaysExceeded: boolean
  selectedKitchenInfo: KitchenInfoItemType
  amountState: [number, (value: number) => void]
  handleKitchenInfo: (infoToAdd: KitchenInfoItemType) => void
  onNext?: (values: any) => void
}

export const AddProductModalComponent: FC<AddProductModalComponentProps> = ({
  product,
  onAddClick,
  isAddDisabled,
  isLeadDaysExceeded,
  selectedKitchenInfo,
  amountState,
  handleKitchenInfo,
  onNext,
}) => {
  // Hooks
  const t = useTranslations()
  const handleWishlist = useWishlist()
  const openNote = useOpenNote()

  // Store
  const { allergens } = useMySelector((state) => state.common)
  const { Currency } = useMySelector((state) => state.app.companyData)
  const { colour } = useMySelector((state) => state.app.interfaceStyles)
  const { bookingVariant, notesAvailable } = useMySelector(
    (state) => state.app.menu
  )

  // State
  const [currentAmount, setCurrentAmount] = amountState
  const [isImgErrored, setIsImageErrored] = useState(false)
  const [isWishlisted, setIsWishlisted] = useState<boolean>(false)
  const [isDisclaimerShown, setIsDisclaimerShown] = useState<boolean>(false)

  //useEffects
  useEffect(() => {
    const isSelected = !!handleLocalStorage('wishlist')?.find(
      (listItem: number) => listItem === product.id
    )
    setIsWishlisted(isSelected)
  }, [product.id])

  // Functions
  const handleWishListClick = (productId: number) => {
    handleWishlist(productId)
    setIsWishlisted((prevValue) => !prevValue)
  }

  const getMultiplier = (sellCode: 1 | 2 | 3) => {
    if (sellCode === 2) {
      return 100
    } else {
      return 1
    }
  }

  const getStep = (sellCode: 1 | 2 | 3) => {
    if (sellCode === 3) {
      return 0.1
    } else {
      return 1
    }
  }

  const handleOpenNote = (productId: number) => {
    if (notesAvailable === 1) {
      openNote(productId)
    }
  }

  const getDaysText = (daysAmount?: number) => {
    if (daysAmount === 1) return `1 ${t('common.day')}`
    return `${daysAmount || 0} ${t('common.days')}`
  }

  return (
    <>
      <ProductMainContainer>
        {/* Image */}
        <ProductMainImageContainer>
          <RenderIf condition={!isImgErrored}>
            <ProductMainImage
              src={product.imageUrl || 'ERRORED_IMAGE'}
              alt="product"
              onError={({ currentTarget }) => {
                setIsImageErrored(true)
                currentTarget.onerror = null // prevents looping
              }}
            />
          </RenderIf>
          <RenderIf condition={isImgErrored}>
            <IconFilled color={`${colour}80`}>
              <ClocheIcon />
            </IconFilled>
          </RenderIf>
          <RenderIf condition={isProductProtected(product)}>
            <AgeProtection>{getProtectedAge(product)}</AgeProtection>
          </RenderIf>
          <RenderIf
            condition={notesAvailable === 1 && location.pathname !== '/menu'}
          >
            <SecondaryImage onClick={() => handleOpenNote(product.id)}>
              <img alt="note" src={note} />
            </SecondaryImage>
          </RenderIf>
          <RenderIf condition={location.pathname === '/menu'}>
            <SecondaryImage
              onClick={() => handleWishListClick(product.id)}
              isActive={isWishlisted}
            >
              <img alt="wishlist" src={emptyHeart} />
            </SecondaryImage>
          </RenderIf>
        </ProductMainImageContainer>

        {/* Title and Price */}
        <ProductMainTitle>
          <p>{product.title}</p>
          <RenderIf
            condition={
              !!product.ClickCollectOrderMin && !!product.ClickCollectOrderMax
            }
          >
            <MinMaxWarning>
              {product.ClickCollectOrderMin
                ? `Min. ${product.ClickCollectOrderMin}`
                : ''}
              {product.ClickCollectOrderMin && product.ClickCollectOrderMax
                ? ' / '
                : ''}
              {product.ClickCollectOrderMax
                ? `Max. ${product.ClickCollectOrderMax}`
                : ''}
            </MinMaxWarning>
          </RenderIf>
          <RenderIf
            condition={product.unitSellCode === 1 && Boolean(getPrice(product))}
          >
            <ProductTitlePrice>
              <ProductTotalPrice>
                {convertToPrice(getPrice(product) * currentAmount)}&thinsp;
                {Currency || ''}
              </ProductTotalPrice>
            </ProductTitlePrice>
          </RenderIf>
        </ProductMainTitle>

        {/* Price for different unitSellCode */}
        <RenderIf
          condition={product.unitSellCode === 2 || product.unitSellCode === 3}
        >
          <ProductMainPriceContainer>
            <p>
              {product.unitSellCode === 2 ? '100g' : ''}
              {product.unitSellCode === 3 ? '1kg' : ''}
            </p>
            <p> = </p>
            <p>
              {convertToPrice(getPrice(product))}&thinsp;
              {Currency || ''}
            </p>
            <PriceInfoContainer>
              <ProductMainPrice onClick={() => setIsDisclaimerShown(true)}>
                i
              </ProductMainPrice>
              <ProductMainPrice>
                <ProductTotalPrice>
                  {convertToPrice(getPrice(product) * currentAmount)}&thinsp;
                  {Currency || ''}
                </ProductTotalPrice>
              </ProductMainPrice>
            </PriceInfoContainer>
          </ProductMainPriceContainer>
          <RenderIf condition={isDisclaimerShown}>
            <CutDisclaimer>{t('products.cutDisclaimer')}</CutDisclaimer>
          </RenderIf>
        </RenderIf>

        {/* Variants */}
        {Array.isArray(product.kitchenInfo) &&
          product.kitchenInfo?.map((infoItem) => (
            <div key={`${product.id}_kitchenInfo_${infoItem.title}`}>
              <SectionTitle>{infoItem.title}</SectionTitle>
              <div>
                <KitchenItemsContainer>
                  {infoItem.values?.map((value) => (
                    <KitchenItem
                      key={value}
                      isActive={
                        selectedKitchenInfo?.[infoItem.title]?.indexOf(value) >
                        -1
                      }
                      onClick={() =>
                        handleKitchenInfo({ [infoItem.title]: value })
                      }
                    >
                      {value}
                    </KitchenItem>
                  ))}
                </KitchenItemsContainer>
              </div>
            </div>
          ))}

        {/* Allergens */}
        <RenderIf condition={!!product?.allergens?.length}>
          <AllergensSections>
            <SectionTitle>{t('filters.allergens')}</SectionTitle>
            <AllergensContainer>
              {product?.allergens?.split(', ')?.map((id, idx) => {
                return (
                  <AllergenItem key={`product_allergen__${id}`}>
                    <Allergen
                      src={AllergenImages[id] || AllergenImages[0]}
                      alt={id}
                    />
                    <AllergenText>
                      {product.linkRA
                        ? product.allergenLabels?.split(',')?.[idx]
                        : allergens.find((allergen) => allergen.id === +id)
                            ?.name}
                    </AllergenText>
                  </AllergenItem>
                )
              })}
            </AllergensContainer>
          </AllergensSections>
        </RenderIf>

        {/* Description */}
        <RenderIf condition={!!product.note}>
          <SectionTitle>{t('products.description')}</SectionTitle>
          <Description>{product.note}</Description>
        </RenderIf>
      </ProductMainContainer>

      {/* Footer */}
      <RenderIf
        condition={
          navigator.onLine &&
          (bookingVariant === 2 || process.env.REACT_APP_IS_CLIC === '1')
        }
      >
        <Divider />
        <Footer>
          <RenderIf
            condition={
              !product.soldOut &&
              !product.menuItems?.length &&
              !product.subTemplateItems?.length &&
              !isLeadDaysExceeded
            }
          >
            <AmountHandler
              multiplier={getMultiplier(product.unitSellCode)}
              currentValue={currentAmount}
              onDecrease={setCurrentAmount}
              onIncrease={setCurrentAmount}
              step={getStep(product.unitSellCode)}
              minValue={product.ClickCollectOrderMin}
              maxValue={product.ClickCollectOrderMax}
            />
            <Button
              buttonType="common"
              disabled={isAddDisabled}
              width="100%"
              onClick={onAddClick}
            >
              {t('products.addOrder')}
            </Button>
          </RenderIf>
          <RenderIf
            condition={
              (!!product.menuItems?.length ||
                !!product.subTemplateItems?.length) &&
              !isLeadDaysExceeded
            }
          >
            <ButtonContainer>
              <Button
                buttonType="common"
                width="100%"
                onClick={() => onNext?.(selectedKitchenInfo)}
              >
                {t('products.compose')}
              </Button>
            </ButtonContainer>
          </RenderIf>
          <RenderIf condition={isLeadDaysExceeded}>
            <ButtonContainer>
              <Button buttonType="common" width="100%" disabled>
                {t('clickCollect.leadDaysExceeded', {
                  value: getDaysText(product?.ClickCollectLeadDaysCount),
                })}
              </Button>
            </ButtonContainer>
          </RenderIf>
        </Footer>
      </RenderIf>
    </>
  )
}
