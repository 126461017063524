import styled from 'styled-components'

export const TextAreaContainer = styled.div``

export const TextAreaStyled = styled.textarea`
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  width: 100%;
  min-height: 100px;
  padding: 10px;
  line-height: 1rem;
  margin: 10px 0 14px;
  outline: none;
  color: ${({ theme }) => theme.FontColor};
  background-color: ${({ theme }) => theme.FooterColor};
`
