// import { errorHandler } from '../components/ErrorHandler'

export const handlePromiseAll = async (functions: any[]): Promise<any> => {
  const result = await Promise.allSettled(functions.map((func) => func()))
  return result.map((el) => {
    if (el.status === 'rejected') {
      //   errorHandler(el.reason)
      return null
    } else {
      return el.value
    }
  })
}
