import { RenderIf } from '../../../components/RenderIf'
import { useMySelector } from '../../../hooks/useMySelector'
import { useOpenProductModal } from '../../../hooks/useOpenProductModal'
import { useTranslations } from '../../../hooks/useTranslations'
import { Discount } from './Discount'
import { Price } from './Price'

import {
  IDiscount,
  IIndivPrice,
} from '../../../store/reducers/SpecialsReducer/types'
import { CategoryItemText, Slider, SliderContainer } from './styled'

export const Specials = () => {
  const t = useTranslations()
  const handleProductClick = useOpenProductModal()

  const { categories } = useMySelector((state) => state)
  const { discounts, individualPrices } = useMySelector(
    (state) => state.specials
  )

  return (
    <>
      <RenderIf
        condition={
          (!!discounts?.length || !!individualPrices?.length) &&
          !!categories?.find((el) => el.products?.length)
        }
      >
        <CategoryItemText className="special_category">
          {t('common.promotions')}
        </CategoryItemText>
      </RenderIf>
      <SliderContainer>
        <Slider>
          {discounts?.map((discount: IDiscount) => (
            <Discount
              key={discount.productId}
              discount={discount}
              handleProductClick={handleProductClick}
            />
          ))}
          {individualPrices?.map((price: IIndivPrice) => (
            <Price
              key={price.productId}
              individualPrice={price}
              handleProductClick={handleProductClick}
            />
          ))}
        </Slider>
      </SliderContainer>
    </>
  )
}
