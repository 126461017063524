export const getPrice = (product: any) => {
  const betragValues = [
    product?.individualPrice,
    product?.discountPrice,
    product?.betrag2,
    product?.betrag,
    product?.betrag2E2,
    product?.betragE2,
    product?.betrag2E3,
    product?.betragE3,
    product?.betrag2E4,
    product?.betragE4,
    product?.betrag2E5,
    product?.betragE5,
    product?.betrag2E6,
    product?.betragE6,
    product?.betrag2E7,
    product?.betragE7,
    product?.betrag2E8,
    product?.betragE8,
    product?.betrag2E9,
    product?.betragE9,
    product?.betrag2E10,
    product?.betragE10,
  ]

  for (const value of betragValues) {
    if (typeof value === 'string' && parseFloat(value) !== 0) {
      return parseFloat(value) // Ignore string values of "0.000"
    }

    if (typeof value === 'number' && value !== 0) {
      return value // Return non-zero number
    }

    continue
  }

  return 0 // Default value if no valid price found
}
