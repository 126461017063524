import { FirebaseOptions } from 'firebase/app'

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyA-RH09v4ueJdABVPjU3Nu-R2om_dZGQBk',
  authDomain: 'click-and-collect-8e3cd.firebaseapp.com',
  projectId: 'click-and-collect-8e3cd',
  storageBucket: 'click-and-collect-8e3cd.appspot.com',
  messagingSenderId: '551176870649',
  appId: '1:551176870649:web:71829f09267ee408a92028',
  measurementId: 'G-RPFJG31T63',
}

export default firebaseConfig
