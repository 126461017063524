import styled from 'styled-components'

export const A2hsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 10px;
  background-color: ${({ theme }) => theme.FooterColor};
  padding: 10px;
  display: flex;
`

export const A2hsImage = styled.img`
  height: auto;
  width: auto;
  max-width: 90px;
  object-fit: contain;
  margin-right: 10px;
`

export const A2hsTitle = styled.p`
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.FontColor};
`

export const A2hsSubtitle = styled.p`
  margin: 0;
  margin-bottom: 35px;
  padding: 0;
  display: flex;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.FontColor};
`

export const A2hsArrow = styled.p`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  color: ${({ theme }) => theme.FontColor};
`
