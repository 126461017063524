import { FC } from 'react'
import { RenderIf } from '../RenderIf'
import { CheckBoxContainer, CheckBox_Box, CheckBox_Label } from './styled'

type CheckBoxProps = {
  isChecked: boolean
  onClick: (value: boolean) => void
  label?: string | JSX.Element
  isDisabled?: boolean
  checkedColor?: string
}

export const CheckBox: FC<CheckBoxProps> = ({
  label,
  isChecked,
  isDisabled = false,
  checkedColor,
  onClick,
  ...props
}) => {
  const handleClick = () => {
    if (!isDisabled) {
      onClick(!isChecked)
    }
  }
  return (
    <CheckBoxContainer onClick={handleClick}>
      <CheckBox_Box
        isChecked={isChecked}
        isDisabled={isDisabled}
        checkedColor={checkedColor}
        {...props}
      />
      <RenderIf condition={!!label}>
        <CheckBox_Label>{label}</CheckBox_Label>
      </RenderIf>
    </CheckBoxContainer>
  )
}
