import styled from 'styled-components'

type GridDivProps = {
  gc?: string
  gr?: string
}

export const GridDiv = styled('div')<GridDivProps>`
  grid-column: ${({ gc }) => gc || 'unset'};
  grid-row: ${({ gr }) => gr || 'unset'};
`
