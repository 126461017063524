import styled from 'styled-components'

type BranchItemType = {
  isSelected?: boolean
}

type RestaurantsCheckBoxProps = {
  isSelected?: boolean
}

type RestaurantsItemProps = {
  isCompany?: boolean
  isAvailable?: boolean
  isQRMenu?: boolean
}

// Register

export const BranchPage = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::after {
    content: unset;
  }
`

export const RegisterContainer = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colour};
`

export const BranchTitle = styled.p`
  height: 2rem;
  width: 100%;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.FontColor};
`

export const BranchItem = styled('p')<BranchItemType>`
  padding: 5vw;
  width: 100%;
  height: 20vw;
  background: #434343;
  box-shadow: 0px 0px 7px ${({ theme }) => theme.colour};
  font-weight: 600;
  font-size: 18px !important;
  text-align: center;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 5vw;
  }
  &::after {
    content: ${({ isSelected }) => (isSelected ? `'✓'` : 'unset')};
    position: absolute;
    bottom: 100%;
    left: 100%;
    transform: translate(-50%, 50%);
    background: #e32323;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    text-align: center;
    color: #fff;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// Table

export const TableInputContainer = styled.div`
  display: flex;
  border-radius: 5px;
  overflow-x: hidden;
  align-items: center;
  width: 80%;
  position: relative;
`

export const TableCaptionError = styled.p`
  color: #f00;
  height: 24px;
`

export const TableInput = styled.input`
  border: 0px;
  outline: none;
  height: 58px;
  width: calc(100% - 121px);
  border-radius: 0 5px 5px 0;
  text-align: center;
  font-size: 1.5rem;
  color: #3c3c32;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
`

export const TablePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`

export const NumberDiv = styled.span`
  background-color: #c4c4c4;
  color: #fff;
  height: 58px;
  width: 58px;
  text-align: center;
  line-height: 58px;
  font-size: 1.5rem;
`

export const OrJustHint = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.FontColor};
`

export const TableTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// TimePicker

export const InputCaption = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  color: #434343;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SelectContainer = styled.div`
  color: ${({ theme }) => theme.FontColor};
`

export const TimeButtonContainer = styled.div`
  margin-top: 44px;
`

// BranchSelect

export const RestaurantsCheckBox = styled('div')<RestaurantsCheckBoxProps>`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colour : theme.MainBackGround};
  border: 1px solid #cdcdcd;
  border-radius: 100%;
  &::after {
    content: '';
    background: ${({ isSelected }) => (isSelected ? '#fff' : 'unset')};
    position: absolute;
    height: 12px;
    width: 12px;
    top: 8px;
    left: 8px;
    border-radius: 100%;
  }
`
export const ButtonsContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
`

export const RestaurantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
  margin-top: 15px;
`

export const RestaurantsItem = styled('div')<RestaurantsItemProps>`
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  min-height: ${({ isCompany }) => (isCompany ? 'unset' : '117px')};
  flex-basis: 1;
  padding: 10px 10px;
  position: relative;
  margin-bottom: 10px;
  background-color: ${({ isAvailable }) =>
    isAvailable ? 'unset' : '#dddddd80'};
  display: ${({ isCompany, isQRMenu }) =>
    !isCompany && isQRMenu ? 'grid' : 'unset'};
  grid-template-columns: 95px 1fr;
  grid-gap: 15px;
`

export const RestaurantsTitle = styled.p`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.FontColor};
`

export const RestaurantsAddress = styled.p`
  color: ${({ theme }) => theme.FontColor};
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`

export const UnavailableSign = styled.p`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ff000080;
  max-width: 80px;
  font-size: 12px;
  text-align: right;
  line-height: 12px;
`
