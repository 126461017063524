import styled from 'styled-components'

type InputProps = {
  textColor?: string
}

type InputContainerProps = {
  height?: string
}

export const InputTitle = styled.p`
  margin: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.FontColor};
`

export const InputContainer = styled('div')<InputContainerProps>`
  margin: 10px;
  margin-top: 0;
  background: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
  border: ${({ theme }) => `1px solid ${theme.colour}`};
  height: ${({ height }) => height || '40px'};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 10px;
  overflow: hidden;
`

export const InputStyled = styled('input')<InputProps>`
  width: 100%;
  border: 0px;
  outline: none;
  padding: 0;
  color: ${({ theme }) => theme.FontColor};
  caret-color: ${({ theme }) => theme.FontColor};
  background: ${({ theme }) => theme.ItemsBackGround};
  &::placeholder {
    color: ${({ theme }) => theme.FontColor};
  }
`
