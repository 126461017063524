/* eslint-disable */

import moment from 'moment'
// @ts-ignore
import de from 'moment/locale/de'
// @ts-ignore
import en from 'moment/locale/en-gb'
// @ts-ignore
import fr from 'moment/locale/fr'
// @ts-ignore
import it from 'moment/locale/it'
import { ILanguage } from '../store/reducers/CommonReducer/types'

export const updateMomentLanguage = (lang: ILanguage) => {
  switch (lang) {
    case 'en':
      moment.updateLocale('en', en)
      break
    case 'fr':
      moment.updateLocale('fr', fr)
      break
    case 'it':
      moment.updateLocale('it', it)
      break
    default:
      moment.updateLocale('de', de)
      break
  }
}
