import styled from 'styled-components'

type TimeLocationProps = {
  customTop: string
}

type TimeLocation_ItemProps = {
  isWide: boolean
}

export const TimeLocation_Container = styled('div')<TimeLocationProps>`
  padding: 10px 3vw;
  z-index: 2;

  background: ${({ theme }) => theme.MainBackGround};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  scroll-margin-top: 140px;

  position: fixed;
  top: ${({ customTop }) => customTop};
  transition: all 0.4s ease;
  width: 100%;
`

export const TimeLocation_ContainerInner = styled.div`
  height: 101px;

  box-sizing: content-box;
  border: ${({ theme }) => {
    if (theme.appTheme === 'light') {
      return `1px solid ${theme.FontColor}`
    } else {
      return `1px solid ${theme.ItemsBackGround}`
    }
  }};
  border-radius: 5px;

  background-color: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colour};

  z-index: 2;
  width: 100%;
`

export const TimeLocation_Item = styled.div<TimeLocation_ItemProps>`
  width: ${({ isWide }) => `calc(100% - ${isWide ? '0px' : '75px'})`};
  display: flex;
  flex-direction: column;
`

export const ItemContainer = styled.div`
  width: 100%;
  height: 50px;
  padding: 5px 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  &:nth-child(1)::after {
    content: '';
    width: 65px;
    background-color: #cdcdcd;
    /* border-bottom: 1px solid #cdcdcd; */
    border-bottom: ${({ theme }) => `1px solid ${theme.FontColor}`};
    position: absolute;
    bottom: 0;
    left: 10px;
  }
`

export const AddressP = styled.p`
  line-height: 14px;
  font-size: 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1 !important; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
`

export const ImageContainer = styled.div`
  min-width: 21px;
  max-width: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

export const EditButton = styled.div`
  width: 75px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* border-left: 1px solid #cdcdcd; */
  border-left: ${({ theme }) => `1px solid ${theme.FontColor}`};
`

export const SubItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SubItemTitle = styled.p`
  font-weight: 600;
  font-size: 13px;
`
