import styled from 'styled-components'

export const PaymentProductsContainer = styled.div`
  overflow-y: scroll;
  padding: 0 3vw;
  height: max-content;
  flex-grow: 1;
`

export const TableDiv = styled.div`
  height: 50px;
  width: 100%;
  text-align: center;

  color: ${({ theme }) => theme.FontColor};

  font-weight: 600;
  font-size: 18px !important;
  line-height: 18px;
  padding-top: 16px;
`

export const SignUpButton = styled.p`
  gap: 10px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin: 10px 0;
  align-items: center;
`

export const SignUpDecorated = styled.span`
  text-decoration: underline;
`

export const SignUpDescription = styled.p`
  text-align: justify;
  font-size: 13px;
  margin: 10px 0;
`
