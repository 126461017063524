import { toast } from 'react-toastify'
import { useOpenModal } from '../../hooks/useOpenModal'
import { useTranslations } from '../../hooks/useTranslations'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import history from '../../utils/history'
import BranchPicker from './BranchPicker'
import GUIDPicker from './GUIDPicker'
import TimePicker from './TimePicker'

export const usePromptParams = () => {
  const t = useTranslations()
  const openModal = useOpenModal()

  const openPromptModal = (
    loadApp: () => void,
    neededSteps = ['guid', 'time', 'branch'],
    shouldHideCross = false
  ) => {
    const onClose = async (closeSearchParams: () => void) => {
      const guid = handleLocalStorage('guid')
      if (guid) {
        closeSearchParams()
        await loadApp()
        if (location.pathname === '/welcome') {
          history.replace('/')
        }
      } else {
        toast.error(t('errors.tryScanner'))
      }
    }

    openModal({
      id: 'SEARCH_PARAMS_PROMPT',
      title: ``,
      shouldHideCross,
      components: [
        ...(neededSteps.includes('guid')
          ? [
              ({ onNext, closeThisModal }: any) => (
                <GUIDPicker
                  increaseStep={() => {
                    if (
                      neededSteps.includes('time') ||
                      neededSteps.includes('branch')
                    ) {
                      onNext()
                    } else {
                      onClose(closeThisModal)
                    }
                  }}
                />
              ),
            ]
          : []),
        ...(process.env.REACT_APP_IS_CLIC === '1' &&
        neededSteps.includes('time')
          ? [
              ({ onNext, closeThisModal }: any) => (
                <TimePicker
                  increaseStep={() => {
                    if (neededSteps.includes('branch')) {
                      onNext()
                    } else {
                      onClose(closeThisModal)
                    }
                  }}
                />
              ),
            ]
          : []),
        ...(neededSteps.includes('branch')
          ? [
              ({ closeThisModal }: any) => (
                <BranchPicker increaseStep={() => onClose(closeThisModal)} />
              ),
            ]
          : []),
      ],
    })
  }

  return openPromptModal
}
