import styled from 'styled-components'

export const NotificationContainer = styled.div`
  min-height: calc(35vh - 50px - 20px - 25px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const WaiterCallPrompt = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.FontColor};
  font-size: 20px;
  padding: 0 25px;
`

export const Notification = styled.div`
  height: 70px;
  max-height: 70px;
  min-width: 125px;
  border-radius: 5px;
  position: relative;
  background: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
  border: ${({ theme }) => `1px solid ${theme.ItemsBorders}`};
  //
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

export const NotificationIconContainer = styled.div`
  height: 70px;
  width: 70px;

  min-width: 70px;
  max-width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const DateTimeContainer = styled.div`
  height: 70px;
  width: 70px;

  min-width: 70px;
  max-width: 70px;

  padding: 10px;

  color: #979797;
  font-size: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`

export const MessagesContainer = styled.div`
  max-height: calc(85vh - 25px - 58px - 25px - 20px);
  overflow-y: scroll;
  padding-bottom: 25px;
  position: relative;
`

export const MessageContainer = styled.div`
  height: 70px;
  flex-grow: 1;
  /* background: red; */
  overflow: hidden;
`

export const Divider = styled.div`
  width: 100%;
  height: 25px;
  background-image: linear-gradient(
    transparent,
    ${({ theme }) => theme.MainBackGround}
  );

  position: absolute;
  bottom: 100%;
  left: 0;
`

export const MessageTitle = styled.div`
  height: 20px;

  margin-top: 2px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const MessageText = styled.div`
  height: 40px;
  margin: 4px 0;

  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const ButtonContainer = styled.div`
  /* margin-top: 25px; */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  position: relative;
`
