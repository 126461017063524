import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, ISelectedProduct, IUpdateAmount } from './types'

export const SelectedProductsSlice = createSlice({
  name: 'selectedProductsSlice',
  initialState,
  reducers: {
    addSelectedProduct(state, action: PayloadAction<ISelectedProduct>) {
      const doesExist = state.find((product) => {
        return product.uniqueId === action.payload.uniqueId
      })
      if (doesExist && action.payload.menuItems) {
        return (state = [
          ...state.map((el) => {
            if (el.uniqueId === action.payload.uniqueId) {
              return { ...el, amount: el.amount + 1 }
            }
            return el
          }),
        ])
      }
      if (doesExist && !action.payload.menuItems) {
        return (state = [
          ...state.filter(
            (el) => !doesExist || !(el.uniqueId === action.payload.uniqueId)
          ),
          ...(action.payload.amount ? [action.payload] : []),
        ])
      }
      if (!doesExist) {
        return (state = [
          ...state,
          ...(action.payload.amount ? [action.payload] : []),
        ])
      }
    },
    updateSelectedProductAmount(state, action: PayloadAction<IUpdateAmount>) {
      return (state = [
        ...state.map((el) => {
          if (el.uniqueId === action.payload.uniqueId) {
            return { ...el, amount: action.payload.amount }
          }
          return el
        }),
      ])
    },
    discardOneSelectedProduct(state, action: PayloadAction<string>) {
      return (state = [...state.filter((el) => el.uniqueId !== action.payload)])
    },
    discardSelectedProducts(state) {
      return (state = [])
    },
  },
})

export default SelectedProductsSlice.reducer
