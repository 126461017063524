export const convertToPrice = (amount?: number | string): string => {
  let number = parseFloat(`${amount}`)
  if (!isFinite(number)) number = 0.0
  let minus = ''
  if (number < 0) minus = '-'
  number = Math.abs(number)
  number = parseInt(`${(number + 0.005) * 100}`) / 100
  let result = new String(number)
  if (result.indexOf('.') < 0) result += '.00'
  if (result.indexOf('.') == result.length - 2) result += '0'
  result = minus + result
  return result.toString()
}
