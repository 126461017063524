import { FC } from 'react'
import { Button } from '../../../components/Button'
import { useTranslations } from '../../../hooks/useTranslations'
import { AskWaiterModalContainer } from './styled'
import { useMySelector } from '../../../hooks/useMySelector'

type AskWaiterModalProps = {
  onClose: () => void
}

export const AskWaiterModal: FC<AskWaiterModalProps> = ({ onClose }) => {
  const t = useTranslations()
  const { paymentVariant } = useMySelector((state) => state.app.menu)

  return (
    <AskWaiterModalContainer>
      <h2>
        {paymentVariant === 1
          ? t('mainPage.askWaiterBeforeMeal')
          : t('mainPage.askWaiterAfterMeal')}
      </h2>
      <Button buttonType="common" width="100%" onClick={onClose}>
        {t('buttons.ok')}
      </Button>
    </AskWaiterModalContainer>
  )
}
