import { useSearchParams } from 'react-router-dom'
import { useOpenModal } from './useOpenModal'
import { useMyDispatch } from './useMyDispatch'
import { useTranslations } from './useTranslations'
import { NoReceiptModal } from '../components/ReceiptModal/NoReceiptModal'
import { SelectedProductsSlice } from '../store/reducers/SelectedProductsReducer'

export type useOpenNoReceiptModalType = (numberToDisplay?: string) => void

export const useOpenNoReceiptModal = (): useOpenNoReceiptModalType => {
  // Hooks
  const dispatch = useMyDispatch()
  const openModal = useOpenModal()
  const t = useTranslations()

  // Store
  const { discardSelectedProducts } = SelectedProductsSlice.actions

  // State
  const [searchParams, setSearchParams] = useSearchParams()

  // Functions
  const openNoReceiptModal = (numberToDisplay?: string) => {
    openModal({
      id: 'NO_RECEIPT_MODAL',
      title: t('order.toMenu'),
      components: [
        ({ closeThisModal }) => (
          <NoReceiptModal
            paymentNumberProps={numberToDisplay}
            onCloseModal={async () => {
              closeThisModal()
              searchParams.delete('payment_success_nr')
              searchParams.delete('datatransTrxId')
              searchParams.delete('order_guid')
              setSearchParams(searchParams)
              clearLocalStorage()
              dispatch(discardSelectedProducts())
            }}
          />
        ),
      ],
      onClose: () => {
        searchParams.delete('payment_success_nr')
        searchParams.delete('datatransTrxId')
        searchParams.delete('order_guid')
        setSearchParams(searchParams)
        clearLocalStorage()
        dispatch(discardSelectedProducts())
      },
    })
  }

  const clearLocalStorage = () => {
    window.localStorage.removeItem('products_notes')
    window.localStorage.removeItem('pickUpDelay')
    window.localStorage.removeItem('pickUpTime')
  }

  return openNoReceiptModal
}
