import styled from 'styled-components'

type ItemProps = {
  isSelected: boolean
  count?: number
}

export const ItemsContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  max-height: calc(100vh - 334px);
  margin-bottom: 10px;
`

export const ItemsGrid = styled.div`
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 120px;
  grid-gap: 10px;
  position: relative;
  &:after {
    content: unset;
  }
`

export const ItemsGridExpander = styled.div`
  position: absolute;
  background-image: linear-gradient(
    transparent,
    ${({ theme }) => theme.MainBackGround}
  );
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: unset;
  }
`

export const MainTitle = styled.div`
  text-align: center;
  margin: 10px 0;
  color: ${({ theme }) => `${theme.FontColor}`};
`

export const ItemTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  height: 40px;
  display: flex;
  align-items: center;
`

export const Item = styled('div')<ItemProps>`
  height: 120px;
  border: ${({ theme, isSelected }) =>
    isSelected
      ? `1px solid ${theme.colour || '#34CB9E'}`
      : `1px solid ${theme.ItemsBorders}`};

  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;

  background-color: ${({ theme }) => theme.ItemsBackGround};

  &:after {
    content: ${({ count }) => (count ? `'${count}'` : 'unset')};
    color: #fff;
    font-size: 12px !important;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colour};
    height: 25px;
    width: 25px;
    border-radius: 0 0 0 13px;
    text-align: center;
    line-height: 25px;
  }
  p {
    margin: 0;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 18px;
    color: #434343;
    text-align: center;
  }
`

export const ItemImageContainer = styled.div`
  height: 60px;
  min-height: 60px;
  width: 60px;
  position: relative;
  box-sizing: border-box;
  background: #b0b0b0;
  border-radius: 100%;
  overflow: hidden;
  margin: 12px 0 0;
`

export const ItemImage = styled.img`
  height: 100%;
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
`

export const ItemPrice = styled.p`
  color: ${({ theme }) => `${theme.FontColor} !important`};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`
