import { ChangeEvent, FC, useEffect, useState } from 'react'
import { TextAreaContainer, TextAreaStyled } from './styled'

type TExtAreaProps = {
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  [key: string]: any
}

export const TextArea: FC<TExtAreaProps> = ({
  onChange,
  defaultValue,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    setCurrentValue(defaultValue)
  }, [defaultValue])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentValue(e.currentTarget.value)
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <TextAreaContainer>
      <TextAreaStyled onChange={handleChange} value={currentValue} {...props} />
    </TextAreaContainer>
  )
}
