import { useState, useEffect } from 'react'
import { Button } from '../../../components/Button'
import { RenderIf } from '../../../components/RenderIf'
import { useMySelector } from '../../../hooks/useMySelector'
import { useTranslations } from '../../../hooks/useTranslations'

import {
  A2hsContainer,
  A2hsImage,
  A2hsTitle,
  A2hsArrow,
  A2hsSubtitle,
} from './styled'

export const A2hs = () => {
  const t = useTranslations()
  const iOS = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase())
  const standalone =
    'standalone' in window.navigator && window.navigator.standalone

  const handleMessageClick = () => {
    window.localStorage.setItem('a2hs_message', 'hide')
  }

  const { ContractorName } = useMySelector((state) => state.app.companyData)
  const { appIcon, logoImage } = useMySelector(
    (state) => state.app.interfaceStyles
  )
  const [supportsPWA, setSupportsPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState(null)

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault()
      setSupportsPWA(true)
      setPromptInstall(e)
    }
    window.addEventListener('beforeinstallprompt', handler)

    return () => {
      window.removeEventListener('beforeinstallprompt', handler)
      window.removeEventListener('transitionend', handler)
    }
  }, [])
  const onInstallClick = (evt) => {
    evt.preventDefault()
    promptInstall.prompt()
  }

  return (
    <RenderIf
      condition={
        !standalone &&
        !window.localStorage.a2hs_message &&
        (iOS || supportsPWA) &&
        process.env.REACT_APP_IS_CLIC === '1'
      }
    >
      <A2hsContainer onClick={handleMessageClick}>
        <A2hsImage
          src={appIcon || logoImage}
          alt="logo"
          height="90"
          width="90"
        />
        <div>
          <A2hsTitle>
            {t('a2hs.install', { companyName: ContractorName || '' })}
          </A2hsTitle>
          <RenderIf condition={!iOS}>
            <Button
              buttonType="common"
              height="2.5rem"
              width="100%"
              onClick={onInstallClick}
            >
              {t('common.installApp')}
            </Button>
          </RenderIf>
          <RenderIf condition={iOS}>
            <A2hsSubtitle>{t('a2hs.subtitle')}</A2hsSubtitle>
            <A2hsArrow>↓</A2hsArrow>
          </RenderIf>
        </div>
      </A2hsContainer>
    </RenderIf>
  )
}
