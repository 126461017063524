import { useEffect, useState } from 'react'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import {
  ButtonContainer,
  DateTimeContainer,
  Divider,
  MessageContainer,
  MessageText,
  MessageTitle,
  MessagesContainer,
  Notification,
  NotificationContainer,
  NotificationIconContainer,
} from './styles'
import { Button } from '../../../components/Button'
import notificationIcon from '../../../assets/noteIcon.svg'
import { useTranslations } from '../../../hooks/useTranslations'
import { subscribeForNotifications } from '../../../utils/webPush/initializeState'
import { errorHandler } from '../../../components/ErrorHandler'
import { RenderIf } from '../../../components/RenderIf'
import { Spinner } from '../../../components/Spinner'
import moment, { Moment } from 'moment'

export const ClickCollectNotificationsModal = () => {
  const t = useTranslations()

  const [isDataLoading, setDataLoading] = useState<boolean>(true)
  const [notificationsList, setNotificationsList] = useState<any[]>([])
  const [isPermissionLoading, setIsPermissionLoading] = useState<boolean>(false)

  useEffect(() => {
    getNotifications()
  }, [])

  const getNotifications = async () => {
    try {
      const result = await qrMenuGET.notifications()
      setNotificationsList(result)
    } catch (e) {
      errorHandler(e)
    } finally {
      setDataLoading(false)
    }
  }

  const requestAllowPushNotifications = async () => {
    if (!isPermissionLoading) {
      try {
        setIsPermissionLoading(true)
        const permission = await window?.Notification?.requestPermission?.()
        if (permission === 'granted') {
          await subscribeForNotifications()
        }
      } catch (e) {
        errorHandler(e)
      } finally {
        setIsPermissionLoading(false)
      }
    }
  }

  const getDate = (date: Moment) => {
    if (date.isBefore(moment(date).startOf('year'))) {
      return date.format('MMM YY')
    } else if (date.isBefore(moment(date).startOf('week'))) {
      return date.format('DD MMM')
    } else {
      return date.format('ddd HH:mm')
    }
  }

  return (
    <NotificationContainer>
      <RenderIf condition={isDataLoading}>
        <Spinner type="alternate" />
      </RenderIf>
      <RenderIf condition={!isDataLoading}>
        <MessagesContainer>
          {notificationsList
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((currentNotification, i) => {
              return (
                <Notification key={`${currentNotification.title}_${i}`}>
                  <NotificationIconContainer>
                    <img src={notificationIcon} alt="+" />
                  </NotificationIconContainer>
                  <MessageContainer>
                    <MessageTitle>{currentNotification.title}</MessageTitle>
                    <MessageText>{currentNotification.message}</MessageText>
                  </MessageContainer>
                  <DateTimeContainer>
                    {getDate(moment(currentNotification.createdAt))}
                  </DateTimeContainer>
                </Notification>
              )
            })}
        </MessagesContainer>
      </RenderIf>
      <ButtonContainer>
        <Divider />
        <Button
          buttonType="common"
          width="100%"
          onClick={requestAllowPushNotifications}
          isLoading={isPermissionLoading}
        >
          {t('sidebar.pushNotifications')}
        </Button>
      </ButtonContainer>
    </NotificationContainer>
  )
}
