import styled from 'styled-components'

export const FooterStyled = styled.div`
  padding: 10px 20px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 50px;
  grid-gap: 10px;

  background-color: ${({ theme }) => theme.FooterColor};
  color: ${({ theme }) => theme.FontColor};

  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-top: ${({ theme }) => `1px solid ${theme.ItemBorder}`};
  height: 120px;
  width: 100%;
  & button {
    height: 50px !important;
  }
`
