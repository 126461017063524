import styled from 'styled-components'

export const OrderModalContainer = styled.div`
  display: grid;
  grid-template-columns: 124px 1fr;
  grid-template-rows: 85px 58px;
  grid-gap: 35px;
  height: 100%;
  margin-top: 12px;
  color: ${({ theme }) => theme.FontColor};
  h2 {
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    padding-bottom: 9px;
  }
  p {
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: center;
  }
`

export const ErrorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 24px 1fr;
  margin-top: 12px;
  grid-gap: 15px;
  justify-items: center;
  color: ${({ theme }) => theme.FontColor};
`

export const MWErrorContainer = styled(ErrorContainer)`
  justify-content: space-between;
  height: 100%;
  & div {
    width: 100%;
  }
`
