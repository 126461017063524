import { FC } from 'react'
import ScannerComponent from 'react-qr-barcode-scanner'
import { Spinner } from '../Spinner'
import {
  ScannerContainer,
  ScannerHeader,
  ScannerTitle,
  ScannerSubTitle,
  ScannerComponentContainer,
} from './styled'

type ScanProps = {
  title: string
  subtitle: string
  onScan: (string: string) => void
}

export const Scanner: FC<ScanProps> = ({ title, subtitle, onScan }) => {
  //
  // Functions
  const handleScan = (string: string) => {
    onScan(string)
  }

  return (
    <>
      <ScannerContainer>
        <ScannerHeader>
          <ScannerTitle>{title}</ScannerTitle>
          <ScannerSubTitle>{subtitle}</ScannerSubTitle>
        </ScannerHeader>
        <div
          style={{
            height: 'calc(100vw - 40px)',
            width: 'calc(100vw - 40px)',
          }}
        >
          <Spinner type="alternate" />
          <ScannerComponentContainer>
            <ScannerComponent
              width="100%"
              height="100%"
              videoConstraints={{
                aspectRatio: 1,
                facingMode: 'environment',
              }}
              delay={1000}
              onUpdate={(err: any, result: any) => {
                if (result) {
                  handleScan(result.text)
                } else {
                  console.log('Error:', err)
                }
              }}
            />
          </ScannerComponentContainer>
        </div>
      </ScannerContainer>
    </>
  )
}
