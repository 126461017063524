import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, ORANGE_COLOR } from './types'

export const AppSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    fetchAppData(state = initialState, action: PayloadAction<any>) {
      return (state = {
        ...state,
        isAppInit: action.payload.isAppInit,
        menu: action.payload.menu || state.menu,
        address: action.payload.address || state.address,
        companyData: action.payload.companyData || state.companyData,
        interfaceStyles:
          action.payload.interfaceStyles || state.interfaceStyles,
        clickCollectConfig:
          action.payload.clickCollectConfig || state.clickCollectConfig,
        tableReservationConfig:
          action.payload.tableReservationConfig || state.tableReservationConfig,
      })
    },
    setIsProductsLoaded(state = initialState, action: PayloadAction<boolean>) {
      return (state = {
        ...state,
        isProductsLoaded: action.payload,
      })
    },
    setIsPaymentAvailable(
      state = initialState,
      action: PayloadAction<boolean>
    ) {
      return (state = {
        ...state,
        isPaymentAvailable: action.payload,
      })
    },
    updateColor(state = initialState) {
      return (state = {
        ...state,
        interfaceStyles: {
          ...state.interfaceStyles,
          colour: ORANGE_COLOR,
        },
      })
    },
    updateLastActiveCategoryId(
      state = initialState,
      action: PayloadAction<string>
    ) {
      return (state = {
        ...state,
        lastActiveCategoryId: action.payload,
      })
    },
  },
})

export default AppSlice.reducer
