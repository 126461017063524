import { FC } from 'react'

export const initialState: IModal[] | null = null

export type ModalProps = {
  onPrev: (props?: any) => void
  onNext: (props?: any) => void
  closeThisModal: () => void
}

export type IModal = {
  id: string
  components: FC<ModalProps>[]
  title?: string
  onClose?: (id: string) => void
  shouldHideCross?: boolean
  startIndex?: number
}
