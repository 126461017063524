import { IAllergen } from '../CommonReducer/types'

export const initialState: ICategory[] = []

export interface IFetchCategoriesData {
  guid: string
  registerId: string | number
  search?: string
}

export interface ICategory {
  id: number
  IsDi: 1 | 0
  IsDo: 1 | 0
  IsFr: 1 | 0
  IsMi: 1 | 0
  IsMo: 1 | 0
  IsSa: 1 | 0
  IsSo: 1 | 0
  IsVisible: 1 | 0
  categoryId: number
  color: number | string
  imageUrl: string
  isNoAction: 1 | 0
  linkMaster: 1 | 0
  linkPrice: 1 | 0
  name: string
  pageIndex: number
  products: IProduct[]
  sortId: 1 | 0
  validAfter: string
  visibleProductsCount: number
}
export interface IProduct {
  EANCode: string
  allergenLabels: string
  allergens: string
  betrag: number
  betrag2: number
  betragE3?: number
  betrag2E3?: number
  individualPrice?: number
  discountPrice?: number
  categoryId: number
  diatItems: string
  id: number
  itemId?: number
  imageUrl: string
  isActive: 1 | 0
  linkRA?: number
  kitchenInfo: IKitchenInfo[] | string
  menuItems: IMenuItem[]
  note: string
  registerId: string
  title: string
  unitSellCode: 1 | 2 | 3
  youthProtectionValue?: '0' | '1' | '2'
  //
  amount?: number
  selectedKitchenInfo?: string
  uniqueId?: string
  underTemplateId?: number
  subTemplateItems: ITemplateItem[]
  productTimeSlot: number | null
  ClickCollectOrderMin?: number
  ClickCollectOrderMax?: number
  ClickCollectLeadDaysCount?: number
  soldOut?: boolean
  manualSortIdx?: number
}

export interface ITemplateItem {
  EANCode: number | null
  allergenLabels: string | null
  allergens: IAllergen[] | null
  betrag: number
  betrag2: number
  categoryId: number
  diatItems: any
  id: number
  imageUrl: string
  isActive: number
  kitchenInfo: IKitchenInfo[] | null
  productTimeSlot: number | null
  registerId: string
  title: string
  underTemplateId: number
  unitSellCode: number
  visibleStatus: number
}

export interface IMenuItem {
  categoryId: number
  categoryName: string
  items: IMenuItem_Item[]
  maxCount: number
  minCount: number
  priceLevel: number
}

interface IMenuItem_Item {
  categoryId: number
  count: number
  imageUrl: string
  kitchenInfo: IKitchenInfo[]
  name: string
  price: number
  productId: number
}

export interface IKitchenInfo {
  title: string
  values: string[]
}

export interface IKitchenTimeSlot {
  name: string
  schedule: IKitchenSchedule[]
  timeSlotCode: number
}

interface IKitchenSchedule {
  day: number
  timeStart: string
  timeEnd: string
}
