import styled from 'styled-components'

type HeaderProps = {
  isTopScrolled: boolean
}

type WishListContainerProps = {
  count: number
  withMargin: boolean
}

export const HeaderStyled = styled('div')<HeaderProps>`
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: ${({ isTopScrolled }) => (isTopScrolled ? 0 : 'max(-14vw,-75px);')};
  transition: all 0.4s ease;
  display: flex;
  height: 70px;
  /* font-size: 50px; */
  justify-content: space-between;
  background-color: #434343;
  padding: 0 20px;
  align-items: center;
`

export const HeaderLogo = styled.img`
  height: 50%;
  object-fit: contain;
`

export const TableCaption = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 44px;
  width: 40px;
  justify-content: flex-end;
`

export const TableCaptionNumber = styled.div`
  margin: 0;
  font-size: 30px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 5px;
  width: 40px;
  text-align: center;
  /* overflow: hidden; */
  img {
    height: 100%;
  }
`

export const TableCaptionText = styled.p`
  margin: 0;
  font-size: 9px;
  height: 9px;
  line-height: 9px;
  white-space: nowrap;
`

export const WishListContainer = styled('div')<WishListContainerProps>`
  position: absolute;
  right: 80px;
  right: ${({ withMargin }) => (withMargin ? '80px' : '20px')};
  display: flex;
  align-items: center;
  &::after {
    content: ${({ count }) => (count ? `'${count}'` : 'unset')};
    position: absolute;
    font-size: 9px;
    height: 15px;
    line-height: 9px;
    color: #fff;
    padding: 3px 5px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colour};
    bottom: 0;
    right: 0;
    transform: translate(25%, 0);
  }
`
