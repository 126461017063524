import styled from 'styled-components'

type ScannerContainerProps = {
  isInModal: boolean
}

export const ScannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.MainBackGround};
  height: calc(105vw + 67px);
  position: relative;
`

export const ScannerHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  background-color: ${({ theme }) => theme.MainBackGround};
  color: ${({ theme }) => theme.FontColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`

export const ScannerTitle = styled.div`
  font-weight: 800;
  font-size: 28px;
  line-height: 40px;
  padding: 30px 0;
  padding-top: 10px;
`

export const ScannerSubTitle = styled.span`
  color: #cdcdcd;
  line-height: 25px;
  padding-bottom: 10px;
`

export const ScannerComponentContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
