export const initialState: ISelectedProduct[] = []

export type ISelectedProduct = {
  id: number
  uniqueId: string
  selectedKitchenInfo: string
  amount: number
  addedAsWeight: boolean
  menuItems?: any[]
}

export type IUpdateAmount = {
  uniqueId: string
  amount: number
}
