import { useMyDispatch } from './useMyDispatch'
import { useMySelector } from './useMySelector'
import { ModalsSlice } from '../store/reducers/ModalsReducer'
import { IModal } from '../store/reducers/ModalsReducer/types'

export type OpenModalFuncType = (params: IModal) => void

export const useOpenModal = (): OpenModalFuncType => {
  const { modals } = useMySelector((state) => state)
  const dispatch = useMyDispatch()

  const { openModal: openModalAction, closeModal } = ModalsSlice.actions

  const handleClose = (id: string, onClose?: (id: string) => void) => {
    if (onClose) {
      onClose(id)
    }
    dispatch(closeModal(id))
  }

  const openModal = ({
    id,
    components,
    title = '',
    onClose,
    shouldHideCross,
    startIndex,
  }: IModal) => {
    dispatch(
      openModalAction({
        components,
        id,
        title,
        shouldHideCross,
        onClose: () => handleClose(id, onClose),
        startIndex,
      })
    )
    return modals?.length
  }
  return openModal
}
