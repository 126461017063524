export const initialState: Translations = {
  isTranslationsLoaded: false,
  de: {},
  en: {},
  fr: {},
  it: {},
}

export type Translations = {
  isTranslationsLoaded?: boolean
  de: TranslationInnerType
  en: TranslationInnerType
  fr: TranslationInnerType
  it: TranslationInnerType
}

type TranslationInnerType = {
  [key: string]: any
}
