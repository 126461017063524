import { FC, useEffect, useState } from 'react'
import { RenderIf } from '../../../components/RenderIf'
import { useTranslations } from '../../../hooks/useTranslations'
import { SplitProducts } from '../../../components/Products/CustomProductsLists/SplitProducts'

import { ProductToPay } from '../types'
import { IProduct } from '../../../store/reducers/CategoriesReducer/types'
import { CheckboxContainer, SubFooter, TableDiv } from '../styled'
import { useOpenModal } from '../../../hooks/useOpenModal'
import {
  PaymentProductsContainer,
  SignUpButton,
  SignUpDecorated,
  SignUpDescription,
} from '../../../components/Cart.tsx/styled'
import { AuthModal } from '../../MainScreen/AuthModal'
import { isAuth } from '../../../utils/isAuth'
import { useMySelector } from '../../../hooks/useMySelector'
import { TermsAndConditionsCheckbox } from '../../TermsConditions/checkBox'

type SplitType = {
  products?: IProduct[]
  productsToPay: ProductToPay[]
  setProductsToPay: (products: any) => void
  currentSelectedProductId: number
  setCurrentSelectedProductId: (id: number) => void
  setIsPaymentAvailable: (value: boolean) => void
}

export const Split: FC<SplitType> = ({
  products,
  productsToPay,
  setProductsToPay,
  currentSelectedProductId,
  setCurrentSelectedProductId,
  setIsPaymentAvailable,
}) => {
  const t = useTranslations()
  const openModal = useOpenModal()

  const { paymentVariant, loginAvailable } = useMySelector(
    (state) => state.app.menu
  )

  const [isUserAuth, setIsUserAuth] = useState<boolean>(true)
  const [isAVGConfirmed, setIsAVGConfirmed] = useState<boolean>(false)

  const handleProductClick = (product: IProduct) => {
    const { itemId, id: productId, amount } = product
    const clickedFromToPay = productsToPay?.find(
      (product) => product.itemId === (itemId || productId)
    )
    if (clickedFromToPay) {
      setCurrentSelectedProductId(clickedFromToPay.itemId)
    }
    if (!clickedFromToPay) {
      setCurrentSelectedProductId(itemId || productId)
      setProductsToPay((prevProducts: ProductToPay[]) => [
        ...prevProducts,
        { itemId: itemId || productId, count: amount, maxAmount: amount },
      ])
    }
  }

  useEffect(() => {
    // if (paymentVariant === 1 && location.pathname === '/payment') {
    if (location.pathname === '/payment') {
      checkIsAuth()
    }
  }, [paymentVariant])

  useEffect(() => {
    if (isUserAuth) {
      setIsPaymentAvailable?.(true)
    } else if (isAVGConfirmed) {
      setIsPaymentAvailable?.(true)
    } else {
      setIsPaymentAvailable?.(false)
    }
  }, [isUserAuth, isAVGConfirmed])

  const getIsSelected = (product: IProduct) => {
    if (product.itemId) {
      return product.itemId === currentSelectedProductId
    }
    return product.id === currentSelectedProductId
  }

  const checkIsAuth = async () => {
    const res = await isAuth()
    setIsUserAuth(res)
  }

  const openSignupModal = () => {
    openModal({
      id: 'AUTH_MODAL',
      title: '',
      components: [
        () => <AuthModal onLogin={checkIsAuth} initialPage="signup" />,
      ],
    })
  }

  return (
    <>
      <TableDiv>{t('order.split')}</TableDiv>
      <PaymentProductsContainer>
        <RenderIf condition={!!products?.length}>
          <SplitProducts
            products={products as IProduct[]}
            onProductClick={handleProductClick}
            getIsSelected={getIsSelected}
            altProductsCounts={productsToPay}
          />
        </RenderIf>
      </PaymentProductsContainer>
      <RenderIf condition={!isUserAuth && location.pathname === '/payment'}>
        <SubFooter>
          <CheckboxContainer>
            <TermsAndConditionsCheckbox
              isChecked={isAVGConfirmed}
              handleCheck={setIsAVGConfirmed}
            />
          </CheckboxContainer>
        </SubFooter>
        <RenderIf condition={loginAvailable}>
          <SubFooter onClick={openSignupModal}>
            <SignUpButton>
              <span>{t('scanner.orJust')}</span>
              <SignUpDecorated>
                {t('auth.signUp')?.toLowerCase()}*
              </SignUpDecorated>
            </SignUpButton>
            <SignUpDescription>{t('auth.signUpAdvantages')}</SignUpDescription>
          </SubFooter>
        </RenderIf>
      </RenderIf>
    </>
  )
}
