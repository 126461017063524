import { ILanguage } from '../../../store/reducers/CommonReducer/types'
import { handleLocalStorage } from '../../../utils/handleLocalStorage'
import { CommonSlice } from '../../../store/reducers/CommonReducer'
import { useMyDispatch } from '../../../hooks/useMyDispatch'
import { useMySelector } from '../../../hooks/useMySelector'

import { LanguagesContainer, Language } from './styled'

export const Languages = () => {
  const dispatch = useMyDispatch()
  const { selectedLanguage } = useMySelector((state) => state.common)
  const { setSelectedLanguage } = CommonSlice.actions

  const updateSelectedLanguage = (lang: ILanguage) => {
    handleLocalStorage({ lang })
    dispatch(setSelectedLanguage(lang))
  }

  return (
    <LanguagesContainer>
      <Language
        isSelected={selectedLanguage === 'de'}
        onClick={() => updateSelectedLanguage('de')}
      >
        DE
      </Language>
      <Language
        isSelected={selectedLanguage === 'fr'}
        onClick={() => updateSelectedLanguage('fr')}
      >
        FR
      </Language>
      <Language
        isSelected={selectedLanguage === 'it'}
        onClick={() => updateSelectedLanguage('it')}
      >
        IT
      </Language>
      <Language
        isSelected={selectedLanguage === 'en'}
        onClick={() => updateSelectedLanguage('en')}
      >
        EN
      </Language>
    </LanguagesContainer>
  )
}
