import { FC } from 'react'
import { ScheduleType } from './types'
import { convertToSchedule } from './convertToSchedule'
import { ScheduleP, ScheduleRow } from './styles'
import { useTranslations } from '../../hooks/useTranslations'
import { RenderIf } from '../RenderIf'

export const Schedule: FC<ISchedule> = ({ schedule }) => {
  const t = useTranslations()
  const [sch, closed] = convertToSchedule(schedule)

  return (
    <>
      <RenderIf condition={sch.length}>
        {sch.map((el: string[], i: number) => (
          <ScheduleRow key={`scedule_item_${i}`}>
            <ScheduleP>
              {el[0]
                .split('-')
                // eslint-disable-next-line
                // @ts-ignore
                .map((code: any) => t(`clickCollect.${[code]}`))
                .join('-')}
            </ScheduleP>
            <p>{el[1]}</p>
          </ScheduleRow>
        ))}
      </RenderIf>
      <RenderIf condition={closed.length}>
        <ScheduleRow key={`scedule_item_${-1}`}>
          <ScheduleP hideBorder>{t('clickCollect.closed')}:</ScheduleP>
          <p>
            {closed
              // eslint-disable-next-line
              // @ts-ignore
              .map((code) => t(`clickCollect.${[code]}`))
              .join(', ')}
          </p>
        </ScheduleRow>
      </RenderIf>
    </>
  )
}

interface ISchedule {
  schedule: ScheduleType
}
