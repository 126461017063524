import styled from 'styled-components'

type SoldOutBannerProps = {
  isList?: boolean
}

type PriceProps = {
  isSoldOut?: boolean
}

type ListItemProps = {
  isSelected?: boolean
}

export const ProductStyled = styled('div')<ListItemProps>`
  /* height: 180px; */
  min-width: 125px;
  border-radius: 5px;
  /* margin-top: 3vw; */
  position: relative;
  background: ${({ theme }) => theme.ItemsBackGround};
  color: ${({ theme }) => theme.FontColor};
  border: ${({ theme, isSelected }) =>
    isSelected
      ? `1px solid ${theme.colour}`
      : `1px solid ${theme.ItemsBorders}`};
`

export const ProductCounter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);

  height: 20px;
  width: 20px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colour};
  color: #fff;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductImageContainer = styled.div`
  border-radius: 4px 4px 0 0;
  overflow: hidden;
`

export const ProductImage = styled.img`
  height: 57px;
  width: 100%;
  object-fit: cover;
`

export const ProductTitle = styled.p`
  margin: 5px 10px 0;
  font-size: 13px;
  line-height: 13px;
  height: 26px;
  color: ${({ theme }) => theme.FontColor};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Price = styled('div')<PriceProps>`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  color: ${({ theme, isSoldOut }) => (isSoldOut ? '#cdcdcd40' : theme.colour)};
  margin: 0 10px 10px;
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  position: relative;
  p:last-child {
    font-size: 16px;
    line-height: 16px;
  }
`

export const SoldOutBanner = styled('div')<SoldOutBannerProps>`
  position: absolute;
  top: ${({ isList }) => (isList ? '25px' : '0px')};
  right: 0px;
  white-space: nowrap;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 600;
  transform: rotate(-4deg);
  text-transform: uppercase;
  border: 2px solid #f00;
  border-radius: 5px;
  color: #f00;
`

export const SecondaryImage = styled('div')<any>`
  position: absolute;
  top: 8px;
  left: ${({ isRight }) => (isRight ? 'unset' : '8px')};
  right: ${({ isRight }) => (isRight ? '8px' : 'unset')};
  /* background-color: ${({ theme }) => theme.ItemsBackGround}; */
  background-color: ${({ theme }) => theme.ItemsBackGround};
  width: 24px;
  height: 24px;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-top: 1px;
`

export const AllergensContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 5px 10px;
  height: 18px;
  overflow-x: hidden;
`

export const Allergen = styled.img`
  background: #cdcdcd;
  height: 18px;
  width: 18px;
  object-fit: cover;
  border-radius: 9px;
`
