import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, IRestaurant } from './types'

export const RestaurantsSlice = createSlice({
  name: 'restaurantsSlice',
  initialState,
  reducers: {
    setRestaurants(state, action: PayloadAction<IRestaurant[]>) {
      return (state = action.payload)
    },
  },
})

export default RestaurantsSlice.reducer
