import { useMyDispatch } from '../useMyDispatch'
import { TextArea } from '../../components/TextArea'
import { Button } from '../../components/Button'
import { ButtonContainer } from '../../components/Button/styled'
import { ModalsSlice } from '../../store/reducers/ModalsReducer'
import { FC, useEffect, useState } from 'react'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { useTranslations } from '../useTranslations'
import { NoteContainer, NoteTitle } from './styled'

export type NotePropsType = {
  productId: number
  isUpdatesAvailable: boolean
  onUpdate?: (value: { [key: number]: string }) => void
}

export const Note: FC<NotePropsType> = ({
  productId,
  onUpdate,
  isUpdatesAvailable,
}) => {
  const t = useTranslations()
  const dispatch = useMyDispatch()
  const { closeModal } = ModalsSlice.actions

  const [note, setNote] = useState('')

  useEffect(() => {
    getPrevValue()
  }, [productId])

  const handleUpdateNote = (e: any) => {
    if (isUpdatesAvailable) {
      setNote(e.currentTarget.value)
    }
  }

  const getPrevValue = () => {
    const prevProductsNotes = handleLocalStorage('products_notes')
    setNote(prevProductsNotes?.[productId] || '')
  }

  const updateProductNote = () => {
    const prevProductsNotes = handleLocalStorage('products_notes')
    handleLocalStorage({
      products_notes: { ...prevProductsNotes, [productId]: note },
    })
    if (onUpdate) {
      onUpdate({ ...prevProductsNotes, [productId]: note })
    }
    dispatch(closeModal('PRODUCT_NOTE'))
  }

  return (
    <NoteContainer>
      <NoteTitle>{t('order.addNoteHeader')}</NoteTitle>
      <TextArea value={note} rows="10" onChange={handleUpdateNote} />
      <ButtonContainer>
        <Button
          buttonType="stroke"
          onClick={() => dispatch(closeModal('PRODUCT_NOTE'))}
        >
          {t('buttons.abort')}
        </Button>
        <Button buttonType="common" onClick={updateProductNote}>
          {t('buttons.save')}
        </Button>
      </ButtonContainer>
    </NoteContainer>
  )
}
