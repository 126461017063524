import { useMySelector } from '../useMySelector'
import { useOpenModal } from '../useOpenModal'
import { useTranslations } from '../useTranslations'
import { Note } from './Note'

export type OpenNoteType = (
  productId: number,
  onUpdate?: (value: { [key: number]: string }) => void,
  isUpdatesAvailable?: boolean
) => void

export const useOpenNote = (): OpenNoteType => {
  const t = useTranslations()
  const openModal = useOpenModal()
  const { notesAvailable } = useMySelector((state) => state.app.menu)

  const openNote = (
    productId: number,
    onUpdate: any,
    isUpdatesAvailable = true
  ) => {
    if (notesAvailable === 1) {
      openModal({
        id: 'PRODUCT_NOTE',
        title: t('products.note'),
        components: [
          () => (
            <Note
              productId={productId}
              onUpdate={onUpdate}
              isUpdatesAvailable={isUpdatesAvailable}
            />
          ),
        ],
      })
    }
  }

  return openNote
}
