import { TranslationsSlice } from '.'
import { qrMenuGET } from '../../../api/qrMenu/GET'
import { AppDispatch } from '../../index'
import { Translations } from './types'

export const initTranslations = () => async (dispatch: AppDispatch) => {
  const translations: Translations = await qrMenuGET.translations()
  const translationsToSet = JSON.parse(
    JSON.stringify(translations || {}),
    (_key, value) => {
      if (typeof value === 'string' && value?.startsWith('*****')) {
        return (params: any) => {
          const names = Object.keys(params)
          const neededSubstitutons = [
            ...value.matchAll(new RegExp(/\$\{(\w+)\}/, 'gi')),
          ].map((a) => a[1])
          const containsAllNeededNames = checker(neededSubstitutons, names)
          const vals = Object.values(params)
          if (containsAllNeededNames) {
            return new Function(...names, `return \`${value?.slice(5)}\``)(
              ...vals
            )
          } else {
            return value?.slice(5)
          }
        }
      } else {
        return value
      }
    }
  )
  dispatch(TranslationsSlice.actions.setTranslations(translationsToSet))
}

const checker = (target: string[], arr: string[]) =>
  target?.every((v) => arr?.includes(v))
