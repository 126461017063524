import styled from 'styled-components'

export const BranchTitle = styled.p`
  height: 2rem;
  width: 100%;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #434343;
  font-weight: 800;
  margin-bottom: 1rem;
`
